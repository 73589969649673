export enum AuctionStatus {
  Scheduled = "scheduled",
  Active = "active",
  Completed = "completed",
}

export enum AuctionType {
  ImmediateDelivery = "immediate_delivery",
  ForwardDate = "forward_date",
  UpfrontPayment = "upfront_payment",
}

export enum OrderStatus {
  Unpaid = "unpaid",
  Expired = "expired",
  Processing = "processing",
  Paid_1 = "paid_1",
  Paid_2 = "paid_2",
  DeliveryStarted = "delivery_started",
  DeliveryEnded = "delivery_ended",
  EscrowRequestRefund = "escrow_request_refund",
  EscrowRequestCancel = "escrow_request_cancel",
  EscrowRequestRelease = "escrow_request_release",
  EscrowRefunded = "escrow_refunded",
  EscrowCancelled = "escrow_cancelled",
  EscrowReleased = "escrow_released",
}

export interface Auction {
  id: number;
  title: string;
  status: AuctionStatus;
  auction_meta: {
    hashrate_start: string;
    hashrate_end: string;
  };
  auction_type: {
    type: AuctionType;
  };
  epoch: Epoch;
}

export interface BlockParty {
  id: number;
  name: string;
  hashrate_ths: number;
  payment_address: string;
}

export interface Order {
  id: number;
  type: OrderType;
  previous_status: OrderStatus;
  status: OrderStatus;
  original_total: number;
  total: number;
  mining_deposit: number;
  auction_fee: number;
  updated_at: number;
  account: Account;
  auction?: Auction;
  block_party?: BlockParty;
  expires_at: number;
  hashrate: number;
}

export interface Account {
  id: number;
  email: string;
  username: string;
  pool_user: PoolUser;
}

export interface RequestOptions {
  headers?: { [key: string]: any };
}

export interface AuctionResult {
  account: Account;
  auction: Auction;
  bid_price: number;
  is_winner: boolean;
  updated_at: number;
}

export interface AuctionWithResult extends Auction {
  result: AuctionResult;
}

export enum SortingDirection {
  None = "",
  Ascending = "asc",
  Descending = "desc",
}

export type IconType =
  | "thLarge"
  | "user"
  | "users"
  | "warehouse"
  | "cart"
  | "server"
  | "envelope"
  | "receipt"
  | "rightFromBracket"
  | "chevronUp"
  | "moneyBillWave"
  | "syncAlt"
  | "times"
  | "circlePlay"
  | "circleStop"
  | "personDigging"
  | "plus"
  | "arrowRightArrowLeft"
  | "arrowRight"
  | "handHoldingDollar"
  | "vault"
  | "gift"
  | "broom"
  | "bars"
  | "ellipsis"
  | "trash"
  | "pencil";

export interface MenuItem {
  id: string;
  label: string;
  to: string;
  icon: IconType;
}

export interface PaginationState {
  offset: number;
  limit: number;
}

export enum PaymentStatus {
  Unpaid = "unpaid",
  Expired = "expired",
  Processing = "processing",
  Paid = "paid",
  Underpaid = "underpaid",
  Refunded = "refunded",
}

export enum PaymentProvider {
  BitGo = "bitgo",
  OpenNode = "opennode",
}

export enum PaymentType {
  Order = "order",
  Verification = "verification",
}

export interface Payment {
  account: Account;
  amount: number;
  auction: Auction;
  expires_at: string;
  id: number;
  is_first: boolean;
  is_onchain: boolean;
  tx_id?: string;
  order: Order;
  original_amount: number;
  payment_id: string;
  provider: PaymentProvider;
  status: PaymentStatus;
  type: PaymentType;
}

export enum FieldType {
  Sats = "sats",
  Thps = "thps",
  Days = "days",
  Component = "component",
  DateTime = "datetime",
  String = "string",
  Number = "number",
  Percentage = "percentage",
  Address = "address",
  Url = "url",
  Boolean = "boolean",
}

export interface Field {
  key: (field: any) => any | string;
  label: string;
  type: FieldType;
  meta: any;
  visible?: (data: any) => boolean;
}

export interface AuthContextType {
  account: Account | undefined;
  setAccount: (account: Account) => void;
  token: string | undefined;
  setToken: (token: string) => void;
  loading: boolean;
  logout: () => void;
}

export interface AccountPermissions {
  start_hashrate_delivery?: boolean;
}

export enum AccountType {
  Buyer = "buyer",
  Seller = "seller",
}

export interface Account {
  type: AccountType;
  email: string;
  permissions: AccountPermissions;
}

export enum OrderType {
  Direct = "direct",
  Auction = "auction",
  BlockParty = "block_party",
}

export enum ProxyType {
  Mask = "mask",
  Switch = "switch",
}

export interface PoolUser {
  pool: string;
  username: string;
}

export interface Worker {
  id: number;
  name: string;
  pool_user: PoolUser;
}

export interface WorkerData {
  id: number;
  accepted_shares: number;
  accepted_shares_300s: number;
  difficulty: number;
  hashrate: number;
  rejected_shares: number;
  rejected_shares_300s: number;
  stratums_id: number;
  timestamp: string;
  worker: number;
}

export interface WorkerHistory {
  id: number;
  proxy: number;
  assigned_at: string;
  current_worker: Worker;
}

export interface ProxyLegacy {
  id: number;
  algorithm: string;
  seller?: Account;
  last_assigned: string;
  name: string;
  order_type: OrderType;
  stratum: string;
  stratums_id: number;
  type: ProxyType;
  worker: Worker;
  worker_history: WorkerHistory;
  is_available?: boolean;
}

export interface EnvironmentContextType {
  environment: Environment;
  setEnvironment: (env: Environment) => void;
}

export enum Environment {
  QA = "qa",
  PROD = "prod",
}

export interface Bid {
  id: number;
  account: Account;
  auction: Auction;
  bid: number;
  is_winner: boolean;
}

export enum MiningStatus {
  Complete = "complete",
  InProgress = "in progress",
  Pending = "pending",
  Terminated = "terminated",
}

export interface Invoice {
  id: number;
  figure: any;
  is_email_sent: boolean;
}

export interface BuyerInvoice extends Invoice {
  report: {
    order: Order;
    anticipated_hashrate: number;
    auction_fee_sats: number;
    auction_title: string;
    auction_url: string;
    average_hashrate_thps: number;
    balance_sats: number;
    bid_price_sats: number;
    body: string;
    deposit_percent: number;
    deposit_sats: number;
    downtime_days: number;
    duration: string;
    epoch_number: number;
    expected_time_of_completion: string;
    hashrate_days: number;
    hashrate_end: string;
    hashrate_start: string;
    hashrate_thps: number;
    mining_status: string;
    payment_address: string;
    percent_complete: number;
    pool_account: string;
    pool_worker_name: string;
    remaining_days: number;
    remaining_status: string;
    stratum_address: string;
    subject: string;
    time_of_completion: any;
    uptime_days: number;
  };
}

export enum SellerInvoiceType {
  Epoch = "epoch",
  Custom = "custom",
}

export interface Earnings {
  fpps_payout_sats: { [key: string]: number };
  fpps_price_avg_satspthpspd: { [key: string]: number };
  hashrate_effective_thps: { [key: string]: number };
  rigly_earnings_sats: { [key: string]: number };
}

export interface SellerInvoice extends Invoice {
  report: {
    invoice_type: SellerInvoiceType;
    account: Account;
    earnings: Earnings;
    average_hashrate: number;
    days_remaining: number;
    downtime: number;
    hashrate_days: number;
    body: string;
    uptime: number;
    meta: any;
  };
}

export enum InvoiceView {
  Data = "data",
  Graph = "graph",
  RawData = "raw-data",
  RawGraph = "raw-graph",
  Email = "Email",
  Earnings = "Earnings",
}

export interface Epoch {
  epoch_number: number;
  difficulty: number;
  start_time: string;
  end_time: string;
  total_hash_rate: number;
}

export enum ActivityType {
  UntrackedAction = "untracked_action",
  LoginAsUser = "login_as_user",
  StartHashrateDelivery = "start_hashrate_delivery",
  EndHashrateDelivery = "end_hashrate_delivery",
  CreateInvoice = "create_invoice",
  UpdateInvoice = "update_invoice",
  PromoteAuctionWinner = "promote_auction_winner",
  SendPaymentReminder = "send_payment_reminder",
  CreateAuction = "create_auction",
  GrantRefund = "grant_refund",
  SwitchHashrateDelivery = "switch_hashrate_delivery",
}

export interface Activity {
  id: number;
  account: Account;
  type: ActivityType;
  meta: any;
  created_at: string;
  updated_at: string;
}

export enum RefundStatus {
  Pending = "pending",
  Granted = "granted",
  Denied = "denied",
}

export interface Refund {
  id: number;
  account: Account;
  assignee?: Account;
  order: Order;
  status: RefundStatus;
  created_at: string;
  updated_at: string;
}

export type Team = {
  id: number;
  name: string;
  payout_address: string;
  slug: string;
};

export type ProxyStatistics = {
  hashrate: number;
  shares_accepted: number;
  shares_rejected: number;
};

export type HashrateStats = {
  calculated: number;
  average: number;
};

export type SharesStats = {
  accepted: number;
  rejected: number;
};

export type WorkerMachine = {
  name: string;
  host: string;
  port: number;
  hashrate: HashrateStats;
  shares: SharesStats;
  difficulty: number;
  is_last_share_accepted: boolean;
};

export type Proxy = {
  id: number;
  name: string;
  host: string;
  port: number;
  process_id: number;
  team: Team;
  seller: number;
  proxy_groups: number[];
  upstream?: Worker;
  hashrate: HashrateStats;
  shares: SharesStats;
  difficulty: number;
  workers: WorkerMachine[];
};

export type ProxyAssignment = {
  timestamp: number;
  from: PoolUser;
  to: PoolUser;
};

export type ProxyDifficulty = {
  timestamp: number;
  difficulty: number;
};

export type ProxyLog = {
  timestamp: string;
  type: string;
  lineNumber: string;
  content: string;
};

export type WebsocketUpdate = {
  type: string;
  payload: any;
};
