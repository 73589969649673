/* eslint-disable @typescript-eslint/no-empty-function */
import * as React from "react";

import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import View from "../components/View";
import { Payment, PaymentStatus } from "../types";
import { get, post } from "../utils/fetch";
import { apiUrl } from "../utils/url";
import { useParams } from "react-router-dom";
import Fields from "../components/Fields";
import Button from "../components/core/Button";
import Icon from "../components/core/Icon";
import { useEnvironmentContext } from "../context/EnvironmentContext";
import Loader from "../components/core/Loader";
import Toolbar from "../components/core/Toolbar";
import { useAuthContext } from "../context/AuthContext";
import toast from "react-hot-toast";
import PaymentStatusChip from "../components/PaymentStatusChip";
import { useModalContext } from "../context/ModalContext";
import AreYouSureModal from "../components/modals/AreYouSureModal";

export default function Orders() {
  const params = useParams();
  const { token } = useAuthContext();
  const { environment } = useEnvironmentContext();
  const { showModal, hideModal } = useModalContext();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [sendReminderLoading, setSendReminderLoading] =
    React.useState<boolean>(false);
  const [payment, setPayment] = React.useState<Payment | undefined>(undefined);

  const loadPayment = async () => {
    if (process.env.REACT_APP_ENV !== "local" && !token) {
      return;
    }

    try {
      setLoading(true);
      const res = await get(
        apiUrl(environment, `/api/ops/payments/${params.paymentId}`),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPayment(res.payment);
    } catch (ex: any) {
      toast.error(ex.message, { position: "bottom-left" });
    } finally {
      setLoading(false);
    }
  };

  const handleSendReminder = async (
    e: React.MouseEvent<HTMLElement>,
    payment: Payment
  ) => {
    if (process.env.REACT_APP_ENV !== "local" && !token) {
      return;
    }

    showModal("are-you-sure", {
      title: "Send payment reminder",
      content: "Confirming this prompt will send an email to the customer",
      onCancel: () => {},
      onConfirm: async () => {
        try {
          e.stopPropagation();
          setSendReminderLoading(true);
          await post(
            apiUrl(
              environment,
              `/api/ops/orders/${payment.order.id}/send-payment-reminder`
            ),
            {
              payment_id: payment.id,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          hideModal("are-you-sure");
        } catch (ex) {
          console.error(ex);
        } finally {
          setSendReminderLoading(false);
        }
      },
    });
  };

  React.useEffect(() => {
    loadPayment();
  }, [params, environment, token]);

  return (
    <>
      <Topbar />
      <div className="flex w-full h-screen-excluding-topbar">
        <Sidebar />
        <View>
          {loading && (
            <div className="w-full h-full flex items-center justify-center">
              <Loader className="w-8 h-8 text-white" />
            </div>
          )}
          {!loading && payment && (
            <>
              <Toolbar className="p-1 pl-4">
                <h1 className="text-white text-sm font-semibold">
                  Payment {payment.id}
                </h1>
                <div className="flex items-center justify-center gap-1">
                  {payment.status === PaymentStatus.Unpaid && (
                    <Button
                      className="h-8 rounded flex items-center justify-start gap-2 uppercase tracking-wide px-2"
                      color="blue"
                      onClick={(e) => handleSendReminder(e, payment)}
                      disabled={sendReminderLoading}
                    >
                      {sendReminderLoading && <Loader className="w-4 h-4" />}
                      {!sendReminderLoading && (
                        <Icon icon="envelope" className="w-4 h-4 text-white" />
                      )}
                      <span className="text-xs">Send reminder</span>
                    </Button>
                  )}
                </div>
              </Toolbar>

              <div className="px-3 pb-3">
                <h2 className="text-xl text-white font-semibold">Fields</h2>
                <div className="grid grid-cols-2 gap-2">
                  <Fields
                    data={payment}
                    schema={{
                      fields: [
                        {
                          key: "id",
                          label: "ID",
                          type: "number",
                        },
                        {
                          key: "account.email",
                          label: "Account",
                          type: "string",
                        },
                        {
                          key: "auction.title",
                          label: "Auction",
                          type: "string",
                        },
                        {
                          label: "Order",
                          type: "component",
                          meta: (payment: Payment) =>
                            `Order (${payment.order.id})`,
                        },
                        {
                          key: "provider",
                          label: "Provider",
                          type: "string",
                        },
                        {
                          key: "type",
                          label: "Type",
                          type: "string",
                        },
                        {
                          key: "created_at",
                          label: "Created at",
                          type: "datetime",
                        },
                      ],
                    }}
                  />
                  <Fields
                    data={payment}
                    schema={{
                      fields: [
                        {
                          label: "Payment ID",
                          type: "component",
                          meta: (payment: Payment) => (
                            <div className="flex items-center justify-start gap-1 font-mono">
                              <span>{payment.payment_id}</span>
                              {payment.provider === "opennode" && (
                                <span>(OpenNode ID)</span>
                              )}
                              {payment.provider === "bitgo" && (
                                <span>(BitGo payment address)</span>
                              )}
                            </div>
                          ),
                        },
                        {
                          label: "Status",
                          type: "component",
                          meta: (payment: Payment) => (
                            <div className="flex items-center justify-start">
                              <PaymentStatusChip value={payment.status} />
                            </div>
                          ),
                        },
                        {
                          key: "is_onchain",
                          label: "On chain?",
                          type: "boolean",
                        },
                        {
                          key: "tx_id",
                          label: "Transaction ID",
                          type: "component",
                          meta: (payment: Payment) => (
                            <a
                              href={`https://blockstream.info/tx/${payment.tx_id}`}
                              className="font-mono text-blue-400 underline"
                              target="_blank"
                            >
                              {payment.tx_id}
                            </a>
                          ),
                          visible: (payment: Payment) => payment.is_onchain,
                        },
                        {
                          key: "amount",
                          label: "Amount",
                          type: "sats",
                        },
                        {
                          key: "original_amount",
                          label: "Original amount",
                          type: "sats",
                        },
                        {
                          key: "is_first",
                          label: "Is first payment for order?",
                          type: "boolean",
                        },
                      ],
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </View>
      </div>
      <AreYouSureModal />
    </>
  );
}
