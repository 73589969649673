import * as React from "react";
import { useParams } from "react-router-dom";
import Loader from "../components/core/Loader";
import Sidebar from "../components/Sidebar";
import Toolbar from "../components/core/Toolbar";
import Topbar from "../components/Topbar";
import View from "../components/View";
import { useAuthContext } from "../context/AuthContext";
import { useEnvironmentContext } from "../context/EnvironmentContext";
import {
  Account,
  AccountType,
  BuyerInvoice,
  InvoiceView,
  SellerInvoice,
  SellerInvoiceType,
} from "../types";
import { get } from "../utils/fetch";
import { apiUrl } from "../utils/url";
import { Tab, Tabs } from "../components/core/Tabs";
import toast from "react-hot-toast";
import InvoiceFields from "../components/invoice/InvoiceFields";
import InvoiceChart from "../components/invoice/InvoiceChart";
import InvoiceRawData from "../components/invoice/InvoiceRawData";
import InvoiceRawGraph from "../components/invoice/InvoiceRawGraph";
import InvoiceEmail from "../components/invoice/InvoiceEmail";
import InvoiceEarnings from "../components/invoice/InvoiceEarnings";

export default function InvoiceDetail() {
  const params = useParams();
  const { environment } = useEnvironmentContext();
  const { token } = useAuthContext();

  const [activeTab, setActiveTab] = React.useState<InvoiceView>(
    InvoiceView.Data
  );
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loadingAccount, setLoadingAccount] = React.useState<boolean>(false);
  const [account, setAccount] = React.useState<Account | undefined>(undefined);
  const [invoice, setInvoice] = React.useState<
    BuyerInvoice | SellerInvoice | undefined
  >(undefined);

  const loadAccount = async () => {
    if (process.env.REACT_APP_ENV !== "local" && !token) {
      return;
    }

    try {
      setLoadingAccount(true);
      const res = await get(
        apiUrl(environment, `/api/ops/accounts/${params.accountId}`),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAccount(res);
    } catch (ex: any) {
      toast.error(ex.message, { position: "bottom-left" });
    } finally {
      setLoadingAccount(false);
    }
  };

  const loadInvoice = async () => {
    if (process.env.REACT_APP_ENV !== "local" && !token) {
      return;
    }

    if (!account) {
      return;
    }

    try {
      setLoading(true);
      const res = await get(
        apiUrl(
          environment,
          account.type === AccountType.Seller
            ? `/api/ops/sellers/${account.id}/invoices/${params.invoiceId}`
            : `/api/ops/buyers/${account.id}/invoices/${params.invoiceId}`
        ),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setInvoice(res);
    } catch (ex: any) {
      toast.error(ex.message, { position: "bottom-left" });
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    loadAccount();
  }, [params, environment, token]);

  React.useEffect(() => {
    loadInvoice();
  }, [params, environment, token, account]);

  const isSeller = account && account.type === AccountType.Seller;

  const renderTitle = () => {
    if (isSeller) {
      const inv = invoice as SellerInvoice;
      return (
        <h1 className="text-white text-sm font-semibold">
          Invoice {inv.id} - {inv.report.account.email} -
          {inv.report.invoice_type === SellerInvoiceType.Epoch
            ? `Epoch ${inv.report.meta.epoch_number}`
            : `${inv.report.meta.hashrate_start} - ${inv.report.meta.hashrate_end}`}
        </h1>
      );
    }

    const inv = invoice as BuyerInvoice;
    return (
      <h1 className="text-white text-sm font-semibold">
        Invoice {inv.id} - Order {inv.report.order.id}
      </h1>
    );
  };

  return (
    <>
      <Topbar />
      <div className="flex w-full h-screen-excluding-topbar">
        <Sidebar />
        <View>
          {(loading || loadingAccount) && (
            <div className="w-full h-full flex items-center justify-center">
              <Loader className="w-8 h-8 text-white" />
            </div>
          )}
          {!loading && invoice && (
            <>
              <Toolbar className="p-1 pl-4">
                {renderTitle()}
                {/* <Button
                  className="h-8 rounded flex items-center justify-start gap-2 uppercase tracking-wide px-2"
                  color="gray"
                  onClick={handleUpdate}
                  disabled={updateLoading}
                >
                  {updateLoading && <Loader className="w-4 h-4" />}
                  {!updateLoading && (
                    <Icon icon="syncAlt" className="w-4 h-4 text-white" />
                  )}
                  <span className="font-mono text-sm">Refresh</span>
                </Button> */}
              </Toolbar>
              <div className="px-3 pb-3">
                <Tabs
                  value={activeTab}
                  onChange={(view: InvoiceView) => setActiveTab(view)}
                >
                  <Tab id={InvoiceView.Data} label="Data">
                    <div className="grid grid-cols-1 xl:grid-cols-3 gap-4 grow">
                      <InvoiceFields account={account} invoice={invoice} />
                    </div>
                  </Tab>
                  <Tab
                    id={InvoiceView.Earnings}
                    label="Earnings"
                    disabled={!isSeller}
                  >
                    <InvoiceEarnings invoice={invoice as SellerInvoice} />
                  </Tab>
                  <Tab id={InvoiceView.Graph} label="Graph">
                    <InvoiceChart account={account} invoice={invoice} />
                  </Tab>
                  <Tab id={InvoiceView.RawData} label="Raw Data">
                    <InvoiceRawData account={account} invoice={invoice} />
                  </Tab>
                  <Tab id={InvoiceView.RawGraph} label="Raw Graph">
                    <InvoiceRawGraph invoice={invoice} />
                  </Tab>
                  <Tab id={InvoiceView.Email} label="E-mail">
                    <InvoiceEmail
                      account={account}
                      invoice={invoice}
                      onRefresh={loadInvoice}
                    />
                  </Tab>
                </Tabs>
              </div>
            </>
          )}
        </View>
      </div>
    </>
  );
}
