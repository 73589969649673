import * as React from "react";
import { useModalContext } from "../../context/ModalContext";
import Button from "../core/Button";
import Modal from "../core/Modal";
import { Order } from "../../types";
import Icon from "../core/Icon";
import clsx from "clsx";
import SelectOrderModal from "./SelectOrderModal";
import OrderSummary from "../orders/OrderSummary";

export default function SwitchHashrateDeliveryModal({
  order,
}: {
  order?: Order;
}) {
  const { modals, hideModal } = useModalContext();
  const { showModal } = useModalContext();
  const { active, meta } = modals["switch-hashrate-delivery"];

  const [selectedOrder, setSelectedOrder] = React.useState<Order | undefined>(
    undefined
  );

  const handleCancel = () => {
    meta.onCancel();
    hideModal("switch-hashrate-delivery");
  };

  const handleConfirm = () => {
    meta.onConfirm(order, selectedOrder);
  };

  const handleSelectOrder = () => {
    showModal("select-order", {
      onCancel: () => hideModal("select-order"),
      onConfirm: (order: Order) => {
        hideModal("select-order");
        setSelectedOrder(order);
      },
    });
  };

  if (!order) {
    return null;
  }

  return (
    <>
      <Modal
        active={active}
        onClose={handleCancel}
        className="w-[calc(100%_-_4rem)] xl:w-3/5"
      >
        <Modal.Header>
          <Modal.Title>
            {meta && meta.title ? meta.title : `Switch hashrate delivery`}
          </Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body className="p-2">
          <div className="grid grid-cols-2 gap-4 text-white">
            <div className="flex flex-col gap-2">
              <span className="font-bold">Current order</span>
              <div className="flex items-center justify-between rounded bg-white/5 h-10 px-3 gap-3">
                Order {order.id}
              </div>
              <OrderSummary order={order} />
            </div>
            <div className="flex flex-col gap-2">
              <span className="font-bold">Target order</span>
              <div className="flex items-center justify-between">
                <button
                  onClick={handleSelectOrder}
                  className={clsx(
                    "flex items-center justify-between bg-white/5 h-10 px-3 gap-3 w-full",
                    {
                      rounded: !selectedOrder,
                      "rounded-l": selectedOrder,
                    }
                  )}
                >
                  {selectedOrder && `Order ${selectedOrder.id}`}
                  {!selectedOrder && `Select order`}
                </button>
                {selectedOrder && (
                  <button
                    className="bg-white/5 h-10 px-3 rounded-r hover:bg-red-500"
                    onClick={() => setSelectedOrder(undefined)}
                  >
                    <Icon icon="times" className="w-4 h-4 text-white" />
                  </button>
                )}
              </div>
              {selectedOrder !== undefined && (
                <OrderSummary order={selectedOrder} />
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="h-8 rounded flex items-center justify-start gap-2 uppercase tracking-wide px-2"
            color="gray"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            className="h-8 rounded flex items-center justify-start gap-2 uppercase tracking-wide px-2"
            color="purple"
            onClick={handleConfirm}
          >
            Switch
          </Button>
        </Modal.Footer>
      </Modal>

      <SelectOrderModal value={selectedOrder} />
    </>
  );
}
