import * as React from "react";

import { Proxy, ProxyDifficulty } from "../../types";
import { format } from "date-fns";
import { useEnvironmentContext } from "../../context/EnvironmentContext";
import * as api from "../../api/proxies";
import { useAuthContext } from "../../context/AuthContext";

export default function DifficultyHistory({ proxy }: { proxy?: Proxy }) {
  const { token } = useAuthContext();
  const { environment } = useEnvironmentContext();

  const [difficulties, setDifficulties] = React.useState<ProxyDifficulty[]>([]);

  async function load() {
    if (!token || !proxy) {
      return;
    }

    try {
      const res = await api.getDifficultyHistory(
        proxy.id.toString(),
        environment,
        token
      );

      setDifficulties(res.results);
    } catch (ex: any) {
      //
    } finally {
      //
    }
  }

  React.useEffect(() => {
    load();
  }, [environment, token]);

  return (
    <div className="rounded whitespace-nowrap overflow-auto w-full h-[40vh]">
      {difficulties.map((difficulty: ProxyDifficulty, i: number) => (
        <div
          className="flex items-center justify-start border-b border-zinc-800"
          key={i}
        >
          <div className="flex items-center px-3 h-8 border-r border-zinc-800">
            <span className="text-white/50 text-sm">
              {format(difficulty.timestamp, "MM-dd-yyyy HH:mm:ss")}
            </span>
          </div>
          <div className="flex p-3 h-8 items-center justify-start">
            <span className="text-white text-sm">{difficulty.difficulty}</span>
          </div>
        </div>
      ))}
    </div>
  );
}
