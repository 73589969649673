import clsx from "clsx";
import * as React from "react";

export default function Toolbar({
  children,
  className = "p-1",
}: {
  children?: React.ReactNode;
  className?: string;
}) {
  return (
    <div
      className={clsx(
        "bg-zinc-900 border-b border-zinc-700 px-2 flex justify-between items-center gap-1 min-h-[40px]",
        className
      )}
    >
      {children}
    </div>
  );
}
