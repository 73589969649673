import * as React from "react";
import { Order, OrderType } from "../../types";

export default function OrderSummary({ order }: { order: Order }) {
  return (
    <div className="bg-white/5 rounded-lg flex flex-col text-white">
      <table>
        <tbody>
          <tr className="border-b border-zinc-800 last-of-type:border-0 h-8">
            <th className="pl-3 text-left font-mono text-xs whitespace-nowrap">
              Order
            </th>
            <td className="pr-3 text-left font-mono text-xs">{order.id}</td>
          </tr>
          <tr className="border-b border-zinc-800 last-of-type:border-0 h-8">
            <th className="pl-3 text-left font-mono text-xs whitespace-nowrap">
              Status
            </th>
            <td className="pr-3 text-left font-mono text-xs">{order.status}</td>
          </tr>
          <tr className="border-b border-zinc-800 last-of-type:border-0 h-8">
            <th className="pl-3 text-left font-mono text-xs whitespace-nowrap">
              {order.type === OrderType.BlockParty
                ? "Block Party"
                : order.type === OrderType.Direct
                ? "Direct"
                : "Auction"}
            </th>
            {order.type === OrderType.BlockParty && (
              <td className="pr-3 text-left font-mono text-xs">
                {order.block_party!.name}
              </td>
            )}
            {order.type === OrderType.Direct && (
              <td className="pr-3 text-left font-mono text-xs">{order.id}</td>
            )}
            {order.type !== OrderType.BlockParty && (
              <td className="pr-3 text-left font-mono text-xs">
                {order.auction!.title}
              </td>
            )}
          </tr>
          <tr className="border-b border-zinc-800 last-of-type:border-0 h-8">
            <th className="pl-3 text-left font-mono text-xs whitespace-nowrap">
              Account
            </th>
            <td className="pr-3 text-left font-mono text-xs">
              {order.account.email}
            </td>
          </tr>
          <tr className="border-b border-zinc-800 last-of-type:border-0 h-8">
            <th className="pl-3 text-left font-mono text-xs whitespace-nowrap">
              Pool user
            </th>
            <td className="pr-3 text-left font-mono text-xs">
              {order.account.pool_user.username}
            </td>
          </tr>
          <tr className="border-b border-zinc-800 last-of-type:border-0 h-8">
            <th className="pl-3 text-left font-mono text-xs whitespace-nowrap">
              Pool
            </th>
            <td className="pr-3 text-left font-mono text-xs">
              {order.account.pool_user.pool}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
