import { OrderStatus } from "../types";

const orderStatuses = [
  OrderStatus.Unpaid,
  OrderStatus.Expired,
  OrderStatus.Processing,
  OrderStatus.Paid_1,
  OrderStatus.Paid_2,
  OrderStatus.DeliveryStarted,
  OrderStatus.DeliveryEnded,
  OrderStatus.EscrowRequestCancel,
  OrderStatus.EscrowRequestRefund,
  OrderStatus.EscrowRequestRelease,
  OrderStatus.EscrowCancelled,
  OrderStatus.EscrowRefunded,
  OrderStatus.EscrowReleased,
];

export function hasPassedOrEqualsOrderStatus(a: OrderStatus, b: OrderStatus) {
  return orderStatuses.indexOf(a) >= orderStatuses.indexOf(b);
}

export function isBeforeOrderStatus(a: OrderStatus, b: OrderStatus) {
  return orderStatuses.indexOf(a) < orderStatuses.indexOf(b);
}
