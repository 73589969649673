/* eslint-disable no-prototype-builtins */
import * as React from "react";
import clsx from "clsx";

interface InputProps {
  id: string;
  type: string;
  placeholder?: string;
  onChange: (value: string) => void;
  className?: string;
  value?: string;
  required?: boolean;
}

export default function Input(props: InputProps) {
  if (props.hasOwnProperty("value")) {
    return <ControlledInput {...props} />;
  }

  return <UncontrolledInput {...props} />;
}

function UncontrolledInput({
  id,
  type,
  placeholder,
  onChange,
  className,
  required,
}: InputProps) {
  const handleChange = (e: any) => {
    const value = e.target.value;
    onChange(value);
  };

  return (
    <input
      type={type}
      id={id}
      name={id}
      className={clsx(
        className,
        "h-10 px-3 outline-none border-none rounded bg-white bg-opacity-5 focus:bg-opacity-10 text-white text-sm"
      )}
      required={required}
      placeholder={placeholder}
      onChange={handleChange}
      onInput={handleChange}
    />
  );
}

function ControlledInput({
  id,
  type,
  placeholder,
  onChange,
  className,
  value,
  required,
}: InputProps) {
  const [internalValue, setInternalValue] = React.useState(() => {
    if (type === "text") {
      return "";
    }
    if (type === "number") {
      return "";
    }
    if (type === "datetime-local") {
      return "";
    }
    return undefined;
  });

  const handleChange = (e: any) => {
    const value = e.target.value;
    setInternalValue(value);
    onChange(value);
  };

  React.useEffect(() => {
    setInternalValue(value);
  }, [value]);

  return (
    <input
      id={id}
      name={id}
      type={type}
      className={clsx(
        className,
        "h-10 px-3 outline-none border-none rounded bg-white bg-opacity-5 focus:bg-opacity-10 text-white text-sm"
      )}
      value={internalValue}
      required={required}
      placeholder={placeholder}
      onChange={handleChange}
      onInput={handleChange}
    />
  );
}
