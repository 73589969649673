import * as React from "react";
import clsx from "clsx";

export default function ProgressBar({
  className,
  value,
  color,
  animate,
}: {
  className?: string;
  value: number;
  color: "white" | "grey" | "blue" | "yellow" | "red" | "green";
  animate: boolean;
}) {
  return (
    <div
      className={clsx(
        "progress w-full h-2 border-t border-b",
        className,
        color,
        {
          "progress-animate": animate,
          "border-zinc-100": color === "white",
          "border-zinc-500": color === "grey",
          "border-blue-500": color === "blue",
          "border-yellow-500": color === "yellow",
          "border-green-500": color === "green",
          "border-red-500": color === "red",
        }
      )}
    >
      <span
        style={{ width: `${value}%` }}
        className={clsx({
          "bg-zinc-100": color === "white",
          "bg-zinc-500": color === "grey",
          "bg-blue-500": color === "blue",
          "bg-yellow-500": color === "yellow",
          "bg-green-500": color === "green",
          "bg-red-500": color === "red",
        })}
      />
    </div>
  );
}
