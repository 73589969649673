import * as React from "react";

import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import View from "../components/View";
import { get } from "../utils/fetch";
import { apiUrl } from "../utils/url";
import { useNavigate, useParams } from "react-router-dom";
import Fields from "../components/Fields";
import { Order, OrderType, ProxyLegacy, WorkerHistory } from "../types";
import Loader from "../components/core/Loader";
import Chart from "../components/core/Chart";
import { useEnvironmentContext } from "../context/EnvironmentContext";
import { Tabs, Tab } from "../components/core/Tabs";
import List from "../components/core/List";
import OrderStatusChip from "../components/OrderStatusChip";
import { useAuthContext } from "../context/AuthContext";

interface Hashrate {
  timestamp: string;
  hashrate: number;
}

enum ProxyView {
  Livefeed = "livefeed",
  Orders = "orders",
  WorkerHistory = "worker-history",
}

export default function Orders() {
  const params = useParams();
  const navigate = useNavigate();
  const { token } = useAuthContext();
  const { environment } = useEnvironmentContext();

  const [activeTab, setActiveTab] = React.useState<ProxyView>(
    ProxyView.Livefeed
  );
  const [loadingProxy, setLoadingProxy] = React.useState<boolean>(false);
  const [loadingWorkerHistory, setLoadingWorkerHistory] =
    React.useState<boolean>(false);
  const [proxy, setProxy] = React.useState<ProxyLegacy | undefined>(undefined);
  const [workerHistory, setWorkerHistory] = React.useState<any>([]);
  const [orders, setOrders] = React.useState<Order[]>([]);
  const [hashrateData, setHashrateData] = React.useState<any>([]);

  const loadProxy = async () => {
    if (process.env.REACT_APP_ENV !== "local" && !token) {
      return;
    }

    try {
      setLoadingProxy(true);
      const res = await get(
        apiUrl(environment, `/api/ops/proxies/${params.proxyId}`),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setProxy(res);
    } catch (ex) {
      console.error(ex);
    } finally {
      setLoadingProxy(false);
    }
  };

  const loadWorkerHistory = async () => {
    if (process.env.REACT_APP_ENV !== "local" && !token) {
      return;
    }

    try {
      setLoadingWorkerHistory(true);
      const res = await get(
        apiUrl(
          environment,
          `/api/ops/proxies/${params.proxyId}/worker-history`
        ),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setWorkerHistory(res);
    } catch (ex) {
      console.error(ex);
    } finally {
      setLoadingWorkerHistory(false);
    }
  };

  const loadLivefeed = async () => {
    if (!proxy || !token) {
      return;
    }

    const res: Hashrate[] = await get(
      apiUrl(
        environment,
        `/api/data/hashrate/${proxy.stratums_id}?resolution=7`
      ),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = [
      {
        x: res.map(({ timestamp }) => timestamp),
        y: res.map(({ hashrate }) => hashrate / 1000000000000),
        marker: {
          color: "rgb(3, 93, 242)",
        },
      },
    ];

    setHashrateData(data);
  };

  const loadOrders = async () => {
    if (process.env.REACT_APP_ENV !== "local" && !token) {
      return;
    }

    try {
      const res = await get(
        apiUrl(environment, `/api/ops/proxies/${params.proxyId}/orders`),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setOrders(res);
    } catch (ex) {
      console.error(ex);
    }
  };

  React.useEffect(() => {
    loadLivefeed();
  }, [proxy, token]);

  React.useEffect(() => {
    loadProxy();
  }, [params, environment, token]);

  React.useEffect(() => {
    if (activeTab === ProxyView.Orders) {
      loadOrders();
    }
  }, [params, activeTab, token]);

  React.useEffect(() => {
    if (proxy) {
      loadWorkerHistory();
    }
  }, [proxy]);

  return (
    <>
      <Topbar />
      <div className="flex w-full h-screen-excluding-topbar">
        <Sidebar />
        <View>
          {(!proxy || loadingProxy) && (
            <div className="flex items-center justify-center">
              <Loader />
            </div>
          )}
          {proxy && (
            <>
              <h1 className="text-white text-2xl font-semibold">
                Proxy {proxy.id} - {proxy.name}
              </h1>

              <h2 className="text-xl text-white font-semibold">Fields</h2>
              <Fields
                data={proxy}
                schema={{
                  fields: [
                    {
                      key: "id",
                      label: "ID",
                      type: "string",
                    },
                    {
                      key: "name",
                      label: "Name",
                      type: "string",
                    },
                    {
                      label: "Seller",
                      meta: (proxy: ProxyLegacy) => (
                        <span className="text-white">
                          {proxy.seller ? proxy.seller.email : "-"}
                        </span>
                      ),
                      type: "component",
                    },
                    {
                      key: "stratum",
                      label: "Stratum",
                      type: "string",
                    },
                    {
                      key: "stratums_id",
                      label: "Stratums ID",
                      type: "number",
                    },
                    {
                      key: "order_type",
                      label: "Order Type",
                      type: "string",
                    },
                    {
                      key: "type",
                      label: "Proxy Type",
                      type: "string",
                    },
                    {
                      key: "worker.name",
                      label: "Worker Name",
                      type: "string",
                    },
                    {
                      meta: (proxy: ProxyLegacy) =>
                        `${proxy.worker.pool_user.username}@${proxy.worker.pool_user.pool}`,
                      label: "Pool User",
                      type: "component",
                    },
                    {
                      key: "algorithm",
                      label: "Algorithm",
                      type: "string",
                    },
                    {
                      key: "last_assigned",
                      label: "Last assigned",
                      type: "datetime",
                      meta: "MM/dd/yyyy HH:mm:ss",
                    },
                  ],
                }}
              />

              <Tabs
                value={activeTab}
                onChange={(view: ProxyView) => setActiveTab(view)}
              >
                <Tab id={ProxyView.Livefeed} label="Livefeed">
                  <Chart
                    title={proxy.stratums_id.toString() || "Hashrate data"}
                    data={hashrateData}
                    className="grow"
                  />
                </Tab>
                <Tab id={ProxyView.Orders} label="Orders">
                  <List
                    className="grow"
                    schema={[
                      { label: "ID", key: "id", type: "number", align: "left" },
                      {
                        label: "Type",
                        key: "type",
                        type: "string",
                        align: "left",
                      },
                      {
                        label: "Auction",
                        key: (order: Order) =>
                          order.type === OrderType.BlockParty ? (
                            <span className="text-white">
                              {order.block_party!.name} ({order.block_party!.id}
                              )
                            </span>
                          ) : order.type === OrderType.Direct ? (
                            <span className="text-white">
                              Direct ({order.id})
                            </span>
                          ) : (
                            <span className="text-white">
                              {order.auction!.title} ({order.auction!.id})
                            </span>
                          ),
                        type: "custom",
                        align: "left",
                      },
                      {
                        label: "Account",
                        key: "account.email",
                        type: "string",
                        align: "left",
                      },
                      {
                        label: "Previous Status",
                        key: (order: Order) => (
                          <OrderStatusChip value={order.previous_status} />
                        ),
                        type: "custom",
                        align: "left",
                      },
                      {
                        label: "Current Status",
                        key: (order: Order) => (
                          <OrderStatusChip value={order.status} />
                        ),
                        type: "custom",
                        align: "left",
                      },
                      {
                        label: "Order expiry",
                        key: "expires_at",
                        type: "datetime",
                        align: "left",
                      },
                    ]}
                    data={orders}
                    loading={loadingProxy}
                    onClick={(order: Order) => navigate(`/orders/${order.id}`)}
                  />
                </Tab>
                <Tab id={ProxyView.WorkerHistory} label="Worker History">
                  <List
                    className="grow"
                    data={workerHistory}
                    loading={loadingWorkerHistory}
                    schema={[
                      { label: "ID", key: "id", type: "number", align: "left" },
                      {
                        label: "Proxy ID",
                        key: "proxy",
                        type: "number",
                        align: "left",
                      },
                      {
                        label: "Worker",
                        key: (wh: WorkerHistory) => (
                          <span className="text-white">
                            {wh.current_worker.name} ({wh.current_worker.id})
                          </span>
                        ),
                        type: "custom",
                      },
                      {
                        label: "Pool User",
                        key: (wh: WorkerHistory) => (
                          <span className="text-white">
                            {wh.current_worker.pool_user.username}@
                            {wh.current_worker.pool_user.pool}
                          </span>
                        ),
                        type: "custom",
                      },
                      {
                        label: "Assigned at",
                        key: "assigned_at",
                        type: "datetime",
                        align: "left",
                      },
                    ]}
                  />
                </Tab>
              </Tabs>

              {/* <h2 className="text-xl text-white font-semibold">Actions</h2>
              <div className="flex items-center justify-start gap-2">
                <Button
                  className="h-10 rounded flex items-center justify-start gap-3 uppercase tracking-wide px-4"
                  color="blue"
                  onClick={handleGenerateInvoice}
                >
                  <Icon icon="receipt" className="w-4 h-4 text-white" />
                  <span>Generate invoice</span>
                </Button>
              </div> */}
            </>
          )}
        </View>
      </div>
    </>
  );
}
