import clsx from "clsx";
import * as React from "react";
import Button from "./Button";

interface TabContext {
  index: number;
  value: string;
}

function Tabs({
  className,
  children,
  value,
  onChange,
}: {
  className?: string;
  children: any[];
  value: string;
  onChange: (val: any) => void;
}) {
  return (
    <div className={clsx("flex flex-col gap-4", className)}>
      <div className="flex items-center justify-start w-full bg-zinc-800 rounded">
        {React.Children.map(children, (Child: React.ReactElement) => {
          if (Child.props.disabled) {
            return null;
          }

          return (
            <Button
              className={clsx(
                "px-4 h-10 first-of-type:rounded-l last-of-type:rounded-r outline-none",
                {
                  "bg-zinc-700": Child.props.id === value,
                  "bg-zinc-800": Child.props.id !== value,
                }
              )}
              onClick={() => onChange(Child.props.id)}
            >
              <span className="text-white">{Child.props.label}</span>
            </Button>
          );
        })}
      </div>
      <div className="w-full flex">
        {React.Children.map(
          children,
          (Child: React.ReactElement, i: number) => {
            return React.cloneElement(Child, {
              tabContext: { index: i, value },
            });
          }
        )}
      </div>
    </div>
  );
}

function Tab({
  id,
  className,
  children,
  tabContext,
  disabled,
}: {
  id: string;
  label: string;
  className?: string;
  children?: React.ReactNode;
  tabContext?: TabContext;
  disabled?: boolean;
}) {
  if (disabled) {
    return null;
  }

  return (
    <div
      className={clsx("w-full", className, {
        flex: tabContext && id === tabContext.value,
        hidden: !tabContext || id !== tabContext.value,
      })}
    >
      {children}
    </div>
  );
}

export { Tab, Tabs };
