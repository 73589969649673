import clsx from "clsx";
import * as React from "react";
import { AuctionStatus } from "../types";
import Chip from "./core/Chip";

export default function AuctionStatusChip({ value }: { value: AuctionStatus }) {
  return (
    <Chip
      className={clsx("", {
        "bg-orange-500": value === AuctionStatus.Scheduled,
        "bg-blue-500": value === AuctionStatus.Active,
        "bg-green-500": value === AuctionStatus.Completed,
      })}
    >
      {value}
    </Chip>
  );
}
