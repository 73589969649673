import * as React from "react";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import { useEffect, useState } from "react";
import * as api from "../api/proxies";
import toast from "react-hot-toast";
import { Proxy, WebsocketUpdate } from "../types";
import { Card } from "@rigly/core";
import { useNavigate } from "react-router-dom";
import Toolbar from "../components/core/Toolbar";
import { parsePoolUrl } from "../utils/pool";
import { useAuthContext } from "../context/AuthContext";
import { useEnvironmentContext } from "../context/EnvironmentContext";
import View from "../components/View";
import Loader from "../components/core/Loader";
import CreateProxyModal from "../components/modals/CreateProxyModal";
import Icon from "../components/core/Icon";
import Popover from "../components/core/Popover";
import { v4 as guid } from "uuid";
import { calculateEfficiency, calculateInefficiency } from "../utils/hashrate";
import AreYouSureModal from "../components/modals/AreYouSureModal";
import { useModalContext } from "../context/ModalContext";
import HashrateChart from "../components/HashrateChart";
import { useWebsocketContext } from "../context/WebsocketContext";

function ProxyItem({
  proxy,
  proxies,
  onRefresh,
}: {
  proxy: Proxy;
  proxies: Proxy[];
  onRefresh: () => void;
}) {
  const { token } = useAuthContext();
  const { environment } = useEnvironmentContext();
  const { showModal, hideModal } = useModalContext();
  const navigate = useNavigate();

  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  const [isCreateOrEditModalActive, setIsCreateOrEditModalActive] =
    useState<boolean>(false);

  const id = "popover-" + guid();

  const handleDelete = async () => {
    if (!token) {
      return;
    }

    showModal("are-you-sure", {
      title: "Delete proxy",
      content: "Confirming this prompt will delete the proxy permanently",
      onCancel: () => {
        //
      },
      onConfirm: async () => {
        try {
          // e.stopPropagation();
          const res = await api.deleteProxy(proxy.id, environment, token);
          console.log(res);
          toast.success("Proxy deleted", { position: "bottom-right" });
          onRefresh();
        } catch (ex: any) {
          console.error(ex);
          toast.error(ex.toString(), { position: "bottom-right" });
        } finally {
          hideModal("are-you-sure");
        }
      },
    });
  };

  return (
    <>
      <div
        className="cursor-pointer"
        onClick={(e: any) => {
          e.preventDefault();
          const popover = e.target.closest(".popover");
          if (popover) {
            return;
          }
          navigate(`/proxies/${proxy.id}`);
        }}
      >
        <Card>
          <div className="flex justify-between items-start">
            <div className="flex flex-col">
              <span className="text-white font-bold">
                {proxy.name} ({proxy.id})
              </span>

              <span className="text-white/50 text-xs">
                {proxy.host ?? "-"}:{proxy.port ?? "-"} &rarr;{" "}
                {proxy.upstream
                  ? parsePoolUrl(proxy.upstream.pool_user.pool).location
                  : "No workers"}
              </span>
            </div>
            <div>
              <button
                id={id}
                className="popover"
                onClick={() => {
                  setMenuVisible(true);
                }}
              >
                <Icon icon="ellipsis" className="w-5 h-5 text-white" />
              </button>
            </div>
          </div>
          <div className="h-[200px] w-full border border-zinc-800 mb-6 mt-4 rounded-lg flex items-center justify-center">
            <HashrateChart
              className="pointer-events-none overflow-hidden"
              proxy={proxy}
              layout={{
                margin: { t: 0, r: 0, b: 0, l: 0 },
                // xaxis: { visible: false },
                // yaxis: { visible: false },
              }}
              renderEmpty={() => (
                <span className="text-xs text-white/40">
                  No chart data available
                </span>
              )}
            />
          </div>
          <div>
            <div className="grid grid-cols-3">
              {[
                {
                  value: proxy.hashrate.average
                    ? `${(proxy.hashrate.average / 1000000000000).toFixed(
                        2
                      )} TH/s`
                    : "0",
                  label: "Hashrate",
                },
                {
                  value: proxy?.shares?.accepted ?? "0",
                  label: "Accepted",
                },
                {
                  value: proxy?.shares?.rejected ?? "0",
                  label: "Rejected",
                },
              ].map((stat: any, i: number) => {
                return (
                  <div className="flex flex-col" key={i}>
                    <span className="text-white/70 text-sm font-mono">
                      {stat.value}
                    </span>
                    <span className="text-white/40 text-xs">{stat.label}</span>
                  </div>
                );
              })}
            </div>
            <div className="grid grid-cols-3 border-t border-zinc-800 mt-3 pt-3">
              {[
                {
                  value: proxy.workers.length,
                  label: proxy.workers.length === 1 ? "Worker" : "Workers",
                },
                {
                  value: Math.round(proxy.difficulty) ?? "-",
                  label: "Difficulty",
                },
                {
                  value: `${calculateEfficiency(
                    proxy.shares
                  )}% / ${calculateInefficiency(proxy.shares)}%`,
                  label: "Efficiency / Inefficiency",
                },
              ].map((stat: any, i: number) => {
                return (
                  <div className="flex flex-col" key={i}>
                    <span className="text-white/70 text-sm font-mono">
                      {stat.value}
                    </span>
                    <span className="text-white/40 text-xs">{stat.label}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </Card>
      </div>
      <Popover
        show={menuVisible}
        target={`#${id}`}
        onHide={() => setMenuVisible(false)}
        position="bottom-right"
      >
        <div className="flex flex-col min-w-[100px]">
          <button
            className="px-2 h-8 hover:bg-white/10 flex items-center justify-start gap-2"
            onClick={() => {
              setMenuVisible(false);
              setIsCreateOrEditModalActive(true);
            }}
          >
            <Icon icon="pencil" className="w-3 h-3 text-white" />
            <span className="text-white text-sm">Edit</span>
          </button>
          <button
            className="px-2 h-8 hover:bg-white/10 flex items-center justify-start gap-2"
            onClick={() => {
              setMenuVisible(false);
              handleDelete();
            }}
          >
            <Icon icon="trash" className="w-3 h-3 text-white" />
            <span className="text-white text-sm">Delete</span>
          </button>
        </div>
      </Popover>
      <AreYouSureModal />
      <CreateProxyModal
        mode="edit"
        data={proxy}
        proxies={proxies}
        active={isCreateOrEditModalActive}
        onClose={() => {
          setIsCreateOrEditModalActive(false);
        }}
        onRefresh={onRefresh}
      />
    </>
  );
}

export default function Proxies() {
  const ws = useWebsocketContext();
  const { environment } = useEnvironmentContext();

  const [proxies, setProxies] = useState<Proxy[]>([]);
  const [isCreateOrEditModalActive, setIsCreateOrEditModalActive] =
    useState<boolean>(false);

  const handleProxyUpdate = (update: WebsocketUpdate) => {
    switch (update.type) {
      case "init": {
        setProxies(update.payload);
        break;
      }
      case "worker_add": {
        setProxies((currentProxies) => {
          const newProxies = [...currentProxies];
          const proxyId = update.payload.proxy_id;
          const proxyIndex = newProxies.findIndex(
            (proxy) => proxy.id === proxyId
          );

          if (proxyIndex !== -1) {
            delete update.payload.proxy_id;
            newProxies[proxyIndex].workers.push(update.payload);
          }

          return newProxies;
        });
        break;
      }
      case "worker_remove": {
        setProxies((currentProxies) => {
          const newProxies = [...currentProxies];
          const proxyId = update.payload.proxy_id;
          const proxyIndex = newProxies.findIndex(
            (proxy) => proxy.id === proxyId
          );

          if (proxyIndex !== -1) {
            newProxies[proxyIndex].workers.splice(update.payload.worker_id, 1);
          }

          return newProxies;
        });
        break;
      }
      case "stats": {
        setProxies((currentProxies) => {
          const newProxies = [...currentProxies];
          const proxyId = update.payload.proxy_id;
          const proxyIndex = newProxies.findIndex(
            (proxy) => proxy.id === proxyId
          );

          if (proxyIndex !== -1) {
            delete update.payload.proxy_id;
            newProxies[proxyIndex] = {
              ...newProxies[proxyIndex],
              ...update.payload,
            };
          }

          return newProxies;
        });
        break;
      }
      default: {
        console.log(update);
        break;
      }
    }
  };

  const handleRefresh = async () => {
    setProxies([]);
    ws.unsubscribe("proxies", handleProxyUpdate);
    ws.subscribe("proxies", handleProxyUpdate);
  };

  useEffect(() => {
    ws.subscribe("proxies", handleProxyUpdate);

    return () => {
      ws.unsubscribe("proxies", handleProxyUpdate);
    };
  }, [ws.isConnected]);

  useEffect(() => {
    document.body.classList.add("dark");
  }, []);

  useEffect(() => {
    setProxies([]);
  }, [environment]);

  return (
    <>
      <Topbar />
      <div className="flex w-full h-screen-excluding-topbar">
        <Sidebar />
        <View>
          <Toolbar>
            <span className="text-sm text-white ml-2">
              Proxies ({proxies.length})
            </span>
            <button
              className="h-7 rounded bg-blue-600 border border-blue-400 px-3 cursor-pointer hover:bg-blue-400 hover:border-blue-300"
              onClick={() => setIsCreateOrEditModalActive(true)}
            >
              <span className="text-sm text-white">Create</span>
            </button>
          </Toolbar>
          <div className="grow overflow-y-auto h-screen-excluding-topbar-toolbar">
            {proxies.length === 0 && (
              <div className="w-full h-full flex items-center justify-center">
                <span className="text-sm text-white/50">
                  No proxies available
                </span>
              </div>
            )}
            {proxies.length > 0 && (
              <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 p-3 pt-0">
                {proxies.map((proxy: Proxy, i: number) => (
                  <ProxyItem
                    proxy={proxy}
                    key={i}
                    onRefresh={handleRefresh}
                    proxies={proxies}
                  />
                ))}
              </div>
            )}
          </div>
        </View>
      </div>
      <CreateProxyModal
        mode="create"
        proxies={proxies}
        active={isCreateOrEditModalActive}
        onClose={() => setIsCreateOrEditModalActive(false)}
        onRefresh={handleRefresh}
      />
    </>
  );
}
