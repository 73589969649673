import * as React from "react";
import clsx from "clsx";

export default function View({
  children,
  className = "p-0 flex flex-col gap-4",
}: {
  children?: React.ReactNode;
  className?: string;
}) {
  return <div className={clsx("w-full h-full", className)}>{children}</div>;
}
