import clsx from "clsx";
import * as React from "react";

import Chip from "./core/Chip";
import { OrderStatus } from "../types";

export default function OrderStatusChip({ value }: { value: OrderStatus }) {
  return (
    <Chip
      className={clsx("", {
        "bg-zinc-500": value === OrderStatus.Unpaid,
        "bg-red-500":
          [OrderStatus.Expired, OrderStatus.EscrowCancelled].indexOf(value) !==
          -1,
        "bg-blue-500":
          [
            OrderStatus.Processing,
            OrderStatus.DeliveryStarted,
            OrderStatus.EscrowRefunded,
          ].indexOf(value) !== -1,
        "bg-orange-500":
          [
            OrderStatus.Paid_1,
            OrderStatus.EscrowRequestCancel,
            OrderStatus.EscrowRequestRefund,
            OrderStatus.EscrowRequestRelease,
          ].indexOf(value) !== -1,
        "bg-green-500":
          [
            OrderStatus.Paid_2,
            OrderStatus.DeliveryEnded,
            OrderStatus.EscrowReleased,
          ].indexOf(value) !== -1,
      })}
    >
      {value}
    </Chip>
  );
}
