import clsx from "clsx";
import * as React from "react";
import Plotly from "react-plotly.js";

export default function ChartComponent({
  title,
  data,
  className,
}: {
  title: string;
  data: any[];
  className: string;
}) {
  return (
    <div
      className={clsx(
        "w-full bg-white bg-opacity-5 rounded pt-5 pl-5",
        className
      )}
    >
      <Plotly
        className={clsx("w-full h-full", className)}
        data={data}
        layout={{
          title: title,
          xaxis: {
            color: "rgba(255,255,255,1)",
            gridcolor: "rgba(255,255,255,0.1)",
          },
          yaxis: {
            ticksuffix: "TH/sec  ",
            color: "rgba(255,255,255,1)",
            gridcolor: "rgba(255,255,255,0.1)",
          },
          margin: { t: 0, r: 20 },
          paper_bgcolor: "rgba(0,0,0,0)",
          plot_bgcolor: "rgba(0,0,0,0)",
        }}
        config={{
          displayModeBar: false,
        }}
        useResizeHandler={true}
        style={{ width: "100%", height: "100%" }}
      />
    </div>
  );
}
