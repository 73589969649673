import * as React from "react";
import { debounce } from "lodash";

import Input from "../components/core/Input";
import Pagination from "../components/core/Pagination";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import View from "../components/View";
import { BuyerInvoice, MiningStatus, PaginationState } from "../types";
import { get } from "../utils/fetch";
import { apiUrl } from "../utils/url";
import { useNavigate } from "react-router-dom";
import { useEnvironmentContext } from "../context/EnvironmentContext";
import List from "../components/core/List";
import clsx from "clsx";
import Chip from "../components/core/Chip";
import { useAuthContext } from "../context/AuthContext";
import Toolbar from "../components/core/Toolbar";

export default function Invoices() {
  const navigate = useNavigate();
  const { token } = useAuthContext();
  const { environment } = useEnvironmentContext();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [invoices, setInvoices] = React.useState([]);
  const [limit, setLimit] = React.useState<number>(20);
  const [offset, setOffset] = React.useState<number>(0);
  const [total, setTotal] = React.useState<number>(0);

  const loadInvoices = async (query?: string) => {
    if (process.env.REACT_APP_ENV !== "local" && !token) {
      return;
    }

    try {
      setLoading(true);
      const search = !query || query === "" ? "" : `search=${query}&`;
      const res = await get(
        apiUrl(
          environment,
          `/api/ops/invoices?${search}limit=${limit}&offset=${offset}`
        ),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setInvoices(res.results);
      setTotal(res.count);
    } catch (ex) {
      console.error(ex);
    } finally {
      setLoading(false);
    }
  };

  const handlePaginationChange = (state: PaginationState) => {
    setOffset(state.offset);
    setLimit(state.limit);
  };

  React.useEffect(() => {
    loadInvoices();
  }, [offset, limit, environment, token]);

  const debounceLoadInvoices = debounce(loadInvoices, 300);

  return (
    <>
      <Topbar />
      <div className="flex w-full h-screen-excluding-topbar">
        <Sidebar />
        <View>
          <Toolbar>
            <Input
              id="search"
              type="text"
              placeholder="Search Order by id, auction_id, auction_title, account_id or account_email"
              onChange={debounceLoadInvoices}
              className="min-w-[480px]"
            />
            <Pagination
              limit={limit}
              offset={offset}
              total={total}
              onChange={handlePaginationChange}
            />
          </Toolbar>

          <div className="px-3 pb-3">
            <List
              schema={[
                { label: "ID", key: "id", type: "number", align: "left" },
                {
                  label: "Auction",
                  key: "report.auction_title",
                  type: "string",
                  align: "left",
                },
                {
                  label: "Epoch",
                  key: "report.epoch_number",
                  type: "number",
                  align: "left",
                },
                {
                  label: "Account",
                  key: "report.order.account.email",
                  type: "string",
                  align: "left",
                },
                {
                  label: "Hashrate start",
                  key: "report.hashrate_start",
                  type: "datetime",
                  align: "left",
                },
                {
                  label: "Hashrate end",
                  key: "report.hashrate_end",
                  type: "datetime",
                  align: "left",
                },
                {
                  label: "Status",
                  key: (invoice: BuyerInvoice) =>
                    invoice.is_email_sent ? (
                      <span className="text-green-500 text-sm font-semibold">
                        E-mail sent
                      </span>
                    ) : (
                      <span className="text-red-500 text-sm font-semibold">
                        Draft
                      </span>
                    ),
                  type: "custom",
                },
                {
                  label: "Mining Status",
                  key: (invoice: BuyerInvoice) => (
                    <Chip
                      className={clsx("", {
                        "bg-green-500":
                          invoice.report.mining_status ===
                          MiningStatus.Complete,
                        "bg-blue-500":
                          invoice.report.mining_status ===
                          MiningStatus.InProgress,
                        "bg-zinc-500":
                          invoice.report.mining_status === MiningStatus.Pending,
                        "bg-red-500":
                          invoice.report.mining_status ===
                          MiningStatus.Terminated,
                      })}
                    >
                      {invoice.report.mining_status}
                    </Chip>
                  ),
                  type: "custom",
                  align: "left",
                },
              ]}
              data={invoices}
              loading={loading}
              onClick={(invoice: BuyerInvoice) =>
                navigate(
                  `/accounts/${invoice.report.order.account.id}/invoice/${invoice.id}`
                )
              }
            />
          </div>
        </View>
      </div>
    </>
  );
}
