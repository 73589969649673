/* eslint-disable @typescript-eslint/no-empty-function */
import * as React from "react";
import { Environment, EnvironmentContextType } from "../types";

export const EnvironmentContext = React.createContext<EnvironmentContextType>({
  environment: Environment.PROD,
  setEnvironment: (_: string) => {},
});

export const useEnvironmentContext = () => React.useContext(EnvironmentContext);

export function EnvironmentContextProvider({
  children,
}: {
  children?: React.ReactNode;
}) {
  const [environment, setEnvironment] = React.useState<Environment>(() => {
    const env = localStorage.getItem("rigly-admin-env");
    if (env) {
      return env as Environment;
    }
    return Environment.PROD;
  });

  return (
    <EnvironmentContext.Provider value={{ environment, setEnvironment }}>
      {children}
    </EnvironmentContext.Provider>
  );
}
