import * as React from "react";
import { createPortal } from "react-dom";
import clsx from "clsx";

export default function Popover({
  show,
  children,
  target,
  position = "bottom-center",
  onHide,
}: {
  show: boolean;
  children: React.ReactNode;
  target: string;
  position?:
    | "top-left"
    | "top-center"
    | "top-right"
    | "left-top"
    | "left-center"
    | "left-bottom"
    | "bottom-left"
    | "bottom-center"
    | "bottom-right"
    | "right-top"
    | "right-center"
    | "right-bottom";
  onHide: () => void;
}) {
  const popoverRef = React.useRef<HTMLDivElement>(null);
  const [isClosable, setIsClosable] = React.useState<boolean>(false);

  const updatePosition = () => {
    if (!popoverRef.current) {
      return;
    }

    const targetEl = document.querySelector(target);
    if (!targetEl) {
      return;
    }

    const popoverEl = popoverRef.current;
    const popoverRect = popoverEl.getBoundingClientRect();
    const targetRect = targetEl.getBoundingClientRect();
    const isSmallerThanMin = popoverRect.width < targetRect.width;
    const popoverWidth = isSmallerThanMin
      ? targetRect.width
      : popoverRect.width;

    let x = 0;
    let y = 0;

    switch (position) {
      case "top-left":
        x = targetRect.left;
        y = targetRect.top - popoverRect.height;
        break;
      case "top-center":
        x = targetRect.left + targetRect.width / 2 - popoverWidth / 2;
        y = targetRect.top - popoverRect.height;
        break;
      case "top-right":
        x = targetRect.right - popoverWidth;
        y = targetRect.top - popoverRect.height;
        break;
      case "bottom-left":
        x = targetRect.left;
        y = targetRect.bottom;
        break;
      case "bottom-center":
        x = targetRect.left + targetRect.width / 2 - popoverWidth / 2;
        y = targetRect.bottom;
        break;
      case "bottom-right":
        x = targetRect.right - popoverWidth;
        y = targetRect.bottom;
        break;
      case "left-top":
        x = targetRect.left - popoverWidth;
        y = targetRect.top;
        break;
      case "left-center":
        x = targetRect.left - popoverWidth;
        y = targetRect.top + targetRect.height / 2 - popoverRect.height / 2;
        break;
      case "left-bottom":
        x = targetRect.left - popoverWidth;
        y = targetRect.bottom - popoverRect.height;
        break;
      case "right-top":
        x = targetRect.right;
        y = targetRect.top;
        break;
      case "right-center":
        x = targetRect.right;
        y = targetRect.top + targetRect.height / 2 - popoverRect.height / 2;
        break;
      case "right-bottom":
        x = targetRect.right;
        y = targetRect.bottom - popoverRect.height;
        break;
      default:
        x = targetRect.left;
        y = targetRect.top;
    }

    popoverEl.style.minWidth = targetRect.width + "px";
    popoverEl.style.transform = `translate3d(${x}px, ${y}px, 0px)`;
  };

  const handleClickOutside = (event: any) => {
    if (!popoverRef.current || !isClosable) {
      return;
    }

    const withinPopover = popoverRef.current.contains(event.target);
    if (!withinPopover) {
      onHide();
    }
  };

  React.useEffect(() => {
    updatePosition();
    window.addEventListener("resize", updatePosition);
    return () => window.removeEventListener("resize", updatePosition);
  }, [target, popoverRef, position]);

  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [show, popoverRef, isClosable]);

  React.useEffect(() => {
    let t: NodeJS.Timeout;

    if (show) {
      t = setTimeout(() => setIsClosable(true), 50);
    } else {
      setIsClosable(false);
    }

    return () => {
      if (t) {
        clearTimeout(t);
      }
    };
  }, [show]);

  return createPortal(
    <div
      ref={popoverRef}
      className={clsx(
        "rounded shadow-lg absolute top-0 left-0 bg-zinc-800 border border-zinc-600 text-white z-20",
        {
          "visible pointer-events-auto": show,
          "invisible pointer-events-none": !show,
        }
      )}
    >
      {children}
    </div>,
    document.body
  );
}
