/* eslint-disable @typescript-eslint/no-empty-function */
import * as React from "react";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";

import Input from "../components/core/Input";
import Pagination from "../components/core/Pagination";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import View from "../components/View";

import { useEnvironmentContext } from "../context/EnvironmentContext";
import { PaginationState, Payment, PaymentStatus } from "../types";
import { get, post } from "../utils/fetch";
import { apiUrl } from "../utils/url";
import List from "../components/core/List";
import Button from "../components/core/Button";
import clsx from "clsx";
import Loader from "../components/core/Loader";
import Icon from "../components/core/Icon";
import Chip from "../components/core/Chip";
import Checkbox from "../components/core/Checkbox";
import { useAuthContext } from "../context/AuthContext";
import { useModalContext } from "../context/ModalContext";
import AreYouSureModal from "../components/modals/AreYouSureModal";
import Toolbar from "../components/core/Toolbar";

export default function Payments() {
  const { token } = useAuthContext();
  const { environment } = useEnvironmentContext();
  const { showModal, hideModal } = useModalContext();
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [unpaidOnly, setUnpaidOnly] = React.useState<boolean>(false);
  const [paymentReminderLoading, setPaymentReminderLoading] =
    React.useState<boolean>(false);
  const [payments, setPayments] = React.useState([]);
  const [limit, setLimit] = React.useState<number>(20);
  const [offset, setOffset] = React.useState<number>(0);
  const [total, setTotal] = React.useState<number>(0);

  const loadPayments = async () => {
    if (process.env.REACT_APP_ENV !== "local" && !token) {
      return;
    }

    try {
      setLoading(true);
      const status = unpaidOnly ? `&status=unpaid` : "";

      const res = await get(
        apiUrl(
          environment,
          `/api/ops/payments?limit=${limit}&offset=${offset}${status}`
        ),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPayments(res.results);
      setTotal(res.count);
    } catch (ex) {
      console.error(ex);
    } finally {
      setLoading(false);
    }
  };

  const loadPaymentsWithQuery = async (query: string) => {
    if (process.env.REACT_APP_ENV !== "local" && !token) {
      return;
    }

    try {
      setLoading(true);
      const search = query !== "" ? `&search=${query}` : "";
      const status = unpaidOnly ? `&status=unpaid` : "";

      const res = await get(
        apiUrl(
          environment,
          `/api/ops/payments?limit=${limit}&offset=${offset}${status}${search}`
        ),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPayments(res.results);
      setTotal(res.count);
    } catch (ex) {
      console.error(ex);
    } finally {
      setLoading(false);
    }
  };

  const handlePaginationChange = (state: PaginationState) => {
    setOffset(state.offset);
    setLimit(state.limit);
  };

  const handleSendPaymentReminder = async (
    e: React.MouseEvent<HTMLElement>,
    payment: Payment
  ) => {
    if (process.env.REACT_APP_ENV !== "local" && !token) {
      return;
    }

    showModal("are-you-sure", {
      title: "Send payment reminder",
      content: "Confirming this prompt will send an email to the customer",
      onCancel: () => {},
      onConfirm: async () => {
        try {
          e.stopPropagation();
          setPaymentReminderLoading(true);
          await post(
            apiUrl(
              environment,
              `/api/ops/orders/${payment.order.id}/send-payment-reminder`
            ),
            {
              payment_id: payment.id,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          hideModal("are-you-sure");
        } catch (ex) {
          console.error(ex);
        } finally {
          setPaymentReminderLoading(false);
        }
      },
    });
  };

  React.useEffect(() => {
    loadPayments();
  }, [offset, limit, environment, token, unpaidOnly]);

  const debounceLoadPaymentsWithQuery = debounce(loadPaymentsWithQuery, 300);

  return (
    <>
      <Topbar />
      <div className="flex w-full h-screen-excluding-topbar">
        <Sidebar />
        <View>
          <Toolbar>
            <Input
              id="search"
              type="text"
              placeholder="Search Payment by id, order_id, auction_id, auction_title, account_id or account_email"
              onChange={debounceLoadPaymentsWithQuery}
              className="min-w-[580px]"
            />
            <Checkbox
              id="unpaid_only"
              className="mr-auto"
              label="Unpaid only"
              onChange={setUnpaidOnly}
            />
            <Pagination
              limit={limit}
              offset={offset}
              total={total}
              onChange={handlePaginationChange}
            />
          </Toolbar>

          <div className="px-3 pb-3">
            <List
              data={payments}
              loading={loading}
              onClick={(proxy: Payment) => navigate(`/payments/${proxy.id}`)}
              schema={[
                { label: "ID", key: "id", type: "number", align: "left" },
                {
                  label: "Order ID",
                  key: "order.id",
                  type: "number",
                  align: "left",
                },
                {
                  label: "Account",
                  key: "account.email",
                  type: "string",
                  align: "left",
                },
                {
                  label: "Auction",
                  key: (payment: Payment) =>
                    payment.auction
                      ? `${payment.auction.title} (${payment.auction.id})`
                      : `-`,
                  type: "string",
                  align: "left",
                },
                {
                  label: "Status",
                  key: (payment: Payment) => (
                    <Chip
                      className={clsx({
                        "bg-zinc-400": payment.status === PaymentStatus.Unpaid,
                        "bg-red-500": payment.status === PaymentStatus.Expired,
                        "bg-blue-500":
                          payment.status === PaymentStatus.Processing,
                        "bg-green-500": payment.status === PaymentStatus.Paid,
                        "bg-orange-500":
                          payment.status === PaymentStatus.Underpaid,
                        "bg-purple-500":
                          payment.status === PaymentStatus.Refunded,
                      })}
                    >
                      {payment.status}
                    </Chip>
                  ),
                  type: "custom",
                  align: "left",
                },
                {
                  label: "Amount",
                  key: "amount",
                  type: "sats",
                  align: "right",
                },
                {
                  label: "Original Amount",
                  key: "original_amount",
                  type: "sats",
                  align: "right",
                },
                {
                  label: "Provider",
                  key: "provider",
                  type: "string",
                  align: "left",
                },
                {
                  label: "Order Expiry",
                  key: "order.expires_at",
                  type: "datetime",
                  align: "left",
                },
                {
                  label: "Payment Expiry",
                  key: "expires_at",
                  type: "datetime",
                  align: "left",
                },
                {
                  label: "Actions",
                  key: (payment: Payment) =>
                    payment.status === PaymentStatus.Unpaid ? (
                      <Button
                        className="h-8 rounded flex items-center justify-start gap-2 uppercase tracking-wide px-2"
                        color="blue"
                        onClick={(e) => handleSendPaymentReminder(e, payment)}
                        disabled={paymentReminderLoading}
                      >
                        {paymentReminderLoading && (
                          <Loader className="w-4 h-4" />
                        )}
                        {!paymentReminderLoading && (
                          <Icon
                            icon="envelope"
                            className="w-4 h-4 text-white"
                          />
                        )}
                        <span className="text-xs">Send reminder</span>
                      </Button>
                    ) : null,
                  type: "custom",
                  align: "left",
                },
              ]}
            />
          </div>
        </View>
      </div>
      <AreYouSureModal />
    </>
  );
}
