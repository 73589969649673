import * as React from "react";

import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import View from "../components/View";

import PendingRefunds from "../components/refunds/PendingRefunds";
import ProcessedRefunds from "../components/refunds/ProcessedRefunds";

export default function RefundsRoute() {
  return (
    <>
      <Topbar />
      <div className="flex w-full h-screen-excluding-topbar">
        <Sidebar />
        <View className="grid grid-rows-2 p-4 gap-4">
          <PendingRefunds />
          <ProcessedRefunds />
        </View>
      </div>
    </>
  );
}
