import * as React from "react";

import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import View from "../components/View";
import { useParams } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import { useEnvironmentContext } from "../context/EnvironmentContext";
import useIsRequestAllowed from "../hooks/useIsRequestAllowed";
import toast from "react-hot-toast";
import { get } from "../utils/fetch";
import { apiUrl } from "../utils/url";
import { Refund, RefundStatus } from "../types";
import Toolbar from "../components/core/Toolbar";
import Loader from "../components/core/Loader";
import Button from "../components/core/Button";
import { useModalContext } from "../context/ModalContext";
import GrantRefundModal from "../components/modals/GrantRefundModal";

export default function RefundDetail() {
  const { token } = useAuthContext();
  const { environment } = useEnvironmentContext();
  const { isRequestAllowed } = useIsRequestAllowed();
  const { showModal } = useModalContext();
  const params = useParams();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [refund, setRefund] = React.useState<Refund | undefined>(undefined);

  const loadRefund = async () => {
    if (!params.refundId || !isRequestAllowed) {
      return;
    }

    try {
      setLoading(true);
      const res = await get(
        apiUrl(environment, `/api/ops/refunds/${params.refundId}`),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setRefund(res);
    } catch (ex: any) {
      toast.error(ex.message, { position: "bottom-left" });
    } finally {
      setLoading(false);
    }
  };

  const handleGrant = () => {
    showModal("grant-refund", {
      onConfirm: () => {
        loadRefund();
      },
    });
  };

  const handleDeny = () => {
    toast.error("This feature hasn't been implemented yet", {
      position: "bottom-left",
    });
  };

  React.useEffect(() => {
    loadRefund();
  }, [params, token, environment]);

  return (
    <>
      <Topbar />
      <div className="flex w-full h-screen-excluding-topbar">
        <Sidebar />
        <View className="grid grid-rows-1 p-4 gap-4">
          {loading && (
            <div className="w-full h-full flex items-center justify-center">
              <Loader className="w-6 h-6 text-white" />
            </div>
          )}
          {!loading && !refund && (
            <div className="w-full h-full flex items-center justify-center">
              <span className="text-white/50 text-sm">
                Could not load refund request {params.refundId}
              </span>
            </div>
          )}
          {!loading && refund && (
            <div className="flex flex-col">
              <Toolbar className="p-1 pl-4">
                <span className="text-white text-sm">
                  Refund Request {refund.id}
                </span>
                <div className="flex items-center justify-center gap-1">
                  {refund.status === RefundStatus.Pending && (
                    <Button
                      onClick={handleGrant}
                      className="h-8 rounded flex items-center justify-start gap-2 uppercase tracking-wide px-2"
                      color="green"
                    >
                      Grant
                    </Button>
                  )}
                  {refund.status === RefundStatus.Pending && (
                    <Button
                      onClick={handleDeny}
                      className="h-8 rounded flex items-center justify-start gap-2 uppercase tracking-wide px-2"
                      color="red"
                    >
                      Deny
                    </Button>
                  )}
                </div>
              </Toolbar>
            </div>
          )}
        </View>
      </div>
      {refund && <GrantRefundModal refund={refund} />}
    </>
  );
}
