import * as React from "react";
import Card from "../core/Card";
import { useAuthContext } from "../../context/AuthContext";
import { useEnvironmentContext } from "../../context/EnvironmentContext";
import { get } from "../../utils/fetch";
import { apiUrl } from "../../utils/url";
import toast from "react-hot-toast";
import Pagination from "../core/Pagination";
import { Activity, ActivityType, PaginationState } from "../../types";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import Loader from "../core/Loader";

export default function ActivityCard() {
  const { environment } = useEnvironmentContext();
  const { token } = useAuthContext();

  const [limit, setLimit] = React.useState<number>(10);
  const [offset, setOffset] = React.useState<number>(0);
  const [total, setTotal] = React.useState<number>(0);
  const [activity, setActivity] = React.useState<Activity[]>([]);
  const [loadingActivity, setLoadingActivity] = React.useState<boolean>(false);

  const loadActivity = async () => {
    if (process.env.REACT_APP_ENV !== "local" && !token) {
      return;
    }

    try {
      setLoadingActivity(true);
      const res = await get(
        apiUrl(
          environment,
          `/api/ops/activity?limit=${limit}&offset=${offset}`
        ),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setActivity(res.results);
      setTotal(res.count);
    } catch (ex: any) {
      toast.error(ex.message, { position: "bottom-left" });
    } finally {
      setLoadingActivity(false);
    }
  };

  const handlePaginationChange = (state: PaginationState) => {
    setOffset(state.offset);
    setLimit(state.limit);
  };

  React.useEffect(() => {
    loadActivity();
  }, [limit, offset]);

  return (
    <Card className="col-span-6 flex flex-col">
      <Card.Header>
        <Card.Title>Activity</Card.Title>
        <div className="flex items-center justify-end">
          <Pagination
            limit={limit}
            offset={offset}
            total={total}
            onChange={handlePaginationChange}
            small
          />
        </div>
      </Card.Header>
      <Card.Body className="p-2">
        {loadingActivity && (
          <div className="w-full h-full flex items-center justify-center">
            <Loader className="w-8 h-8 text-white" />
          </div>
        )}
        {!loadingActivity &&
          activity.map((item: Activity, i: number) => {
            const name = capitalize(item.account.email.split("@")[0]);
            const message = MESSAGES[item.type]({ ...item, name });
            return (
              <div
                key={i}
                className="px-4 h-10 flex items-center justify-between bg-white even:bg-opacity-10 odd:bg-opacity-5 font-mono text-xs"
              >
                {message}
                <span>
                  {format(new Date(item.created_at), "MMMM d, yyyy HH:mm:ss")}
                </span>
              </div>
            );
          })}
      </Card.Body>
    </Card>
  );
}
const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const MESSAGES: Record<ActivityType, (data: any) => React.ReactNode> = {
  [ActivityType.CreateAuction]: (data: any) => (
    <span>
      <b>{data.name}</b> created an auction
    </span>
  ),
  [ActivityType.CreateInvoice]: (data: any) => (
    <span>
      <b>{data.name}</b> created{" "}
      <Link
        className="underline hover:text-white"
        to={`/accounts/${data.meta.account}/invoice/${data.meta.invoice}`}
      >
        Invoice {data.meta.invoice}
      </Link>
    </span>
  ),
  [ActivityType.EndHashrateDelivery]: (data: any) => (
    <span>
      <b>{data.name}</b> ended a hashrate delivery (
      <Link
        className="underline hover:text-white"
        to={`/orders/${data.meta.order}`}
      >
        Order {data.meta.order}
      </Link>
      )
    </span>
  ),
  [ActivityType.LoginAsUser]: (data: any) => (
    <span>
      <b>{data.name}</b> logged in as{" "}
      <Link
        className="underline hover:text-white"
        to={`/accounts/${data.meta.account}`}
      >
        Account {data.meta.account}
      </Link>
    </span>
  ),
  [ActivityType.PromoteAuctionWinner]: (data: any) => (
    <span>
      <b>{data.name}</b> promoted a runner up
    </span>
  ),
  [ActivityType.SendPaymentReminder]: (data: any) => (
    <span>
      <b>{data.name}</b> sent a payment reminder
    </span>
  ),
  [ActivityType.StartHashrateDelivery]: (data: any) => (
    <span>
      <b>{data.name}</b> started a hashrate delivery (
      <Link
        className="underline hover:text-white"
        to={`/orders/${data.meta.order}`}
      >
        Order {data.meta.order}
      </Link>
      )
    </span>
  ),
  [ActivityType.UntrackedAction]: (data: any) => (
    <span>
      <b>{data.name}</b> performed an unknown action
    </span>
  ),
  [ActivityType.UpdateInvoice]: (data: any) => (
    <span>
      <b>{data.name}</b> updated an invoice
    </span>
  ),
  [ActivityType.SwitchHashrateDelivery]: (data: any) => (
    <span>
      <b>{data.name}</b> switch a hashrate delivery from (
      <Link
        className="underline hover:text-white"
        to={`/orders/${data.meta.from_order}`}
      >
        Order {data.meta.from_order}
      </Link>
      ) to (
      <Link
        className="underline hover:text-white"
        to={`/orders/${data.meta.to_order}`}
      >
        Order {data.meta.to_order}
      </Link>
      )
    </span>
  ),
  [ActivityType.GrantRefund]: (data: any) => (
    <span>
      <b>{data.name}</b> granted a{" "}
      <Link
        className="underline hover:text-white"
        to={`/refunds/${data.meta.refund}`}
      >
        refund
      </Link>{" "}
      to (
      <Link
        className="underline hover:text-white"
        to={`/orders/${data.meta.order}`}
      >
        Order {data.meta.order}
      </Link>
      )
    </span>
  ),
};
