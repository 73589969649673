import * as React from "react";
import clsx from "clsx";
function Card({
  className,
  children,
}: {
  className?: string;
  children?: React.ReactNode[];
}) {
  const childCount = children ? children.length : 0;
  return (
    <div
      className={clsx(
        "bg-white bg-opacity-5 rounded text-white overflow-y-auto grid card",
        className
      )}
      style={{
        gridTemplateRows: childCount === 3 ? "40px auto 40px" : "40px auto",
      }}
    >
      {children}
    </div>
  );
}

Card.Header = function ({
  className,
  children,
}: {
  className?: string;
  children?: React.ReactNode;
}) {
  return (
    <div
      className={clsx(
        "border-b border-zinc-700 h-10 flex items-center justify-between px-3",
        className
      )}
    >
      {children}
    </div>
  );
};

Card.Title = function ({
  className,
  children,
}: {
  className?: string;
  children?: React.ReactNode;
}) {
  return (
    <span className={clsx("text-white text-sm font-bold", className)}>
      {children}
    </span>
  );
};

Card.Body = function ({
  className,
  children,
}: {
  className?: string;
  children?: React.ReactNode;
}) {
  return (
    <div
      className={clsx("card-body overflow-x-hidden overflow-y-auto", className)}
    >
      {children}
    </div>
  );
};

export default Card;
