import * as React from "react";

import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import View from "../components/View";
import Toolbar from "../components/core/Toolbar";
import Button from "../components/core/Button";
import Icon from "../components/core/Icon";
import toast from "react-hot-toast";
import Loader from "../components/core/Loader";
import { apiUrl } from "../utils/url";
import { useAuthContext } from "../context/AuthContext";
import { useEnvironmentContext } from "../context/EnvironmentContext";
import { get } from "../utils/fetch";
import { BlockParty, PaginationState } from "../types";
import List from "../components/core/List";
import { formatAddress } from "../utils/formatter";
import Pagination from "../components/core/Pagination";

export default function RefundsRoute() {
  const { token } = useAuthContext();
  const { environment } = useEnvironmentContext();
  const [sweepLoading, setSweepLoading] = React.useState(false);
  const [blockPartiesLoading, setBlockPartiesLoading] = React.useState(false);
  const [blockParties, setBlockParties] = React.useState([]);
  const [limit, setLimit] = React.useState<number>(20);
  const [offset, setOffset] = React.useState<number>(0);
  const [total, setTotal] = React.useState<number>(0);

  const handleSweep = async (id: number) => {
    try {
      setSweepLoading(true);

      await get(apiUrl(environment, `/api/ops/block-parties/${id}/sweep`), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (ex: any) {
      toast.error(ex.message, { position: "bottom-left" });
    } finally {
      setTimeout(() => {
        setSweepLoading(false);
      }, 1000);
    }
  };

  const loadBlockParties = async () => {
    if (process.env.REACT_APP_ENV !== "local" && !token) {
      return;
    }

    try {
      setBlockPartiesLoading(true);

      const res = await get(
        apiUrl(
          environment,
          `/api/products/block-parties?limit=${limit}&offset=${offset}`
        ),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setBlockParties(res.results);
      setTotal(res.count);
      console.log(res);
    } catch (ex: any) {
      toast.error(ex.message, { position: "bottom-left" });
    } finally {
      setBlockPartiesLoading(false);
    }
  };

  const handlePaginationChange = (state: PaginationState) => {
    setOffset(state.offset);
    setLimit(state.limit);
  };

  React.useEffect(() => {
    loadBlockParties();
  }, [offset, limit, environment, token]);

  return (
    <>
      <Topbar />
      <div className="flex w-full h-screen-excluding-topbar">
        <Sidebar />
        <View>
          <Toolbar>
            <Pagination
              limit={limit}
              offset={offset}
              total={total}
              onChange={handlePaginationChange}
            />
            <div className="flex items-center justify-center gap-1">
              <Button
                className="h-8 rounded flex items-center justify-start gap-2 uppercase tracking-wide px-2"
                color="blue"
                onClick={() => handleSweep(1)}
              >
                {sweepLoading && <Loader className="w-4 h-4" />}
                {!sweepLoading && (
                  <Icon icon="broom" className="w-4 h-4 text-white" />
                )}
                <span className="text-xs">Sweep payments</span>
              </Button>
            </div>
          </Toolbar>
          <div className="px-3 pb-3">
            <List
              data={blockParties}
              loading={blockPartiesLoading}
              schema={[
                { label: "ID", key: "id", type: "number", align: "left" },
                { label: "Name", key: "name", type: "string", align: "left" },
                {
                  label: "Hashrate Goal",
                  key: (blockParty: BlockParty) => (
                    <span className="text-xs text-white font-mono">
                      {blockParty.hashrate_ths} TH/s
                    </span>
                  ),
                  type: "custom",
                },
                {
                  label: "Host",
                  key: "host.email",
                  type: "string",
                  align: "left",
                },
                {
                  label: "Payment Address",
                  key: (blockParty: BlockParty) => (
                    <span className="text-xs text-white font-mono">
                      {formatAddress(blockParty.payment_address) || "-"}
                    </span>
                  ),
                  type: "custom",
                },
              ]}
            />
          </div>
        </View>
      </div>
    </>
  );
}
