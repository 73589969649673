import * as React from "react";
import {
  SellerInvoice,
  BuyerInvoice,
  // Account
} from "../../types";
import Code from "../core/Code";

export default function InvoiceRawGraph({
  // account,
  invoice,
}: {
  // account: Account | undefined;
  invoice: BuyerInvoice | SellerInvoice;
}) {
  return (
    <div className="flex gap-4 items-start grow">
      <Code>{JSON.stringify(invoice.figure, null, 2)}</Code>
    </div>
  );
}
