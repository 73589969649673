import * as React from "react";
import clsx from "clsx";

export default function Checkbox({
  id,
  className = "px-4",
  label,
  onChange,
  value,
  disabled,
  required,
}: {
  id: string;
  className?: string;
  label?: string;
  value?: boolean;
  onChange?: (state: boolean) => void;
  disabled?: boolean;
  required?: boolean;
}) {
  const [val, setVal] = React.useState<boolean>(value || false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) {
      return;
    }

    setVal(e.target.checked);
    if (onChange) {
      onChange(e.target.checked);
    }
  };

  if (!label) {
    return (
      <label
        htmlFor={id}
        className={clsx(
          "checkbox w-4 text-white text-sm flex items-center justify-center",
          className,
          { disabled: disabled }
        )}
      >
        {label && label}
        <input
          type="checkbox"
          id={id}
          name={id}
          onInput={handleChange}
          onChange={handleChange}
          checked={val}
          required={required}
        />
        <span className="checkmark" />
      </label>
    );
  }

  return (
    <label
      htmlFor={id}
      className={clsx(
        "checkbox text-white text-sm bg-zinc-800 rounded flex items-center justify-start h-10",
        className,
        { disabled: disabled }
      )}
    >
      {label && label}
      <input
        type="checkbox"
        id={id}
        name={id}
        onInput={handleChange}
        onChange={handleChange}
        checked={val}
        required={required}
      />
      <span className="checkmark" />
    </label>
  );
}
