import * as React from "react";
import { debounce } from "lodash";

import Input from "../components/core/Input";
import Pagination from "../components/core/Pagination";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import View from "../components/View";
import { Epoch, Order, OrderType, PaginationState } from "../types";
import { get } from "../utils/fetch";
import { apiUrl } from "../utils/url";
import { useNavigate } from "react-router-dom";
import { useEnvironmentContext } from "../context/EnvironmentContext";
import OrderStatusChip from "../components/OrderStatusChip";
import List from "../components/core/List";
import { useAuthContext } from "../context/AuthContext";
import Select from "../components/core/Select";
import toast from "react-hot-toast";
import Toolbar from "../components/core/Toolbar";

export default function Orders() {
  const { token } = useAuthContext();
  const { environment } = useEnvironmentContext();
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [loadingEpochs, setLoadingEpochs] = React.useState<boolean>(false);
  const [epochs, setEpochs] = React.useState<Epoch[]>([]);
  const [orders, setOrders] = React.useState([]);
  const [limit, setLimit] = React.useState<number>(20);
  const [offset, setOffset] = React.useState<number>(0);
  const [total, setTotal] = React.useState<number>(0);
  const [epoch, setEpoch] = React.useState<number | undefined>(undefined);

  const loadEpochs = async () => {
    if (process.env.REACT_APP_ENV !== "local" && !token) {
      return;
    }

    try {
      setLoadingEpochs(true);
      const res = await get(apiUrl(environment, `/api/ops/mining/epochs`), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setEpochs(res);
    } catch (ex: any) {
      toast.error(ex.message, { position: "bottom-left" });
    } finally {
      setLoadingEpochs(false);
    }
  };

  const loadOrders = async (query?: string) => {
    if (process.env.REACT_APP_ENV !== "local" && !token) {
      return;
    }

    try {
      setLoading(true);
      const searchParam = !query || query === "" ? "" : `&search=${query}`;
      const epochParam = epoch === undefined ? "" : `&epoch=${epoch}`;
      const res = await get(
        apiUrl(
          environment,
          `/api/ops/orders?limit=${limit}&offset=${offset}${searchParam}${epochParam}`
        ),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setOrders(res.results);
      setTotal(res.count);
    } catch (ex: any) {
      toast.error(ex.message, { position: "bottom-left" });
    } finally {
      setLoading(false);
    }
  };

  const handlePaginationChange = (state: PaginationState) => {
    setOffset(state.offset);
    setLimit(state.limit);
  };

  const handleEpochChange = (epoch: number) => {
    setEpoch(epoch);
  };

  React.useEffect(() => {
    loadOrders();
  }, [offset, limit, environment, token, epoch]);

  React.useEffect(() => {
    loadEpochs();
  }, [environment, token]);

  const debounceLoadOrders = debounce(loadOrders, 300);
  const epochOptions = epochs.map((epoch: Epoch) => ({
    label: `Epoch ${epoch.epoch_number}`,
    value: epoch.epoch_number,
  }));

  return (
    <>
      <Topbar />
      <div className="flex w-full h-screen-excluding-topbar">
        <Sidebar />
        <View>
          <Toolbar>
            <div className="flex items-center justify-start gap-4">
              <Input
                id="search"
                type="text"
                placeholder="Search Order by id, auction_id, auction_title, account_id or account_email"
                onChange={debounceLoadOrders}
                className="min-w-[480px]"
              />
              <Select
                id="epoch"
                placeholder="Epoch"
                clearLabel="Clear epoch"
                className="min-w-[200px]"
                options={epochOptions}
                value={epoch}
                onChange={handleEpochChange}
                loading={loadingEpochs}
                clearable
              />
            </div>
            <Pagination
              limit={limit}
              offset={offset}
              total={total}
              onChange={handlePaginationChange}
            />
          </Toolbar>

          <div className="px-3 pb-3">
            <List
              data={orders}
              loading={loading}
              onClick={(order: Order) => navigate(`/orders/${order.id}`)}
              schema={[
                { label: "ID", key: "id", type: "number", align: "left" },
                {
                  label: "Epoch",
                  key: (order: Order) => (
                    <span className="text-white text-sm font-mono">
                      {order.type === OrderType.BlockParty
                        ? "-"
                        : order.type === OrderType.Direct
                        ? "-"
                        : order.auction!.auction_type.type === "forward_date"
                        ? order.auction!.epoch.epoch_number
                        : "-"}
                    </span>
                  ),
                  type: "custom",
                },
                {
                  label: "Auction",
                  key: (order: Order) => (
                    <span className="text-white text-sm">
                      {order.auction
                        ? order.auction.title
                        : `Direct Sale ${order.id}`}
                    </span>
                  ),
                  type: "custom",
                },
                {
                  label: "Account",
                  key: "account.email",
                  type: "string",
                  align: "left",
                },
                {
                  label: "Status",
                  key: (order: Order) => (
                    <OrderStatusChip value={order.status} />
                  ),
                  type: "custom",
                },
                {
                  label: "Previous Status",
                  key: (order: Order) => (
                    <OrderStatusChip value={order.previous_status} />
                  ),
                  type: "custom",
                },
                {
                  label: "Expiry date",
                  key: "expires_at",
                  type: "datetime",
                },
              ]}
            />
          </div>
        </View>
      </div>
    </>
  );
}
