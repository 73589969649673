import * as React from "react";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";

import Input from "../components/core/Input";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import View from "../components/View";
import { Auction, AuctionType, PaginationState } from "../types/index";
import { get, post } from "../utils/fetch";
import { apiUrl } from "../utils/url";
import Pagination from "../components/core/Pagination";
import { useEnvironmentContext } from "../context/EnvironmentContext";
import List from "../components/core/List";
import AuctionStatusChip from "../components/AuctionStatusChip";
import { useAuthContext } from "../context/AuthContext";
import Toolbar from "../components/core/Toolbar";
import Loader from "../components/core/Loader";
import Button from "../components/core/Button";
import Icon from "../components/core/Icon";
import toast from "react-hot-toast";
import { useModalContext } from "../context/ModalContext";
import CreateAuctionModal from "../components/modals/CreateAuctionModal";

export default function Auctions() {
  const { token } = useAuthContext();
  const { environment } = useEnvironmentContext();
  const { showModal, hideModal } = useModalContext();
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [createAuctionLoading, setCreateAuctionLoading] =
    React.useState<boolean>(false);

  const [auctions, setAuctions] = React.useState([]);
  const [limit, setLimit] = React.useState<number>(20);
  const [offset, setOffset] = React.useState<number>(0);
  const [total, setTotal] = React.useState<number>(0);

  const loadAuctions = async () => {
    if (process.env.REACT_APP_ENV !== "local" && !token) {
      return;
    }

    try {
      setLoading(true);
      const res = await get(
        apiUrl(
          environment,
          `/api/ops/auctions?limit=${limit}&offset=${offset}`
        ),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAuctions(res.results);
      setTotal(res.count);
    } catch (ex) {
      console.error(ex);
    } finally {
      setLoading(false);
    }
  };

  const loadAuctionsWithQuery = async (query: string) => {
    if (process.env.REACT_APP_ENV !== "local" && !token) {
      return;
    }

    try {
      setLoading(true);
      const search = query === "" ? "" : `search=${query}&`;
      const res = await get(
        apiUrl(
          environment,
          `/api/ops/auctions?${search}limit=${limit}&offset=${offset}`
        ),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAuctions(res.results);
      setTotal(res.count);
    } catch (ex) {
      console.error(ex);
    } finally {
      setLoading(false);
    }
  };

  const handlePaginationChange = (state: PaginationState) => {
    setOffset(state.offset);
    setLimit(state.limit);
  };

  const handleCreateAuction = async () => {
    if (process.env.REACT_APP_ENV !== "local" && !token) {
      return;
    }

    showModal("create-auction", {
      onSave: async (data: any) => {
        try {
          setCreateAuctionLoading(true);
          const res = await post(
            apiUrl(environment, `/api/ops/auctions/create`),
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          toast.success(`Auction created (${res.id})`, {
            position: "bottom-left",
          });
          hideModal("create-auction");
          await loadAuctions();
        } catch (ex: any) {
          toast.error(ex.message, { position: "bottom-left" });
        } finally {
          setCreateAuctionLoading(false);
        }
      },
    });
  };

  React.useEffect(() => {
    loadAuctions();
  }, [offset, limit, environment, token]);

  const debounceLoadAuctionsWithQuery = debounce(loadAuctionsWithQuery, 300);

  return (
    <>
      <Topbar />
      <div className="flex w-full h-screen-excluding-topbar">
        <Sidebar />
        <View>
          <Toolbar>
            <Input
              id="search"
              type="text"
              placeholder="Search Auction by id, title or epoch number"
              onChange={debounceLoadAuctionsWithQuery}
              className="min-w-[320px]"
            />
            <Pagination
              limit={limit}
              offset={offset}
              total={total}
              onChange={handlePaginationChange}
            />
            <Button
              className="h-8 rounded flex items-center justify-start gap-2 uppercase tracking-wide px-2"
              color="blue"
              onClick={handleCreateAuction}
              disabled={createAuctionLoading}
            >
              {createAuctionLoading && <Loader className="w-4 h-4" />}
              {!createAuctionLoading && (
                <Icon icon="plus" className="w-4 h-4 text-white" />
              )}
              <span className="text-xs">Create auction</span>
            </Button>
          </Toolbar>

          <div className="px-3 pb-3">
            <List
              data={auctions}
              loading={loading}
              onClick={(auction: Auction) =>
                navigate(`/auctions/${auction.id}`)
              }
              schema={[
                { label: "ID", key: "id", type: "number", align: "left" },
                { label: "Title", key: "title", type: "string", align: "left" },
                {
                  label: "Epoch",
                  key: "epoch.epoch_number",
                  type: "number",
                  align: "left",
                  show: (auction: Auction) =>
                    auction.auction_type.type === AuctionType.ForwardDate,
                  fallback: "-",
                },
                {
                  label: "Type",
                  key: "auction_type.type",
                  type: "string",
                  align: "left",
                },
                {
                  label: "Start at",
                  key: "start_at",
                  type: "datetime",
                  align: "left",
                },
                {
                  label: "End at",
                  key: "end_at",
                  type: "datetime",
                  align: "left",
                },
                {
                  label: "Status",
                  key: (auction: Auction) => (
                    <AuctionStatusChip value={auction.status} />
                  ),
                  type: "custom",
                },
              ]}
            />
          </div>
        </View>
      </div>
      <CreateAuctionModal />
    </>
  );
}
