import clsx from "clsx";
import * as React from "react";
import Card from "../components/core/Card";
import List from "../components/core/List";
import OrdersList from "../components/OrdersList";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import View from "../components/View";
import { useAuthContext } from "../context/AuthContext";
import { useEnvironmentContext } from "../context/EnvironmentContext";
import { get } from "../utils/fetch";
import { apiUrl } from "../utils/url";
import ActivityCard from "../components/activity/ActivityCard";
import randomNumber from "../utils/random-number";

export default function Dashboard() {
  const { environment } = useEnvironmentContext();
  const { token } = useAuthContext();
  const [orders, setOrders] = React.useState([]);
  const [loadingOrders, setLoadingOrders] = React.useState<boolean>(false);
  // const [ordersLimit, setOrdersLimit] = React.useState<number>(10);
  // const [ordersOffset, setOrdersOffset] = React.useState<number>(0);
  // const [ordersTotal, setOrdersTotal] = React.useState<number>(0);

  const loadUpcomingOrders = async () => {
    if (process.env.REACT_APP_ENV !== "local" && !token) {
      return;
    }

    try {
      setLoadingOrders(true);
      const res = await get(apiUrl(environment, `/api/ops/orders/upcoming`), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setOrders(res.results);
      // setOrdersTotal(res.count);
    } catch (ex) {
      console.error(ex);
    } finally {
      setLoadingOrders(false);
    }
  };

  React.useEffect(() => {
    loadUpcomingOrders();
  }, [environment, token]);

  return (
    <>
      <Topbar />
      <div className="flex w-full h-screen-excluding-topbar">
        <Sidebar />
        <View>
          <div className="w-full h-full grid grid-rows-2 grid-cols-12 gap-4">
            <Card className="col-span-6 flex flex-col">
              <Card.Header>
                <Card.Title>Services</Card.Title>
              </Card.Header>
              <Card.Body className="p-2">
                <List
                  schema={[
                    {
                      label: "Name",
                      key: "name",
                      type: "string",
                      align: "left",
                    },
                    {
                      label: "Latency",
                      key: ({ latency }: { latency: number }) => (
                        <span>
                          {latency}
                          <i className="not-italic text-zinc-400">ms</i>
                        </span>
                      ),
                      type: "custom",
                    },
                    {
                      label: "Status",
                      key: ({ status }: { status: "online" | "offline" }) => (
                        <span
                          className={clsx({
                            "text-green-500": status === "online",
                            "text-red-500": status === "offline",
                          })}
                        >
                          {status}
                        </span>
                      ),
                      type: "custom",
                    },
                  ]}
                  data={[
                    {
                      name: "rigly-backend-prod",
                      latency: randomNumber(5, 25),
                      status: "online",
                    },
                    {
                      name: "rigly-backend-qa",
                      latency: randomNumber(5, 25),
                      status: "online",
                    },
                    {
                      name: "rigly-frontend-prod",
                      latency: randomNumber(5, 25),
                      status: "online",
                    },
                    {
                      name: "rigly-frontend-qa",
                      latency: randomNumber(5, 25),
                      status: "online",
                    },
                    {
                      name: "rigly-notifications",
                      latency: randomNumber(5, 25),
                      status: "online",
                    },
                    {
                      name: "rigly-data",
                      latency: randomNumber(5, 25),
                      status: "online",
                    },
                    {
                      name: "bitgo-express",
                      latency: randomNumber(5, 25),
                      status: "online",
                    },
                  ]}
                  loading={false}
                />
              </Card.Body>
            </Card>
            <ActivityCard />
            <Card className="col-span-12 flex flex-col">
              <Card.Header>
                <Card.Title>Upcoming orders</Card.Title>
              </Card.Header>
              <Card.Body className="p-2">
                <OrdersList orders={orders} loading={loadingOrders} />
              </Card.Body>
            </Card>
          </div>
        </View>
      </div>
    </>
  );
}
