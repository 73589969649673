import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from "../components/core/Button";
import Input from "../components/core/Input";
import Loader from "../components/core/Loader";
import { useAuthContext } from "../context/AuthContext";
import { useEnvironmentContext } from "../context/EnvironmentContext";
import { Environment } from "../types";
import { post } from "../utils/fetch";
import { adminUrl, apiUrl } from "../utils/url";

enum View {
  Login = "login",
  EmailSent = "email-ssent",
}

export default function Login({ callback }: { callback?: boolean }) {
  const navigate = useNavigate();
  const { setToken } = useAuthContext();
  const [searchParams] = useSearchParams();
  const { environment } = useEnvironmentContext();

  const [authLoading, setAuthLoading] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>("");
  const [view, setView] = React.useState<View>(View.Login);

  const authorize = async () => {
    const { token } = await post(
      apiUrl(Environment.PROD, "/api/auth/authorize"),
      {
        code: searchParams.get("code"),
        email: searchParams.get("email"),
      }
    );

    window.localStorage.setItem("rigly_admin_token", token);
    setToken(token);

    navigate("/");
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      setAuthLoading(true);
      await post(apiUrl(Environment.PROD, "/api/auth/login"), {
        email,
        url: adminUrl(environment),
        return_url: adminUrl(environment),
      });

      setView(View.EmailSent);
    } catch (ex) {
      console.error(ex);
    } finally {
      setAuthLoading(false);
    }
  };

  React.useEffect(() => {
    if (callback) {
      authorize();
    }
  }, [callback, searchParams]);

  return (
    <div className="w-full h-screen flex items-center justify-center">
      <div className="p-4 bg-zinc-800 rounded flex flex-col gap-4 min-w-[420px] max-w-[420px]">
        {view === View.Login && (
          <>
            <div className="flex flex-col gap-2">
              <h1 className="text-white text-2xl font-semibold">Sign in</h1>
              <span className="text-zinc-400">
                Sign in using your Rigly staff account
              </span>
            </div>
            <form onSubmit={handleSubmit} className="flex flex-col gap-4">
              <div className="field">
                <Input
                  id="email"
                  className="w-full"
                  type="email"
                  placeholder="E-mail"
                  onChange={setEmail}
                  required
                />
              </div>
              <div className="field">
                <Button className="rounded h-10 bg-blue-600 hover:bg-blue-700 flex items-center justify-center px-4">
                  {authLoading && <Loader className="text-white h-5 h-5" />}
                  {!authLoading && (
                    <span className="text-white uppercase tracking-wide">
                      Sign in
                    </span>
                  )}
                </Button>
              </div>
            </form>
          </>
        )}
        {view === View.EmailSent && (
          <div className="flex flex-col gap-2">
            <h1 className="text-white text-2xl font-semibold">
              Check your email
            </h1>
            <span className="text-zinc-400">
              If the account exists and you have the right credentials you
              should have a Sign In email.
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
