import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import clsx from "clsx";

import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import View from "../components/View";
import { get, post } from "../utils/fetch";
import Chip from "../components/core/Chip";
import {
  Auction,
  AuctionResult,
  AuctionStatus,
  Bid,
  Order,
} from "../types/index";
import { apiUrl } from "../utils/url";
import Button from "../components/core/Button";
import Fields from "../components/Fields";
import Loader from "../components/core/Loader";
import { useEnvironmentContext } from "../context/EnvironmentContext";
import List from "../components/core/List";
import OrderStatusChip from "../components/OrderStatusChip";
import { useAuthContext } from "../context/AuthContext";
import Toolbar from "../components/core/Toolbar";

export default function Auctions() {
  const params = useParams();
  const { environment } = useEnvironmentContext();
  const { token } = useAuthContext();
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [promoteWinnerLoading, setPromoteWinnerLoading] =
    React.useState<boolean>(false);
  const [auction, setAuction] = React.useState<Auction | undefined>(undefined);
  const [auctionResult, setAuctionResult] = React.useState<
    AuctionResult[] | undefined
  >(undefined);

  const [bids, setBids] = React.useState<any>([]);
  const [orders, setOrders] = React.useState<Order[] | undefined>(undefined);

  const load = async () => {
    if (!params.auctionId || !token) {
      return;
    }

    try {
      setLoading(true);
      const auctionId = Number(params.auctionId);
      const res = await get(
        apiUrl(environment, `/api/ops/auctions/${auctionId}`),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setAuction(res.auction);
      setOrders(res.orders);
      setBids(res.bids);

      if (res.auction.status === "completed") {
        setAuctionResult(res.auction_result);
      }
    } catch (ex) {
      console.error(ex);
    } finally {
      setLoading(false);
    }
  };

  const handlePromoteWinner = async (result: AuctionResult) => {
    try {
      setPromoteWinnerLoading(true);
      await post(apiUrl(environment, "/api/ops/auctions/promote-winner"), {
        auction: result.auction,
        account: result.account.id,
      });

      load();
    } catch (ex) {
      console.error(ex);
    } finally {
      setPromoteWinnerLoading(false);
    }
  };

  React.useEffect(() => {
    load();
  }, [params, environment, token]);

  const auctionResultWinnerIndex = auctionResult
    ? auctionResult.findIndex((result) => result.is_winner)
    : 0;

  return (
    <>
      <Topbar />
      <div className="flex w-full h-screen-excluding-topbar">
        <Sidebar />
        <View>
          {auction && (
            <>
              <Toolbar>
                <span className="text-white text-sm font-semibold">
                  {auction.title}
                </span>
              </Toolbar>
              <div className="flex flex-col gap-2 px-3">
                <h2 className="text-xl text-white font-semibold">Fields</h2>
                <Fields
                  data={auction}
                  schema={{
                    fields: [
                      { key: "id", label: "ID", type: "number" },
                      { key: "title", label: "Title", type: "string" },
                      {
                        key: "auction_type.type",
                        label: "Type",
                        type: "string",
                      },
                      {
                        key: "epoch.epoch_number",
                        label: "Epoch",
                        type: "number",
                      },
                      {
                        label: "Status",
                        type: "component",
                        meta: (auction: Auction) => {
                          return (
                            <Chip
                              className={clsx("", {
                                "bg-red-500":
                                  auction.status === AuctionStatus.Scheduled,
                                "bg-blue-500":
                                  auction.status === AuctionStatus.Active,
                                "bg-green-500":
                                  auction.status === AuctionStatus.Completed,
                              })}
                            >
                              {auction.status}
                            </Chip>
                          );
                        },
                      },
                      // {
                      //   key: "original_total",
                      //   label: "Original total",
                      //   type: "sats",
                      // },
                      // { key: "total", label: "Total", type: "sats" },
                      // {
                      //   key: "mining_deposit",
                      //   label: "Mining deposit",
                      //   type: "sats",
                      // },
                      // { key: "auction_fee", label: "Auction fee", type: "sats" },
                      // {
                      //   key: "expires_at",
                      //   label: "Expires at",
                      //   type: "datetime",
                      //   meta: "MM/dd/yyyy HH:mm:ss",
                      // },
                    ],
                  }}
                />
              </div>
            </>
          )}
          {auctionResult && (
            <div className="flex flex-col gap-2 px-3">
              <h2 className="text-xl text-white font-semibold">
                Auction result
              </h2>

              <List
                data={auctionResult}
                loading={loading}
                schema={[
                  {
                    label: "Position",
                    key: (_: AuctionResult, i: number) => (
                      <span className="text-white text-sm">{i + 1}</span>
                    ),
                    type: "custom",
                  },
                  {
                    label: "Account",
                    key: "account.email",
                    type: "string",
                    align: "left",
                  },
                  {
                    label: "Bid amount",
                    key: "bid_price",
                    type: "sats",
                    align: "left",
                  },
                  {
                    label: "Bid amount",
                    key: (result: AuctionResult, i: number) => (
                      <span className="px-4 col-span-2 text-white flex items-center justify-start">
                        {result.is_winner && (
                          <Chip className="bg-green-500">Winner</Chip>
                        )}
                        {i === auctionResultWinnerIndex + 1 && (
                          <Chip className="bg-orange-500">Runner-up</Chip>
                        )}
                      </span>
                    ),
                    type: "custom",
                  },
                  {
                    label: "Updated at",
                    key: "updated_at",
                    type: "datetime",
                    align: "left",
                  },
                  {
                    label: "Actions",
                    key: (result: AuctionResult) => (
                      <div className="col-span-2">
                        {!result.is_winner && (
                          <Button
                            color="green"
                            disabled={promoteWinnerLoading}
                            className="h-8 rounded w-full flex items-center justify-center px-4"
                            onClick={() => handlePromoteWinner(result)}
                          >
                            {promoteWinnerLoading && (
                              <Loader className="w-5 h-5" />
                            )}
                            {!promoteWinnerLoading && (
                              <span className="text-xs tracking-wide uppercase whitespace-nowrap">
                                Promote to winner
                              </span>
                            )}
                          </Button>
                        )}
                      </div>
                    ),
                    type: "custom",
                  },
                ]}
              />
            </div>
          )}
          {bids && (
            <div className="flex flex-col gap-2 px-3">
              <h2 className="text-xl text-white font-semibold">Bids</h2>
              <List
                schema={[
                  { label: "ID", key: "id", type: "number", align: "left" },
                  {
                    label: "Amount",
                    key: "bid",
                    type: "sats",
                    align: "left",
                  },
                  {
                    label: "Account",
                    key: "account.email",
                    type: "string",
                    align: "left",
                  },
                ]}
                data={bids}
                loading={loading}
                onClick={(proxy: Bid) => navigate(`/bids/${proxy.id}`)}
              />
            </div>
          )}
          {orders && orders.length > 0 && (
            <div className="flex flex-col gap-2">
              <h2 className="text-xl text-white font-semibold">Orders</h2>
              <List
                data={orders}
                loading={loading}
                onClick={(order: Order) => navigate(`/orders/${order.id}`)}
                schema={[
                  {
                    label: "ID",
                    type: "number",
                    key: "id",
                    align: "left",
                  },
                  {
                    label: "Account",
                    type: "string",
                    key: "account.email",
                    align: "left",
                  },
                  {
                    label: "Total price",
                    type: "sats",
                    key: "original_total",
                    align: "left",
                  },
                  {
                    label: "Status",
                    type: "custom",
                    key: (order: Order) => (
                      <OrderStatusChip value={order.status} />
                    ),
                  },
                  {
                    label: "Updated at",
                    type: "datetime",
                    key: "updated_at",
                    align: "left",
                  },
                ]}
              />
            </div>
          )}
        </View>
      </div>
    </>
  );
}
