import * as React from "react";
import clsx from "clsx";

export default function Button({
  className = "uppercase tracking-wide h-12 px-4",
  children,
  onClick,
  disabled,
  color,
  hoverText,
  hoverPosition,
}: {
  className?: string;
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  color?: "transparent" | "green" | "blue" | "red" | "gray" | "purple";
  hoverText?: string;
  hoverPosition?: "right" | "up";
}) {
  return (
    <button
      className={clsx(
        className,
        "text-sm font-semibold text-white disabled:cursor-not-allowed disabled:bg-zinc-600 disabled:text-zinc-400",
        {
          "text-white hover:bg-white hover:bg-opacity-5":
            color === "transparent",
          "text-white bg-green-700 hover:bg-green-500": color === "green",
          "text-white bg-red-700 hover:bg-red-500": color === "red",
          "text-white bg-blue-700 hover:bg-blue-500": color === "blue",
          "text-white bg-zinc-700 hover:bg-zinc-500": color === "gray",
          "text-white bg-purple-700 hover:bg-purple-500": color === "purple",
        }
      )}
      disabled={disabled}
      onClick={onClick}
      aria-label={hoverText}
      data-balloon-pos={hoverPosition}
    >
      {children}
    </button>
  );
}
