import * as React from "react";
import { useModalContext } from "../../context/ModalContext";
import Button from "../core/Button";
import Modal from "../core/Modal";
import { Refund } from "../../types";
import OrderSummary from "../orders/OrderSummary";
import toast from "react-hot-toast";
import Loader from "../core/Loader";
import { apiUrl } from "../../utils/url";
import { useEnvironmentContext } from "../../context/EnvironmentContext";
import { useAuthContext } from "../../context/AuthContext";
import { post } from "../../utils/fetch";

export default function GrantRefundModal({ refund }: { refund: Refund }) {
  const { token } = useAuthContext();
  const { environment } = useEnvironmentContext();
  const { modals, hideModal } = useModalContext();
  const { active, meta } = modals["grant-refund"];

  const [loading, setLoading] = React.useState<boolean>(false);

  const handleCancel = () => {
    hideModal("grant-refund");
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);
      await post(
        apiUrl(environment, `/api/ops/refunds/grant`),
        { refund_id: refund.id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      hideModal("grant-refund");
      meta.onConfirm();
    } catch (ex: any) {
      toast.error(ex.message, { position: "bottom-left" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal active={active} onClose={handleCancel}>
      <Modal.Header>
        <Modal.Title>Grant Refund - Order {refund.order.id}</Modal.Title>
        <Modal.Close />
      </Modal.Header>
      <Modal.Body className="p-2">
        <div className="text-white text-sm leading-7">
          <span>Are you sure you want to grant a refund to this order?</span>
        </div>
        <OrderSummary order={refund.order} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="h-8 rounded flex items-center justify-start gap-2 uppercase tracking-wide px-2"
          color="gray"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          className="h-8 rounded flex items-center justify-start gap-2 uppercase tracking-wide px-2"
          color="green"
          onClick={handleConfirm}
          disabled={loading}
        >
          {loading && <Loader className="w-4 h-4 text-white" />}
          {!loading && <span>Grant</span>}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
