import * as React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function TextEditor({
  value,
  onChange,
}: {
  id: string;
  value?: string;
  onChange: (val: string) => void;
}) {
  return (
    <div className="bg-white w-full">
      <ReactQuill theme="snow" value={value} onChange={onChange} />
    </div>
  );
}
