import * as React from "react";
import toast from "react-hot-toast";

import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import View from "../components/View";
import { get, post } from "../utils/fetch";
import { apiUrl } from "../utils/url";
import { useEnvironmentContext } from "../context/EnvironmentContext";
import Fields from "../components/Fields";
import Loader from "../components/core/Loader";
import Toolbar from "../components/core/Toolbar";
import Icon from "../components/core/Icon";
import Button from "../components/core/Button";
import { useModalContext } from "../context/ModalContext";
import CreateEpochModal from "../components/modals/CreateEpochModal";
import { useAuthContext } from "../context/AuthContext";

export default function Mining() {
  const { token } = useAuthContext();
  const { environment } = useEnvironmentContext();
  const { showModal, hideModal } = useModalContext();

  const [loadingNextEpoch, setLoadingNextEpoch] =
    React.useState<boolean>(false);
  const [createEpochLoading, setCreateEpochLoading] =
    React.useState<boolean>(false);
  const [nextEpoch, setNextEpoch] = React.useState<number | undefined>(
    undefined
  );

  const loadNextEpoch = async () => {
    if (process.env.REACT_APP_ENV !== "local" && !token) {
      return;
    }

    try {
      setLoadingNextEpoch(true);
      const res = await get(apiUrl(environment, `/api/ops/mining/next-epoch`), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setNextEpoch(res.epoch_number);
    } catch (ex: any) {
      toast.error(ex.message, { position: "bottom-left" });
    } finally {
      setLoadingNextEpoch(false);
    }
  };

  const handleCreateEpoch = () => {
    showModal("create-epoch", {
      onSave: async (epoch_number: number) => {
        try {
          setCreateEpochLoading(true);
          hideModal("create-epoch");
          await post(
            apiUrl(environment, `/api/ops/mining/create-epoch`),
            {
              epoch_number,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        } catch (ex: any) {
          toast.error(ex.message, { position: "bottom-left" });
        } finally {
          setCreateEpochLoading(false);
        }
      },
    });
  };

  React.useEffect(() => {
    loadNextEpoch();
  }, [environment, token]);

  return (
    <>
      <Topbar />
      <div className="flex w-full h-screen-excluding-topbar">
        <Sidebar />
        <View>
          {loadingNextEpoch && (
            <div className="w-full h-full flex items-center justify-center">
              <Loader className="w-8 h-8 text-white" />
            </div>
          )}
          {!loadingNextEpoch && (
            <>
              <Toolbar>
                <Button
                  className="h-8 rounded flex items-center justify-start gap-2 uppercase tracking-wide px-2"
                  color="gray"
                  onClick={handleCreateEpoch}
                  disabled={createEpochLoading}
                >
                  {createEpochLoading && <Loader className="w-4 h-4" />}
                  {!createEpochLoading && (
                    <Icon icon="plus" className="w-4 h-4 text-white" />
                  )}
                  <span className="font-mono text-sm">Create epoch</span>
                </Button>
              </Toolbar>
              <div className="px-3 pb-3">
                <Fields
                  data={{ next_epoch: nextEpoch }}
                  schema={{
                    fields: [
                      {
                        key: "next_epoch",
                        label: "Next Epoch",
                        type: "number",
                      },
                    ],
                  }}
                />
              </div>
            </>
          )}
        </View>
      </div>
      <CreateEpochModal />
    </>
  );
}
