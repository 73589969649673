import { RequestOptions } from "../types/index";

const defaultHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export async function get(
  url: string,
  requestOptions: RequestOptions = { headers: defaultHeaders }
) {
  const res = await fetch(url, {
    method: "GET",
    headers: { ...requestOptions.headers, ...defaultHeaders },
  });

  const payload = await res.json();

  if (payload.error) {
    throw new Error(payload.error);
  }

  return payload;
}

export async function post(
  url: string,
  data: any,
  requestOptions: RequestOptions = { headers: defaultHeaders }
) {
  const res = await fetch(url, {
    method: "POST",
    headers: { ...requestOptions.headers, ...defaultHeaders },
    body: JSON.stringify(data),
  });

  const payload = await res.json();

  if (payload.error) {
    throw new Error(payload.error);
  }

  return payload;
}
