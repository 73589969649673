import * as React from "react";
import clsx from "clsx";

export default function Chip({
  className,
  children,
}: {
  className?: string;
  children?: React.ReactNode;
}) {
  return (
    <div
      className={clsx(
        className,
        "flex items-center justify-center rounded px-2 h-6 text-white text-xs font-semibold uppercase tracking-wide"
      )}
    >
      {children}
    </div>
  );
}
