import * as React from "react";
import { SellerInvoice } from "../../types";

export const EARNINGS_HEADINGS: { [key: string]: string } = {
  hashrate_effective_thps: "Hashrate Effective (TH/s)",
  fpps_price_avg_satspthpspd: "FPPS Price (sats/TH/s/pd)",
  fpps_payout_sats: "FPPS Payout (sats)",
  rigly_earnings_sats: "Rigly Earnings (sats)",
};

export default function InvoiceEarnings({
  invoice,
}: {
  invoice: SellerInvoice;
}) {
  const { earnings } = invoice.report;

  return (
    <div className="bg-zinc-800 rounded p-2 w-full">
      <table className="text-white w-full">
        <thead>
          <tr>
            <td>Date</td>
            {Object.keys(earnings).map((key, i) => (
              <td key={i}>{EARNINGS_HEADINGS[key]}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.keys(earnings.hashrate_effective_thps).map((date, i) => (
            <tr key={i}>
              <td>{date}</td>
              <td>{earnings.hashrate_effective_thps[date]}</td>
              <td>{earnings.fpps_price_avg_satspthpspd[date]}</td>
              <td>{earnings.fpps_payout_sats[date]}</td>
              <td>{earnings.rigly_earnings_sats[date]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
