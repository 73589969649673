import { Environment } from "../types";

const BASE_API_URLS = {
  prod: process.env.REACT_APP_RIGLY_API_ENDPOINT_PROD,
  qa: process.env.REACT_APP_RIGLY_API_ENDPOINT_QA,
};


export const BASE_PROXY_API_URLS = {
  prod: process.env.REACT_APP_RIGLY_PROXY_API_ENDPOINT_PROD || "https://proxy.rigly.io",
  qa: process.env.REACT_APP_RIGLY_PROXY_API_ENDPOINT_QA || "https://qa.proxy.rigly.io",
  // qa: "http://localhost:8003",
}

export const PROXY_WS_URLS = {
  prod: process.env.REACT_APP_RIGLY_PROXY_API_ENDPOINT_WS_PROD || "wss://proxy.rigly.io/ws",
  qa: process.env.REACT_APP_RIGLY_PROXY_API_ENDPOINT_WS_QA || "wss://qa.proxy.rigly.io/ws",
  // qa: "ws://localhost:8003/ws",
};

const BASE_FE_URLS = {
  prod: process.env.REACT_APP_RIGLY_FRONTEND_ENDPOINT_PROD,
  qa: process.env.REACT_APP_RIGLY_FRONTEND_ENDPOINT_QA,
};

const BASE_ADMIN_URLS = {
  prod: process.env.REACT_APP_RIGLY_ADMIN_ENDPOINT_PROD,
  qa: process.env.REACT_APP_RIGLY_ADMIN_ENDPOINT_QA,
};

const SELLER_PORTAL_URL = "https://portal.rigly.io";

export function proxyUrl(env: Environment, path?: string): string {
  if (!path) {
    return `${BASE_PROXY_API_URLS[env]}`;
  }
  const p = path.startsWith("/") ? path : `/${path}`;
  return `${BASE_PROXY_API_URLS[env]}${p}`;
}



export function apiUrl(env: Environment, path?: string): string {
  if (!path) {
    return BASE_API_URLS[env] ?? "";
  }
  const p = path.startsWith("/") ? path : `/${path}`;
  return `${BASE_API_URLS[env]}${p}`;
}

export function feUrl(env: Environment, path?: string): string {
  if (!path) {
    return BASE_FE_URLS[env] ?? "";
  }
  const p = path.startsWith("/") ? path : `/${path}`;
  return `${BASE_FE_URLS[env]}${p}`;
}
export function sellerPortalUrl(path?: string): string {
  if (!path) {
    return SELLER_PORTAL_URL;
  }
  const p = path.startsWith("/") ? path : `/${path}`;
  return `${SELLER_PORTAL_URL}${p}`;
}

export function adminUrl(env: Environment, path?: string): string {
  if (!path) {
    return BASE_ADMIN_URLS[env] ?? "";
  }
  const p = path.startsWith("/") ? path : `/${path}`;
  return `${BASE_ADMIN_URLS[env]}${p}`;
}
