/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import * as React from "react";

interface ModalState {
  active: boolean;
  meta?: any;
}

interface ModalContextType {
  modals: { [key: string]: ModalState };
  showModal: (id: string, meta?: any) => void;
  hideModal: (id: string, meta?: any) => void;
}

export const ModalContext = React.createContext<ModalContextType>({
  modals: {},
  showModal: (_id: string, _meta?: any) => {},
  hideModal: (_id: string, _meta?: any) => {},
});

export const useModalContext = () => React.useContext(ModalContext);

export function ModalContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [modals, setModals] = React.useState<{ [key: string]: ModalState }>({
    "are-you-sure": { active: false, meta: undefined },
    "create-epoch": { active: false, meta: undefined },
    "create-auction": { active: false, meta: undefined },
    "create-invoice": { active: false, meta: undefined },
    "switch-hashrate-delivery": { active: false, meta: undefined },
    "select-order": { active: false, meta: undefined },
    "grant-refund": { active: false, meta: undefined },
  });

  const showModal = (id: string, meta?: any) => {
    setModals({ ...modals, [id]: { active: true, meta } });
  };

  const hideModal = (id: string) => {
    setModals({ ...modals, [id]: { active: false, meta: undefined } });
  };

  return (
    <ModalContext.Provider value={{ modals, showModal, hideModal }}>
      {children}
    </ModalContext.Provider>
  );
}
