import clsx from "clsx";
import * as React from "react";

import Chip from "./core/Chip";
import { PaymentStatus } from "../types";

export default function PaymentStatusChip({ value }: { value: PaymentStatus }) {
  return (
    <Chip
      className={clsx("", {
        "bg-zinc-500": value === PaymentStatus.Unpaid,
        "bg-red-500":
          [PaymentStatus.Expired, PaymentStatus.Underpaid].indexOf(value) !==
          -1,
        "bg-blue-500": [PaymentStatus.Processing].indexOf(value) !== -1,
        "bg-orange-500": [PaymentStatus.Refunded].indexOf(value) !== -1,
        "bg-green-500": [PaymentStatus.Paid].indexOf(value) !== -1,
      })}
    >
      {value}
    </Chip>
  );
}
