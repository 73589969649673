/* eslint-disable @typescript-eslint/no-empty-function */
import * as React from "react";
import { Account, AuthContextType, Environment } from "../types";
import { get } from "../utils/fetch";
import { apiUrl } from "../utils/url";

export const AuthContext = React.createContext<AuthContextType>({
  account: undefined,
  setAccount: (_: Account) => {},
  token: undefined,
  setToken: (_: string) => {},
  loading: true,
  logout: () => {},
});

export const useAuthContext = () => React.useContext(AuthContext);

export function AuthContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [token, setToken] = React.useState<string | undefined>(undefined);
  const [account, setAccount] = React.useState<Account | undefined>(undefined);

  const authorize = async () => {
    try {
      setLoading(true);

      if (process.env.REACT_APP_ENV === "local") {
        setToken("aKxgH93r87tmGjBzNsyZdE");
        return;
      }

      const foundToken = token
        ? token
        : window.localStorage.getItem("rigly_admin_token");

      if (!foundToken) {
        return;
      }

      const res = await get(apiUrl(Environment.PROD, "/api/account"), {
        headers: {
          Authorization: `Bearer ${foundToken}`,
        },
      });

      if (!res.account.is_staff) {
        throw Error("Unauthorized, you don't have the proper credentials");
      }

      setToken(foundToken);
      setAccount(res.account);
    } catch (ex) {
      console.error(ex);
      logout();
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    setAccount(undefined);
    setToken(undefined);
    window.localStorage.removeItem("rigly_admin_token");
  };

  React.useEffect(() => {
    authorize();
  }, [window.location.href]);

  return (
    <AuthContext.Provider
      value={{ account, setAccount, token, setToken, loading, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
}
