import * as React from "react";
import { useModalContext } from "../../context/ModalContext";
import Button from "../core/Button";
import Input from "../core/Input";
import Modal from "../core/Modal";

export default function CreateEpochModal() {
  const { modals, hideModal } = useModalContext();
  const { active, meta } = modals["create-epoch"];
  const [epochNumber, setEpochNumber] = React.useState<string>("");

  const handleCancel = () => {
    hideModal("create-epoch");
  };

  const handleSave = () => {
    meta.onSave(Number(epochNumber));
  };

  return (
    <Modal active={active} onClose={handleCancel}>
      <Modal.Header>
        <Modal.Title>
          {meta && meta.title ? meta.title : "Create epoch"}
        </Modal.Title>
        <Modal.Close />
      </Modal.Header>
      <Modal.Body className="p-2">
        <div className="text-white text-sm leading-7">
          {meta && meta.content ? meta.content : ""}
        </div>
        <Input
          id="epoch_number"
          type="number"
          placeholder="Epoch number"
          onChange={setEpochNumber}
          className="w-full"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="h-8 rounded flex items-center justify-start gap-2 uppercase tracking-wide px-2"
          color="gray"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          className="h-8 rounded flex items-center justify-start gap-2 uppercase tracking-wide px-2"
          color="blue"
          onClick={handleSave}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
