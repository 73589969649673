export default function omit(obj: any, keys: any = []) {
  const result: any = {};

  Object.entries(obj).forEach(([key, value]) => {
    if (keys.indexOf(key) === -1) {
      result[key] = value;
    }
  });

  return result;
}
