import clsx from "clsx";
import * as React from "react";
import Button from "./Button";
import Icon from "./Icon";

function Modal({
  className = "w-full md:w-2/4 lg:w-[30vw]",
  children,
  active,
  onClose,
}: {
  className?: string;
  children: any[];
  active: boolean;
  onClose: () => void;
}) {
  if (!active) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center px-4 md:px-0 z-10">
      <div
        className={clsx(
          "bg-zinc-800 rounded border border-zinc-700 flex flex-col",
          className
        )}
      >
        {React.Children.map(children, (Child: React.ReactElement) => {
          if (!Child) {
            return null;
          }
          return React.cloneElement(Child, {
            ctx: { close: onClose },
          });
        })}
      </div>
    </div>
  );
}

Modal.Header = function ({
  className,
  children,
  ctx,
}: {
  className?: string;
  children: any[];
  ctx?: any;
}) {
  return (
    <div
      className={clsx(
        "h-12 border-b border-zinc-700 flex items-center justify-between px-2",
        className
      )}
    >
      {React.Children.map(children, (Child: React.ReactElement) => {
        if (!Child) {
          return;
        }
        return React.cloneElement(Child, {
          ctx,
        });
      })}
    </div>
  );
};

Modal.Close = function ({ className, ctx }: { className?: string; ctx?: any }) {
  return (
    <Button
      color="gray"
      onClick={() => {
        if (ctx.close) {
          ctx.close();
        }
      }}
      className={clsx(
        "w-6 h-6 flex items-center justify-center rounded",
        className
      )}
    >
      <Icon icon="times" className="text-white w-4 h-4" />
    </Button>
  );
};

Modal.Title = function ({
  className,
  children,
}: {
  className?: string;
  children?: any;
}) {
  return (
    <span className={clsx("text-white font-bold text-sm", className)}>
      {children}
    </span>
  );
};

Modal.Body = function ({
  className,
  children,
}: {
  className?: string;
  children?: any;
}) {
  return <div className={clsx("", className)}>{children}</div>;
};

Modal.Footer = function ({
  className,
  children,
}: {
  className?: string;
  children?: any;
}) {
  return (
    <div
      className={clsx(
        "border-t border-zinc-700 px-2 h-12 flex justify-between gap-2 items-center",
        className
      )}
    >
      {children}
    </div>
  );
};

export default Modal;
