import * as React from "react";
import { Refund, RefundStatus } from "../../types";
import List from "../core/List";
import { Link, useNavigate } from "react-router-dom";
import clsx from "clsx";

export default function RefundList({
  loading,
  refunds,
}: {
  loading: boolean;
  refunds: Refund[];
}) {
  const navigate = useNavigate();
  return (
    <List
      data={refunds}
      loading={loading}
      onClick={(r: Refund) => navigate(`/refunds/${r.id}`)}
      schema={[
        { label: "ID", key: "id", type: "number", align: "left" },
        {
          label: "Status",
          key: (r: Refund) => (
            <span
              className={clsx("text-sm font-semibold", {
                "text-orange-400": r.status === RefundStatus.Pending,
                "text-green-400": r.status === RefundStatus.Granted,
                "text-red-400": r.status === RefundStatus.Denied,
              })}
            >
              {r.status.toUpperCase()}
            </span>
          ),
          type: "custom",
        },
        {
          label: "Account",
          key: (r: Refund) => (
            <Link
              to={`/accounts/${r.account.id}`}
              className="text-white underline hover:text-blue-500 text-sm"
            >
              {r.account.email} ({r.account.id})
            </Link>
          ),
          type: "custom",
        },
        {
          label: "Order",
          key: (r: Refund) => (
            <Link
              to={`/orders/${r.order.id}`}
              className="text-white underline hover:text-blue-500 text-sm"
            >
              Order {r.order.id}
            </Link>
          ),
          type: "custom",
        },
        {
          label: "Assigned to",
          key: (r: Refund) =>
            r.assignee ? (
              <Link
                to={`/accounts/${r.assignee.id}`}
                className="text-white underline hover:text-blue-500 text-sm"
              >
                {r.assignee.email} ({r.assignee.id})
              </Link>
            ) : (
              <span className="text-white/40 text-sm">Unassigned</span>
            ),
          type: "custom",
        },
        {
          label: "Created at",
          key: "created_at",
          type: "datetime",
        },
      ]}
    />
  );
}
