import * as React from "react";

import { apiUrl } from "../utils/url";
import { get } from "../utils/fetch";
import toast from "react-hot-toast";
import { useAuthContext } from "../context/AuthContext";
import { useEnvironmentContext } from "../context/EnvironmentContext";

import { ProxyLegacy, Order, SortingDirection } from "../types/index";
import ProgressBar from "../components/ProgressBar";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import View from "../components/View";
import clsx from "clsx";
import Icon from "../components/core/Icon";
import List from "../components/core/List";
import { format } from "date-fns";

export default function ProxiesStatus() {
  const { token } = useAuthContext();
  const { environment } = useEnvironmentContext();

  const [loading, setLoading] = React.useState<boolean>(true);
  const [sortingKey, setSortingKey] = React.useState<string | undefined>(
    undefined
  );
  const [sortingDirection, setSortingDirection] =
    React.useState<SortingDirection>(SortingDirection.None);
  const [proxies, setProxies] = React.useState<any[]>([]);

  const loadProxies = async (
    sortKey?: string,
    sortDirection?: SortingDirection
  ) => {
    if (process.env.REACT_APP_ENV !== "local" && !token) {
      return;
    }

    try {
      setLoading(true);
      const url = `/api/status/proxies${
        sortKey ? `?sort_by=${sortKey}&order=${sortDirection}` : ""
      }`;
      const res = await get(apiUrl(environment, url), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setProxies(res);
    } catch (ex: any) {
      toast.error(ex.message, { position: "bottom-left" });
    } finally {
      setLoading(false);
    }
  };

  const handleSort = (key: string | undefined, direction: SortingDirection) => {
    setSortingKey(key);
    setSortingDirection(direction);
  };

  React.useEffect(() => {
    loadProxies(sortingKey, sortingDirection);
  }, [sortingKey, sortingDirection]);

  return (
    <>
      <Topbar />
      <div className="flex w-full h-screen-excluding-topbar">
        <Sidebar />
        <View className="p-4">
          <List
            sortKey={sortingKey}
            sortDirection={sortingDirection}
            onSort={handleSort}
            tdClassName="py-1"
            spanClass="!text-xs"
            schema={[
              {
                key: (item: { proxy: ProxyLegacy; orders: Order[] }) => (
                  <div
                    className={clsx("w-2 h-2 rounded-full border mx-auto", {
                      "bg-green-500 border-green-300": item.orders.length === 0,
                      "bg-red-500 border-red-300": item.orders.length > 0,
                    })}
                  />
                ),
                label: "Status",
                type: "custom",
              },
              {
                key: "proxy.name",
                label: "Proxy name",
                type: "string",
                align: "left",
              },
              {
                key: "proxy.stratums_id",
                label: "Stratu.ms ID",
                type: "string",
                align: "left",
              },
              {
                key: (item: { proxy: ProxyLegacy; orders: Order[] }) => (
                  <div className="flex items-center">
                    <div className="flex items-center justify- start gap-2 bg-zinc-700 rounded px-2 py-1 border border-zinc-600 relative min-w-[200px] w-[200px]">
                      <div className="absolute right-2 -bottom-[2px] pointer-events-none">
                        <Icon
                          icon="warehouse"
                          className="text-white/20 w-8 h-8"
                        />
                      </div>
                      <div className="flex flex-col">
                        <span className="text-[10px] text-white font-bold">
                          Worker {item.proxy.worker.name} (
                          {item.proxy.worker.id})
                        </span>
                        <span className="text-[10px] text-white">-</span>
                      </div>
                    </div>

                    <ProgressBar
                      className="h-2 w-[40px]"
                      value={100}
                      color={
                        item.orders.length === 0
                          ? "white"
                          : item.orders.length === 1
                          ? "green"
                          : "red"
                      }
                      animate={item.orders.length <= 1}
                    />

                    <div className="flex items-center justify- start gap-2 bg-zinc-700 rounded px-2 py-1 border border-zinc-600 relative min-w-[200px] w-[200px]">
                      <div className="absolute right-2 -bottom-[2px] pointer-events-none">
                        <Icon icon="user" className="text-white/20 w-8 h-8" />
                      </div>
                      <div className="flex flex-col">
                        <span className="text-[10px] text-white font-bold">
                          {item.proxy.worker.pool_user.username}
                        </span>
                        <span className="text-[10px] text-white">
                          {item.proxy.worker.pool_user.pool}
                        </span>
                      </div>
                    </div>
                  </div>
                ),
                label: "Connection",
                type: "custom",
              },
              {
                key: (
                  item: { proxy: ProxyLegacy; orders: Order[]; completion: number },
                  i: number
                ) => (
                  <div className="flex w-full flex-col gap-1">
                    {item.orders.length === 0 && (
                      <span className="text-xs text-white">-</span>
                    )}
                    {item.orders.length === 1 &&
                      item.orders.map((order: Order, j: number) => (
                        <div
                          key={i + j}
                          className="flex items-center justify- start gap-2 bg-zinc-700 rounded px-2 py-1 border border-zinc-600 relative w-[200px]"
                        >
                          <div className="flex flex-col">
                            <span className="text-[10px] text-white font-bold">
                              Order {order.id}
                            </span>
                            <span className="text-[10px] text-white">
                              {order.account.email}
                            </span>
                            <span className="text-[10px] text-white">
                              Completion: {item.completion} %
                            </span>
                          </div>
                        </div>
                      ))}
                    {item.orders.length > 1 && (
                      <div className="flex flex-col">
                        <span className="text-xs text-red-500">
                          Processing multiple orders
                        </span>
                        <span className="text-xs text-red-500">
                          {item.orders.map((order: Order) => order.id).join(", ")}
                        </span>
                      </div>
                    )}
                  </div>
                ),
                label: "Order",
                type: "custom",
              },
              {
                key: (item: { proxy: ProxyLegacy }) => {
                  if (!item.proxy.worker_history) {
                    return <span className="text-white text-xs">-</span>;
                  }
                  return (
                    <span className="text-white text-xs">
                      {format(
                        item.proxy.worker_history.assigned_at,
                        "yyyy-MM-dd HH:mm:ss"
                      )}
                    </span>
                  );
                },
                label: "Last assigned at",
                type: "custom",
              },
            ]}
            data={proxies}
            loading={loading}
          />
        </View>
      </div>
    </>
  );
}

