import { SharesStats } from "../types";

export function calculateEfficiency(shares?: SharesStats) {
  if (!shares) {
    return Number(0).toFixed(2);
  }

  const totalShares = shares.accepted + shares.rejected;
  if (totalShares === 0) {
    return Number(0).toFixed(2);
  }

  return ((shares.accepted / totalShares) * 100).toFixed(2);
}

export function calculateInefficiency(shares?: SharesStats) {
  if (!shares) {
    return Number(100).toFixed(2);
  }

  const totalShares = shares.accepted + shares.rejected;
  if (totalShares === 0) {
    return Number(100).toFixed(2);
  }

  return ((shares.rejected / totalShares) * 100).toFixed(2);
}
