import * as React from "react";

export default function Code({ children }: { children: React.ReactNode }) {
  return (
    <div className="bg-zinc-800 rounded p-2 w-full">
      <pre className="text-white p-2 text-sm font-mono bg-black/10 rounded">
        {children}
      </pre>
    </div>
  );
}
