import { format } from "date-fns";
import * as React from "react";
import { Field } from "../types";
import { get } from "lodash";
import clsx from "clsx";
import Icon from "./core/Icon";
import Checkbox from "./core/Checkbox";

function maxLength(value: any, length = 8) {
  if (!value || value === "") {
    return <span className="inherit">-</span>;
  }
  return (
    <>
      <span className="inherit">{value.toString().slice(0, length)}</span>
      <span className="font-sans">...</span>
    </>
  );
}

export default function Fields({
  className,
  data,
  schema,
}: {
  className?: string;
  data: any;
  schema: any;
}) {
  return (
    <table className={clsx("text-white w-full", className)}>
      <thead>
        <tr>
          <th className="text-left h-8 bg-white bg-opacity-10 first-of-type:rounded-tl-lg last-of-type:rounded-tr-lg px-4 border-r border-zinc-900 last-of-type:border-none">
            <div className="flex items-center justify-between gap-2">
              <span className="text-white text-xs">Key</span>
              <div className="flex flex-col">
                <Icon
                  icon="chevronUp"
                  className="w-[10px] h-[10px] text-zinc-500"
                />
                <Icon
                  icon="chevronUp"
                  className="w-[10px] h-[10px] text-zinc-500 rotate-180 -mt-[2px]"
                />
              </div>
            </div>
          </th>
          <th className="text-left h-8 bg-white bg-opacity-10 first-of-type:rounded-tl-lg last-of-type:rounded-tr-lg px-4 border-r border-zinc-900 last-of-type:border-none">
            <div className="flex items-center justify-between gap-2">
              <span className="text-white text-xs">Value</span>
              <div className="flex flex-col">
                <Icon
                  icon="chevronUp"
                  className="w-[10px] h-[10px] text-zinc-500"
                />
                <Icon
                  icon="chevronUp"
                  className="w-[10px] h-[10px] text-zinc-500 rotate-180 -mt-[2px]"
                />
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        {schema.fields
          .filter((field: Field) => {
            return field.visible ? field.visible(data) : true;
          })
          .map((field: Field, i: number) => {
            const value =
              typeof field.key === "function"
                ? field.key(data)
                : get(data, field.key);

            return (
              <tr key={i} className="bg-opacity-10 odd:bg-opacity-5 bg-white">
                <td className="px-4 h-10 text-sm whitespace-nowrap">
                  {field.label}
                </td>
                {field.type === "percentage" && (
                  <td className="px-4 h-10 text-sm whitespace-nowrap font-mono">
                    {value}
                    <i className="text-zinc-400 not-italic">%</i>
                  </td>
                )}
                {field.type === "sats" && (
                  <td className="px-4 h-10 text-sm whitespace-nowrap font-mono">
                    {value}
                    <i className="text-zinc-400 not-italic">ṩ</i>
                  </td>
                )}
                {field.type === "thps" && (
                  <td className="px-4 h-10 text-sm whitespace-nowrap font-mono">
                    {value}
                    <i className="text-zinc-400 not-italic">TH/s</i>
                  </td>
                )}
                {field.type === "days" && (
                  <td className="px-4 h-10 text-sm whitespace-nowrap">
                    {value} days
                  </td>
                )}
                {field.type === "address" && (
                  <td className="px-4 h-10 text-sm whitespace-nowrap font-mono">
                    {maxLength(value, 12)}
                  </td>
                )}
                {field.type === "string" && (
                  <td className="px-4 h-10 text-sm whitespace-nowrap">
                    {value}
                  </td>
                )}
                {field.type === "url" && (
                  <td className="px-4 h-10 text-sm whitespace-nowrap">
                    <a href={value} target="_blank">
                      {maxLength(value, 28)}
                    </a>
                  </td>
                )}
                {field.type === "number" && (
                  <td className="px-4 h-10 text-sm whitespace-nowrap font-mono">
                    {value}
                  </td>
                )}
                {field.type === "component" && (
                  <td className="px-4 h-10 text-sm whitespace-nowrap">
                    {field.meta(data, field)}
                  </td>
                )}
                {field.type === "boolean" && (
                  <td className="px-4 h-10 text-sm whitespace-nowrap">
                    <Checkbox
                      id={field.key.toString()}
                      value={value}
                      disabled
                      className=""
                    />
                  </td>
                )}
                {field.type === "datetime" && (
                  <>
                    {value && (
                      <td className="px-4 h-10 text-sm whitespace-nowrap">
                        {format(
                          new Date(value),
                          field.meta || "MMMM d, yyyy HH:mm:ss"
                        )}
                      </td>
                    )}
                    {!value && (
                      <td className="px-4 h-10 text-sm whitespace-nowrap">-</td>
                    )}
                  </>
                )}
              </tr>
            );
          })}
      </tbody>
    </table>
  );
}
