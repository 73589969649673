import { useEffect, useState } from "react";
import { Proxy } from "../types";
import * as api from "../api/proxies";
import { useAuthContext } from "../context/AuthContext";
import { useEnvironmentContext } from "../context/EnvironmentContext";
import toast from "react-hot-toast";
import Plotly from "react-plotly.js";
import clsx from "clsx";

type HashrateEntry = {
  timestamp: string;
  stratums_id: number;
  hashrate: number;
};

type PlotlyDataset = {
  x: string[];
  y: number[];
  marker: any;
};

export default function HashrateChart({
  proxy,
  className,
  renderEmpty,
  layout = {},
}: {
  proxy: Proxy;
  className?: string;
  renderEmpty: any;
  layout?: any;
}) {
  const { token } = useAuthContext();
  const { environment } = useEnvironmentContext();

  const [hashrateData, setHashrateData] = useState<PlotlyDataset[]>([]);

  const load = async () => {
    if (!token || !proxy) {
      return;
    }

    try {
      // const res = await api.getHashrateChart(
      //   proxy.id.toString(),
      //   environment,
      //   token
      // );
      // if (res.error) {
      //   return;
      // }
      // const plotlyData = [
      //   {
      //     x: res.map(({ timestamp }: HashrateEntry) => timestamp),
      //     y: res.map(({ hashrate }: HashrateEntry) => hashrate / 1000000000000),
      //     marker: {
      //       color: "#60A5FA",
      //     },
      //     line: {
      //       color: "#60A5FA",
      //       // shape: "spline",
      //       width: 3,
      //     },
      //     showlegend: false,
      //   },
      // ];
      // setHashrateData(plotlyData);
    } catch (ex: any) {
      toast.error(ex.message, { position: "bottom-right" });
    }
  };

  useEffect(() => {
    load();
  }, [proxy, environment, token]);

  let xaxis = {
    gridcolor: "rgba(63,63,69,1)",
    tickfont: {
      color: "white",
    },
  };
  if (layout.yaxis) {
    xaxis = { ...xaxis, ...layout.xaxis };
  }

  let yaxis = {
    gridcolor: "rgba(63,63,69,1)",
    ticksuffix: " TH/sec   ",
    tickfont: {
      color: "white",
    },
  };
  if (layout.yaxis) {
    yaxis = { ...yaxis, ...layout.yaxis };
  }

  let margin = { t: 16, l: 96, r: 0, b: 48 };
  if (layout.margin) {
    margin = { ...margin, ...layout.margin };
  }

  if (hashrateData.length === 0) {
    return renderEmpty();
  }

  return (
    <Plotly
      className={clsx("w-full h-full", className)}
      data={hashrateData}
      layout={{
        margin,
        paper_bgcolor: "rgba(0,0,0,0)",
        plot_bgcolor: "rgba(0,0,0,0)",
        xaxis,
        yaxis,
        showlegend: false,
      }}
      config={{
        displayModeBar: false,
      }}
      useResizeHandler={true}
      style={{ width: "100%", height: "100%" }}
    />
  );
}
