import * as React from "react";
import { Toaster } from "react-hot-toast";
import { Navigate, useLocation } from "react-router-dom";

import { useAuthContext } from "../context/AuthContext";
import Loader from "./core/Loader";

const PROTECTED_ROUTES =
  process.env.REACT_APP_ENV !== "local"
    ? [
        "/accounts",
        "/auctions",
        "/orders/proxies",
        "/orders",
        "/payments",
        "/invoices",
        "/proxies",
        "/mining",
      ]
    : [];

export default function Layout({ children }: { children: React.ReactNode }) {
  const { account, loading } = useAuthContext();
  const location = useLocation();

  if (loading) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <Loader className="w-8 h-8 text-white" />
      </div>
    );
  }

  const isProtectedRoute =
    PROTECTED_ROUTES.findIndex((route: string) => {
      return location.pathname !== "/" && route.startsWith(location.pathname);
    }) !== -1;

  if (isProtectedRoute && !loading && !account) {
    return <Navigate to="/login" replace />;
  }

  return (
    <>
      <Toaster />
      {children}
    </>
  );
}
