import * as React from "react";
import {
  Account,
  AccountType,
  BuyerInvoice,
  MiningStatus,
  SellerInvoice,
  SellerInvoiceType,
} from "../../types";
import Fields from "../Fields";
import Chip from "../core/Chip";
import clsx from "clsx";
import { Link } from "react-router-dom";

export default function InvoiceFields({
  account,
  invoice,
}: {
  account: Account | undefined;
  invoice: BuyerInvoice | SellerInvoice;
}) {
  if (!account) {
    return null;
  }

  const isSeller = account.type === AccountType.Seller;

  return isSeller ? (
    <Fields
      className="col-span-3"
      data={invoice}
      schema={{
        fields: [
          { key: "id", label: "ID", type: "number" },
          {
            label: "Invoice Type",
            type: "component",
            meta: (invoice: SellerInvoice) => {
              return (
                <span className="text-white text-sm">
                  {invoice.report.invoice_type === SellerInvoiceType.Epoch
                    ? "Epoch-based"
                    : "Custom timespan"}
                </span>
              );
            },
          },
          {
            label: "Meta",
            type: "component",
            meta: (invoice: SellerInvoice) => {
              return (
                <span className="text-white text-sm">
                  {invoice.report.invoice_type === SellerInvoiceType.Epoch
                    ? `Epoch ${invoice.report.meta.epoch_number}`
                    : `${invoice.report.meta.hashrate_start} - ${invoice.report.meta.hashrate_end}`}
                </span>
              );
            },
          },
          {
            label: "Account",
            type: "component",
            meta: (invoice: SellerInvoice) => {
              return (
                <Link
                  to={`/accounts/${invoice.report.account.id}`}
                  className="text-white text-sm underline"
                >
                  {invoice.report.account.email}
                </Link>
              );
            },
          },
          {
            key: "report.downtime",
            label: "Downtime",
            type: "days",
          },
          {
            key: "report.uptime",
            label: "Uptime",
            type: "days",
          },
          {
            key: "report.average_hashrate",
            label: "Average hashrate",
            type: "number",
          },
          {
            key: "report.hashrate_days",
            label: "Hashrate days",
            type: "days",
          },
          {
            key: "report.created_at",
            label: "Created at",
            type: "datetime",
          },
        ],
      }}
    />
  ) : (
    <>
      <Fields
        className="col-span-1"
        data={invoice}
        schema={{
          fields: [
            { key: "id", label: "ID", type: "number" },
            {
              label: "Mining Status",
              type: "component",
              meta: (invoice: BuyerInvoice) => {
                return (
                  <Chip
                    className={clsx("", {
                      "bg-green-500":
                        invoice.report.mining_status === MiningStatus.Complete,
                      "bg-blue-500":
                        invoice.report.mining_status ===
                        MiningStatus.InProgress,
                      "bg-zinc-500":
                        invoice.report.mining_status === MiningStatus.Pending,
                      "bg-red-500":
                        invoice.report.mining_status ===
                        MiningStatus.Terminated,
                    })}
                  >
                    {invoice.report.mining_status}
                  </Chip>
                );
              },
            },
            {
              label: "Account",
              type: "component",
              meta: (invoice: BuyerInvoice) => {
                return (
                  <Link
                    to={`/accounts/${invoice.report.order.account.id}`}
                    className="text-white text-sm underline"
                  >
                    {invoice.report.order.account.email}
                  </Link>
                );
              },
            },
            {
              key: "report.auction_title",
              label: "Auction title",
              type: "string",
            },
            {
              key: "report.auction_url",
              label: "Auction url",
              type: "url",
            },
            {
              key: "report.downtime_days",
              label: "Downtime",
              type: "days",
            },
            {
              key: "report.uptime_days",
              label: "Uptime",
              type: "days",
            },
            {
              key: "report.duration",
              label: "Duration",
              type: "string",
            },
            {
              key: "report.epoch_number",
              label: "Epoch",
              type: "number",
            },
            {
              key: "report.expected_time_of_completion",
              label: "Expected time of completion",
              type: "datetime",
              meta: "MMMM d, yyyy HH:mm:ss",
            },
            {
              key: "report.percent_complete",
              label: "Percent complete",
              type: "percentage",
            },
            {
              key: "report.remaining_status",
              label: "Remaining status",
              type: "string",
            },
            {
              key: "report.time_of_completion",
              label: "Time of completion",
              type: "datetime",
              meta: "MMMM d, yyyy HH:mm:ss",
            },
          ],
        }}
      />
      <Fields
        className="col-span-1"
        data={invoice}
        schema={{
          fields: [
            {
              key: "report.stratum_address",
              label: "Stratu.ms address",
              type: "url",
            },
            {
              key: "report.hashrate_start",
              label: "Hashrate start",
              type: "datetime",
              meta: "MMMM d, yyyy HH:mm:ss",
            },
            {
              key: "report.hashrate_end",
              label: "Hashrate end",
              type: "datetime",
              meta: "MMMM d, yyyy HH:mm:ss",
            },
            {
              key: "report.hashrate_days",
              label: "Hashrate days",
              type: "number",
            },
            {
              key: "report.anticipated_hashrate",
              label: "Anticipated hashrate",
              type: "thps",
            },
            {
              key: "report.average_hashrate_thps",
              label: "Average hashrate",
              type: "thps",
            },
            {
              key: "report.hashrate_thps",
              label: "Hashrate",
              type: "thps",
            },
            {
              key: "report.pool_account",
              label: "Pool account",
              type: "string",
            },
            {
              key: "report.pool_worker_name",
              label: "Pool worker name",
              type: "string",
            },
          ],
        }}
      />
      <Fields
        className="col-span-1"
        data={invoice}
        schema={{
          fields: [
            {
              key: "report.payment_address",
              label: "Payment address",
              type: "address",
            },
            {
              key: "report.auction_fee_sats",
              label: "Auction fee",
              type: "sats",
            },
            {
              key: "report.balance_sats",
              label: "Balance",
              type: "sats",
            },
            {
              key: "report.bid_price_sats",
              label: "Bid amount",
              type: "sats",
            },
            {
              key: "report.deposit_sats",
              label: "Mining Deposit",
              type: "sats",
            },
            {
              key: "report.deposit_percent",
              label: "Deposit percentage",
              type: "percentage",
            },
          ],
        }}
      />
    </>
  );
}
