import * as React from "react";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";

import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import View from "../components/View";
import List from "../components/core/List";
import Input from "../components/core/Input";
import Pagination from "../components/core/Pagination";

import { get } from "../utils/fetch";
import { apiUrl } from "../utils/url";
import { PaginationState, ProxyLegacy } from "../types";
import { useEnvironmentContext } from "../context/EnvironmentContext";
import { useAuthContext } from "../context/AuthContext";
import Toolbar from "../components/core/Toolbar";
import toast from "react-hot-toast";

export default function Proxies() {
  const navigate = useNavigate();
  const { token } = useAuthContext();
  const { environment } = useEnvironmentContext();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [proxies, setProxies] = React.useState([]);
  const [demoProxies, setDemoProxies] = React.useState([]);
  const [limit, setLimit] = React.useState<number>(20);
  const [offset, setOffset] = React.useState<number>(0);
  const [total, setTotal] = React.useState<number>(0);

  const loadProxies = async (query?: string) => {
    if (process.env.REACT_APP_ENV !== "local" && !token) {
      return;
    }

    try {
      setLoading(true);
      const search = !query || query === "" ? "" : `search=${query}&`;
      const res = await get(
        apiUrl(
          environment,
          `/api/ops/proxies?${search}limit=${limit}&offset=${offset}`
        ),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setProxies(res.results);
      setTotal(res.count);
    } catch (ex: any) {
      toast.error(ex.message, { position: "bottom-left" });
    } finally {
      setLoading(false);
    }
  };

  const loadDemoProxies = async () => {
    if (process.env.REACT_APP_ENV !== "local" && !token) {
      return;
    }

    try {
      setLoading(true);
      const res = await get(apiUrl(environment, `/api/ops/proxies/demo`), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setDemoProxies(res);
    } catch (ex: any) {
      toast.error(ex.message, { position: "bottom-left" });
    } finally {
      setLoading(false);
    }
  };

  const handlePaginationChange = (state: PaginationState) => {
    setOffset(state.offset);
    setLimit(state.limit);
  };

  React.useEffect(() => {
    loadProxies();
    loadDemoProxies();
  }, [offset, limit, environment, token]);

  const debounceLoadProxies = debounce(loadProxies, 300);

  return (
    <>
      <Topbar />
      <div className="flex w-full h-screen-excluding-topbar">
        <Sidebar />
        <View>
          <Toolbar>
            <Input
              id="search"
              type="text"
              placeholder="Search Proxy by id or name"
              onChange={debounceLoadProxies}
              className="min-w-[240px]"
            />
            <Pagination
              limit={limit}
              offset={offset}
              total={total}
              onChange={handlePaginationChange}
            />
          </Toolbar>

          <h2 className="text-xl text-white font-semibold">Proxies</h2>
          <List
            data={proxies}
            loading={loading}
            onClick={(proxy: ProxyLegacy) =>
              navigate(`/proxies-legacy/${proxy.id}`)
            }
            schema={SCHEMA}
          />

          <h2 className="text-xl text-white font-semibold">Demo proxies</h2>
          <List
            data={demoProxies}
            loading={loading}
            onClick={(proxy: ProxyLegacy) =>
              navigate(`/proxies-legacy/${proxy.id}`)
            }
            schema={[
              ...SCHEMA,
              {
                label: "Status",
                key: (item: ProxyLegacy) => {
                  return item.is_available ? (
                    <span className="text-sm text-green-500">Available</span>
                  ) : (
                    <span className="text-sm text-red-500">Unavailable</span>
                  );
                },
                type: "custom",
              },
            ]}
          />
        </View>
      </div>
    </>
  );
}

const SCHEMA = [
  { label: "ID", key: "id", type: "number", align: "left" },
  { label: "Name", key: "name", type: "string", align: "left" },
  {
    label: "Seller",
    key: (proxy: ProxyLegacy) => (
      <span className="text-white">
        {proxy.seller ? proxy.seller.email : "-"}
      </span>
    ),
    type: "custom",
  },
  {
    label: "Stratum",
    key: "stratum",
    type: "string",
    align: "left",
  },
  {
    label: "Stratums ID",
    key: "stratums_id",
    type: "number",
    align: "left",
  },
  {
    label: "Proxy Type",
    key: "type",
    type: "string",
    align: "left",
  },
  {
    label: "Operation Type",
    key: "operation_type",
    type: "string",
    align: "left",
  },
  {
    label: "Order Type",
    key: "order_type",
    type: "string",
    align: "left",
  },
  {
    label: "Worker Name",
    key: "worker.name",
    type: "string",
    align: "left",
  },
  {
    label: "Pool User",
    key: (item: ProxyLegacy) => {
      return `${item.worker.pool_user.username}@${item.worker.pool_user.pool}`;
    },
    type: "string",
    align: "left",
  },
];
