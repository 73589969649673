import * as React from "react";
import { useModalContext } from "../../context/ModalContext";
import Button from "../core/Button";
import Form from "../core/Form";
import Modal from "../core/Modal";
import toast from "react-hot-toast";
import { apiUrl } from "../../utils/url";
import { get, post } from "../../utils/fetch";
import { useEnvironmentContext } from "../../context/EnvironmentContext";
import { useAuthContext } from "../../context/AuthContext";
import Icon from "../core/Icon";
import Loader from "../core/Loader";
import { Epoch } from "../../types";
import { format } from "date-fns";
// import { utcToZonedTime } from "date-fns-tz";

const DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";

export default function CreateInvoiceModal({
  orderId,
  accountId,
}: {
  orderId?: number;
  accountId?: number;
}) {
  const { environment } = useEnvironmentContext();
  const { token } = useAuthContext();
  const { modals, hideModal } = useModalContext();
  const { active, meta } = modals["create-invoice"];

  const isSellerInvoice = orderId === undefined;

  const [createInvoiceLoading, setCreateInvoiceLoading] =
    React.useState<boolean>(false);
  const [formData, setFormData] = React.useState<any>({});
  const [formSchema, setFormSchema] = React.useState<any>([]);
  const [epochs, setEpochs] = React.useState<Epoch[]>([]);

  const handleFormChange = (data: any, key?: string) => {
    if (key === "hashrate_start" || key === "hashrate_end") {
      data[key] = format(data[key], DATE_FORMAT);
    }

    if (key === "epoch_number" && data.epoch_number) {
      const epoch = epochs.find(
        (epoch: Epoch) => epoch.epoch_number === data.epoch_number
      );

      if (epoch) {
        return setFormData({
          ...formData,
          epoch_number: epoch.epoch_number,
          hashrate_start: format(epoch.start_time, DATE_FORMAT),
          hashrate_end: format(epoch.end_time, DATE_FORMAT),
        });
      }
    }

    if (key === "hashrate_start" || key === "hashrate_end") {
      return setFormData({ ...formData, ...data, epoch_number: null });
    }

    setFormData({ ...formData, ...data });
  };

  const handleCancel = () => {
    hideModal("create-invoice");
  };

  const handleSave = async () => {
    if (process.env.REACT_APP_ENV !== "local" && !token) {
      return;
    }

    try {
      setCreateInvoiceLoading(true);

      const data = { ...formData };
      if (isSellerInvoice) {
        data.seller = accountId;
      }

      if (formData.epoch_number) {
        delete data.hashrate_start;
        delete data.hashrate_end;
      } else {
        delete data.epoch_number;
      }

      console.log(data);
      await post(
        isSellerInvoice
          ? apiUrl(environment, `/api/ops/sellers/invoices/create`)
          : apiUrl(environment, `/api/ops/orders/${orderId}/invoices/create`),
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      meta.onSave();
    } catch (ex: any) {
      toast.error(ex.message, { position: "bottom-left" });
    } finally {
      setCreateInvoiceLoading(false);
    }
  };

  const loadCreateInvoiceSchema = async () => {
    if (process.env.REACT_APP_ENV !== "local" && !token) {
      return;
    }
    if (!isSellerInvoice) {
      return setFormSchema([]);
    }

    const res = await get(
      apiUrl(environment, `/api/ops/sellers/invoices/create/schema`),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setFormSchema(res);
  };

  const loadEpochs = async () => {
    if (process.env.REACT_APP_ENV !== "local" && !token) {
      return;
    }

    const res = await get(apiUrl(environment, `/api/ops/mining/epochs`), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    setEpochs(res);
  };

  React.useEffect(() => {
    loadCreateInvoiceSchema();
    loadEpochs();
  }, [token]);

  return (
    <Modal
      active={active}
      onClose={handleCancel}
      className="w-full md:w-2/4 lg:w-[50vw]"
    >
      <Modal.Header>
        <Modal.Title>
          {meta && meta.title ? meta.title : "Create invoice"}
        </Modal.Title>
        <Modal.Close />
      </Modal.Header>
      <Modal.Body className="p-2">
        <div className="text-white text-sm leading-7">
          {meta && meta.content ? meta.content : ""}
        </div>
        <Form schema={formSchema} data={formData} onChange={handleFormChange} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="h-8 rounded flex items-center justify-start gap-2 uppercase tracking-wide px-2"
          color="gray"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          className="h-8 rounded flex items-center justify-start gap-2 uppercase tracking-wide px-2"
          color="blue"
          onClick={handleSave}
          disabled={createInvoiceLoading}
        >
          {createInvoiceLoading && <Loader className="w-4 h-4" />}
          {!createInvoiceLoading && (
            <Icon icon="plus" className="w-4 h-4 text-white" />
          )}
          <span className="text-xs">Create invoice</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
