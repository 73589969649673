import * as React from "react";
import { useModalContext } from "../../context/ModalContext";
import Button from "../core/Button";
import Modal from "../core/Modal";

export default function AreYouSureModal() {
  const { modals, hideModal } = useModalContext();
  const { active, meta } = modals["are-you-sure"];

  const handleCancel = () => {
    meta.onCancel();
    hideModal("are-you-sure");
  };

  const handleConfirm = () => {
    meta.onConfirm();
  };

  return (
    <Modal active={active} onClose={handleCancel}>
      <Modal.Header>
        <Modal.Title>
          {meta && meta.title ? meta.title : "Are you sure?"}
        </Modal.Title>
        <Modal.Close />
      </Modal.Header>
      <Modal.Body className="p-2">
        <div className="text-white text-sm leading-7">
          {meta && meta.content ? meta.content : ""}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="h-8 rounded flex items-center justify-start gap-2 uppercase tracking-wide px-2"
          color="gray"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          className="h-8 rounded flex items-center justify-start gap-2 uppercase tracking-wide px-2"
          color="blue"
          onClick={handleConfirm}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
