import React from 'react';
import Modal from "../../components/core/Modal";
import Form from "../../components/core/Form";
import Button from "../../components/core/Button";
import { Loader } from "@rigly/core";
import toast from "react-hot-toast";
import { useAuthContext } from "../../context/AuthContext";
import { useEnvironmentContext } from "../../context/EnvironmentContext";
import * as api from "../../api/proxies";
import { Proxy } from "../../types";


export default function CreateOrEditProxyModal({
  mode,
  active,
  onClose,
  onRefresh,
  data,
  proxies,
}: {
  mode: "create" | "edit";
  active: boolean;
  onClose: () => void;
  onRefresh: () => void;
  data?: Proxy;
  proxies: Proxy[];
}) {
  const { token } = useAuthContext();
  const { environment } = useEnvironmentContext();

  const [schema, setSchema] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(true);

  const [formData, setFormData] = React.useState<any>(
    mode === "create" || !data ? {
        name: undefined,
        team: undefined,
        seller: undefined,
        proxy_groups: [],
        proxy_port: undefined,
      } : {
        name: data.name,
        team: data.team,
        seller: data.seller,
        proxy_groups: [...data.proxy_groups], // Ensure a new array is created
        proxy_port: data.port,
      }
  );

  const load = async () => {
    if (!token) {
      return;
    }

    try {
      setLoading(true);
      const res = await api.getCreateProxySchema(environment, token);
      setSchema(res);
    } catch (ex: any) {
      console.log(ex.message);
      toast.error(ex.message, { position: "bottom-right" });
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async (e: any) => {
    e.preventDefault();

    if (!token) {
      return;
    }

    if (mode === "edit") {
      if (!data?.id) {
        toast.error("Proxy ID is missing", { position: "bottom-right" });
        return;
      }

      try {
        setLoading(true);
        await api.editProxy(
          data.id.toString(),
          { ...formData, proxy_port: Number(formData.proxy_port) },
          environment,
          token
        );

        // If the promise resolves successfully, show the success message.
        toast.success("Proxy edited", { position: "bottom-right" });
        onRefresh();
        onClose();
        return; // Prevent further execution to avoid reaching createProxy logic
        } catch (error: unknown) {
          console.log("Error occurred:", error);
          setLoading(false);

          if (error instanceof api.ApiError) {
            // Specific handling for ApiError
            toast.error(error.detail, { position: "bottom-right" });
          } else if (typeof error === "object" && error !== null && "response" in error) {
            const axiosError = error as { response?: { data?: { detail?: string } } };
            let errorMessage = "Failed to edit proxy. Please try again.";

            if (axiosError.response && axiosError.response.data && axiosError.response.data.detail) {
              errorMessage = axiosError.response.data.detail;
            }

            toast.error(errorMessage, { position: "bottom-right" });
          } else if (typeof error === 'string') {
            // Handle case where error might be a simple string
            toast.error(error, { position: "bottom-right" });
          } else {
            // Fallback error message if error type is not known
            toast.error("An unexpected error occurred. Please try again.", { position: "bottom-right" });
          }
        }



      return;
    }

    try {
      // setLoading(true);
      await api.createProxy(
        { ...formData, proxy_port: Number(formData.proxy_port) },
        environment,
        token
      );
      toast.success("Proxy created", { position: "bottom-right" });
    } catch (ex: any) {
      console.log(ex.message);
      toast.error(ex.message, { position: "bottom-right" });
    } finally {
      // setLoading(false);
    }

    onRefresh();
    onClose();
  };



  React.useEffect(() => {
    if (active) {
      load();
    }
  }, [environment, active]);

  const isPortAvailable =
    formData.proxy_port &&
    proxies.findIndex((p: Proxy) => {
      return p.port.toString() === formData.proxy_port.toString();
    }) === -1;

  const canSave = React.useMemo(() => {
    const isFormDataValid = () => {
      // Check if required fields are valid; return true if all required fields are filled correctly
      return formData.name && formData.team && formData.seller && formData.proxy_groups.length > 0;
    };

    const isPortValid = () => {
      if (!formData.proxy_port) return false;
      const index = proxies.findIndex((p: Proxy) => p.port.toString() === formData.proxy_port.toString());
      return mode === "create" ? index === -1 : (index === -1 || data?.port.toString() === formData.proxy_port.toString());
    };

    const hasChanges = () => {

      const originalData = {
        name: data?.name,
        team: data?.team,
        seller: data?.seller,
        proxy_groups: data?.proxy_groups || [],
        proxy_port: data?.port,
      };
      const currentData = {
        name: formData.name,
        team: formData.team,
        seller: formData.seller,
        proxy_groups: formData.proxy_groups || [],
        proxy_port: formData.proxy_port,
      };

      // Define the arraysEqual function accepting any type of array elements.
      const arraysEqual = (a: any[] = [], b: any[] = []): boolean => {
        if (a === b) return true;
        if (a.length !== b.length) return false;

        for (let i = 0; i < a.length; ++i) {
          if (!(b.includes(a[i]))) {
            return false;
          }
        }
        return true;
      };



      // Compare each field, using arraysEqual for arrays
      return (
        originalData.name !== currentData.name ||
        originalData.team !== currentData.team ||
        originalData.seller !== currentData.seller ||
        !arraysEqual(originalData.proxy_groups, currentData.proxy_groups) ||
        originalData.proxy_port !== currentData.proxy_port
      );
    };

    return isFormDataValid() && isPortValid() &&  hasChanges();
  }, [formData, proxies, mode, data]);


  return (
    <Modal
      active={active}
      onClose={onClose}
      className="w-full px-3 md:w-[600px]"
    >
      <Modal.Header>
        <Modal.Title>
          {mode === "create" ? "Create Proxy" : "Edit Proxy"}
        </Modal.Title>
        <Modal.Close />
      </Modal.Header>
      {loading && (
        <>
          <Modal.Body className="p-3 flex items-center justify-center h-[240px]">
            <Loader className="w-8 h-8 text-white" />
          </Modal.Body>
          <Modal.Footer>
            <div />
            <Button
              disabled
              className="h-8 rounded flex items-center justify-start gap-2 uppercase tracking-wide px-2"
              color="gray"
            >
              <span className="text-xs">Save</span>
            </Button>
          </Modal.Footer>
        </>
      )}
      {!loading && (
        <form onSubmit={handleSave}>
          <Modal.Body className="p-3">
            <Form
              tag="div"
              data={formData}
              schema={schema}
              onChange={setFormData}
            />
          </Modal.Body>
          <Modal.Footer>
            <div />
            <Button
              className="h-8 rounded flex items-center justify-start gap-2 uppercase tracking-wide px-2"
              color="blue"
              disabled={!canSave}
            >
              <span className="text-xs">Save</span>
            </Button>
          </Modal.Footer>
        </form>
      )}
    </Modal>
  );
}
