import clsx from "clsx";
import * as React from "react";
import { getDateTime } from "../../utils/datetime";
import Checkbox from "./Checkbox";
import Input from "./Input";
import Select, { Option } from "./Select";
import { useScreenSize } from "../../hooks/useScreenSize";

interface FormField {
  type: "string" | "number" | "datetime" | "select" | "boolean" | "tags";
  label?: string;
  key: string;
  placeholder?: string;
  defaultValue?: any;
  options: Option[];
  required?: boolean;
  searchable?: boolean;
}

const parseSchema = (schema: any) => {
  const result = schema.reduce((obj: any, field: FormField) => {
    if (field.defaultValue) {
      obj[field.key] = field.defaultValue;
    } else {
      switch (field.type) {
        case "string":
          obj[field.key] = "";
          break;
        case "number":
          obj[field.key] = "";
          break;
        case "datetime":
          obj[field.key] = getDateTime();
          break;
        case "select": {
          obj[field.key] = field.options[0].value;
          break;
        }
        case "boolean": {
          obj[field.key] = false;
        }
      }
    }
    return obj;
  }, {});
  return result;
};

function FormRoot({
  children,
  className,
  tag,
}: {
  children: any;
  className?: string;
  tag: "div" | "form";
}) {
  if (tag === "div") {
    return (
      <div className={clsx("flex flex-col gap-2", className)}>{children}</div>
    );
  }
  return (
    <form className={clsx("flex flex-col gap-2", className)}>{children}</form>
  );
}

function Field({
  data,
  value,
  onChange,
}: {
  data: FormField;
  value: any;
  onChange: (key: string, value: any) => void;
}) {
  const [filteredOptions, setFilteredOptions] = React.useState<Option[]>(
    data.options
  );

  const handleClickTag = (field: any, tagValue: any) => {
    const tags: any[] = value;
    const idx = tags.indexOf(tagValue);

    if (idx === -1) {
      tags.push(tagValue);
    } else {
      tags.splice(idx, 1);
    }

    if (onChange) {
      onChange(field.key, tags);
    }
  };
  if (data.type === "string") {
    return (
      <Input
        id={data.key}
        type="text"
        value={value}
        placeholder={data.placeholder}
        onChange={(value: string) => onChange(data.key, value)}
        className="w-full"
        required={data.required}
      />
    );
  }
  if (data.type === "number") {
    return (
      <Input
        id={data.key}
        type="number"
        value={value}
        placeholder={data.placeholder}
        onChange={(value: string) => onChange(data.key, value)}
        className="w-full"
        required={data.required}
      />
    );
  }
  if (data.type === "datetime") {
    return (
      <Input
        id={data.key}
        type="datetime-local"
        value={value}
        placeholder={data.placeholder}
        onChange={(value: string) => onChange(data.key, value)}
        className="w-full"
        required={data.required}
      />
    );
  }
  if (data.type === "select") {
    return (
      <Select
        id={data.key}
        placeholder={data.placeholder}
        options={filteredOptions}
        value={value}
        onChange={(value: any) => onChange(data.key, value)}
        required={data.required}
        searchable={data.searchable}
        onSearch={(query: string) => {
          const result = [...data.options].filter((item: Option) => {
            return item.label.toLowerCase().indexOf(query.toLowerCase()) !== -1;
          });
          setFilteredOptions(result);
        }}
      />
    );
  }
  if (data.type === "tags") {
    return (
      <div className="flex gap-[6px]">
        {data.options.map((option: any, i: number) => {
          const tags = value;
          const idx = tags?.indexOf(option.value);
          return (
            <button
              key={i}
              onClick={(e: any) => {
                e.preventDefault();
                handleClickTag(data, option.value);
              }}
              className={clsx(
                "border rounded-full px-3 h-6 flex items-center justify-center",
                {
                  "bg-blue-500 border-blue-400": idx !== -1,
                  "bg-zinc-800 border-zinc-700 ": idx === -1,
                }
              )}
            >
              <span
                className={clsx("text-xs font-bold", {
                  "text-white": idx !== -1,
                  "text-zinc-500": idx === -1,
                })}
              >
                {option.label}
              </span>
            </button>
          );
        })}
      </div>
    );
  }
  if (data.type === "boolean") {
    return (
      <Checkbox
        id={data.key}
        label={data.key}
        className="px-0"
        value={value}
        onChange={(value: boolean) => onChange(data.key, value)}
        required={data.required}
      />
    );
  }

  return <></>;
}

function Form({
  className,
  schema,
  data,
  onChange,
  tag = "form",
}: {
  className?: string;
  schema: any;
  data?: any;
  onChange?: (data: any, key?: string) => void;
  tag?: "div" | "form";
}) {
  const { isMd } = useScreenSize();
  const [state, setState] = React.useState(() => parseSchema(schema));

  React.useEffect(() => {
    setState(data);
  }, [data]);

  const handleChange = (key: string, value: any) => {
    const newState = { ...state, [key]: value };
    setState(newState);

    if (onChange) {
      onChange(newState, key);
    }
  };

  if (!state) {
    return (
      <div className="flex flex-col items-center py-3 gap-2">
        <span className="text-sm text-white">Could not load form</span>
        <span className="text-sm text-white/50">Error: Invalid schema</span>
      </div>
    );
  }

  return (
    <FormRoot tag={tag} className={className}>
      {schema.map((field: FormField, i: number) => {
        if (Array.isArray(field)) {
          const count = field.length;
          return (
            <div className="flex gap-4 w-full flex-col md:flex-row" key={i}>
              {field.map((childField: FormField, j: number) => {
                return (
                  <div
                    className="flex flex-col gap-2 mb-2 grow"
                    key={i + j}
                    style={{ width: isMd ? 100 / count + "%" : "100%" }}
                  >
                    {childField.label && (
                      <>
                        <span className="text-white text-xs">
                          {childField.label}
                        </span>
                        <Field
                          data={childField}
                          value={state[childField.key]}
                          onChange={handleChange}
                        />
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          );
        }

        return (
          <div key={i} className="flex flex-col gap-2 mb-2">
            {field.label && (
              <span className="text-white text-xs">{field.label}</span>
            )}
            <Field
              data={field}
              value={state[field.key]}
              onChange={handleChange}
            />
          </div>
        );
      })}
    </FormRoot>
  );
}

export default Form;
