import * as React from "react";

import Button from "./core/Button";
import { Link, useLocation } from "react-router-dom";
import Icon from "./core/Icon";
import { MenuItem } from "../types/index";
import clsx from "clsx";

const MENU_ITEMS: MenuItem[] = [
  {
    id: "dashboard",
    label: "Dashboard",
    to: "/",
    icon: "thLarge",
  },
  {
    id: "accounts",
    label: "Accounts",
    to: "/accounts",
    icon: "users",
  },
  {
    id: "auctions",
    label: "Auctions",
    to: "/auctions",
    icon: "warehouse",
  },
  {
    id: "orders",
    label: "Orders",
    to: "/orders",
    icon: "cart",
  },
  {
    id: "invoices",
    label: "Invoices",
    to: "/invoices",
    icon: "receipt",
  },
  {
    id: "payments",
    label: "Payments",
    to: "/payments",
    icon: "moneyBillWave",
  },
  {
    id: "proxies",
    label: "Proxies (legacy)",
    to: "/proxies-legacy",
    icon: "vault",
  },
  {
    id: "proxies",
    label: "Proxies",
    to: "/proxies",
    icon: "vault",
  },
  {
    id: "proxy-status",
    label: "Proxy Status",
    to: "/proxy-status",
    icon: "server",
  },
  {
    id: "mining",
    label: "Mining",
    to: "/mining",
    icon: "personDigging",
  },
  {
    id: "refunds",
    label: "Refunds",
    to: "/refunds",
    icon: "handHoldingDollar",
  },
  {
    id: "block-parties",
    label: "Block Parties",
    to: "/block-parties",
    icon: "gift",
  },
];

export default function Sidebar() {
  const { pathname } = useLocation();
  const currentRoute =
    pathname === "/" ? pathname : "/" + pathname.split("/").filter((x) => x)[0];

  return (
    <aside className="border-r border-zinc-700 w-10 lg:min-w-[180px] h-full">
      <div className="flex flex-col">
        {MENU_ITEMS.map((menuItem: MenuItem, i: number) => {
          return (
            <Link key={i} to={menuItem.to}>
              <Button
                disabled={currentRoute === menuItem.to}
                hoverText={menuItem.label}
                hoverPosition="right"
                className={clsx(
                  "w-10 lg:w-full flex justify-center lg:justify-start items-center gap-3 h-10 group px-0 lg:px-3 relative",
                  {
                    "bg-white bg-opacity-0 hover:bg-opacity-10":
                      currentRoute !== menuItem.to,
                  }
                )}
              >
                {currentRoute === menuItem.to && (
                  <div className="absolute top-0 bottom-0 left-0 w-[3px] bg-blue-500 rounded-r-full" />
                )}
                <Icon
                  icon={menuItem.icon}
                  className="w-4 h-4 text-white text-opacity-60 group-hover:text-opacity-100 group-disabled:text-opacity-100"
                />
                <span className="hidden lg:block normal-case tracking-normal text-white text-opacity-60 group-hover:text-opacity-100 group-disabled:text-opacity-100">
                  {menuItem.label}
                </span>
              </Button>
            </Link>
          );
        })}
      </div>
    </aside>
  );
}
