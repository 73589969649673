import * as React from "react";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";

import Input from "../components/core/Input";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import View from "../components/View";
import { Account, AccountType, PaginationState } from "../types/index";
import { get, post } from "../utils/fetch";
import { apiUrl, feUrl, sellerPortalUrl } from "../utils/url";
import Pagination from "../components/core/Pagination";
import Button from "../components/core/Button";
import { useEnvironmentContext } from "../context/EnvironmentContext";
import List from "../components/core/List";
import clsx from "clsx";
import Icon from "../components/core/Icon";
import { useAuthContext } from "../context/AuthContext";
import Toolbar from "../components/core/Toolbar";

export default function Accounts() {
  const { environment } = useEnvironmentContext();
  const { token } = useAuthContext();
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [accounts, setAccounts] = React.useState([]);
  const [limit, setLimit] = React.useState<number>(20);
  const [offset, setOffset] = React.useState<number>(0);
  const [total, setTotal] = React.useState<number>(0);

  const loadAccounts = async () => {
    // if (process.env.REACT_APP_ENV !== 'local' && !token) {
    //   return;
    // }

    setLoading(true);
    const res = await get(
      apiUrl(environment, `/api/ops/accounts?limit=${limit}&offset=${offset}`),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setAccounts(res.results);
    setTotal(res.count);
    setLoading(false);
  };

  const loadAccountsWithQuery = async (query: string) => {
    if (process.env.REACT_APP_ENV !== "local" && !token) {
      return;
    }

    setLoading(true);
    const search = query === "" ? "" : `search=${query}&`;
    const res = await get(
      apiUrl(
        environment,
        `/api/ops/accounts?${search}limit=${limit}&offset=${offset}`
      ),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setAccounts(res.results);
    setTotal(res.count);
    setLoading(false);
  };

  const handleLoginAsBuyer = async (account: Account) => {
    if (process.env.REACT_APP_ENV !== "local" && !token) {
      return;
    }

    const res = await post(
      apiUrl(environment, "/api/ops/auth/login-as-user"),
      {
        account: account.id,
        return_url: feUrl(environment),
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    window.open(res.url, "_blank");
  };

  const handleLoginAsSeller = async (account: Account) => {
    if (process.env.REACT_APP_ENV !== "local" && !token) {
      return;
    }

    const res = await post(
      apiUrl(environment, "/api/ops/auth/login-as-user"),
      {
        account: account.id,
        return_url: sellerPortalUrl(),
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    window.open(res.url, "_blank");
  };
  const handlePaginationChange = (state: PaginationState) => {
    setOffset(state.offset);
    setLimit(state.limit);
  };

  React.useEffect(() => {
    loadAccounts();
  }, [offset, limit, environment, token]);

  const debounceLoadAccountsWithQuery = debounce(loadAccountsWithQuery, 300);

  return (
    <>
      <Topbar />
      <div className="flex w-full h-screen-excluding-topbar">
        <Sidebar />
        <View>
          <Toolbar>
            <Input
              id="search"
              type="text"
              placeholder="Search account by id, email"
              onChange={debounceLoadAccountsWithQuery}
              className="min-w-[320px]"
            />
            <Pagination
              limit={limit}
              offset={offset}
              total={total}
              onChange={handlePaginationChange}
            />
          </Toolbar>
          <div className="p-3 pt-0">
            <List
              data={accounts}
              loading={loading}
              onClick={(account: Account) =>
                navigate(`/accounts/${account.id}`)
              }
              schema={[
                { label: "ID", key: "id", type: "number", align: "left" },
                { label: "Email", key: "email", type: "string", align: "left" },
                {
                  label: "Type",
                  key: (account: Account) => (
                    <span className="text-white">
                      {account.type === AccountType.Buyer ? "Buyer" : "Seller"}
                    </span>
                  ),
                  type: "custom",
                },
                {
                  label: "Referral code",
                  key: "referral_code",
                  type: "number",
                  align: "left",
                },
                {
                  label: "Demo",
                  key: "is_demo",
                  type: "boolean",
                  align: "left",
                },
                {
                  label: "Onboarded",
                  key: "is_onboarded",
                  type: "boolean",
                  align: "left",
                },
                {
                  label: "Paid",
                  key: "is_paid",
                  type: "boolean",
                  align: "left",
                },
                {
                  label: "Staff",
                  key: "is_staff",
                  type: "boolean",
                  align: "left",
                },
                {
                  label: "Subscribed",
                  key: "is_subscribed",
                  type: "boolean",
                  align: "left",
                },
                {
                  label: "Verified",
                  key: "is_verified",
                  type: "boolean",
                  align: "left",
                },
                {
                  label: "Actions",
                  key: (account: Account) => (
                    <div className="flex items-center justify-between gap-2">
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleLoginAsBuyer(account);
                        }}
                        className={clsx(
                          "h-8 rounded w-full text-sm font-semibold text-white border border-blue-400 bg-blue-500 hover:border-blue-300 hover:bg-blue-400 flex items-center justify-center"
                        )}
                      >
                        <div className="flex items-center justify-start gap-2">
                          <Icon icon="moneyBillWave" className="w-4 h-4" />
                          <span className="text-xs tracking-wide uppercase whitespace-nowrap">
                            Login as Buyer
                          </span>
                        </div>
                      </Button>
                      {account.type === "seller" && (
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleLoginAsSeller(account);
                          }}
                          className={clsx(
                            "h-8 rounded w-full text-sm font-semibold text-white border border-purple-400 bg-purple-500 hover:border-purple-300 hover:bg-purple-400 flex items-center justify-center"
                          )}
                        >
                          <div className="flex items-center justify-start gap-2">
                            <Icon icon="cart" className="w-4 h-4" />
                            <span className="text-xs tracking-wide uppercase whitespace-nowrap">
                              Login as seller
                            </span>
                          </div>
                        </Button>
                      )}
                    </div>
                  ),
                  type: "custom",
                },
              ]}
            />
          </div>
        </View>
      </div>
    </>
  );
}
