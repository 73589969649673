/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from "react";
import { apiUrl } from "../utils/url";
import { get } from "../utils/fetch";
import { useEnvironmentContext } from "../context/EnvironmentContext";
import Loader from "../components/core/Loader";
import { Payment, PoolUser, WorkerData } from "../types";
import Icon from "../components/core/Icon";

interface ProxyStatus {
  email: string;
  proxy: string;
  payment?: Payment;
  pool_user?: PoolUser;
  worker?: WorkerData;
  assigned_at?: string;
}

function ElapsedTime({
  className,
  timestamp,
}: {
  className?: string;
  timestamp: string;
}) {
  const then = new Date(timestamp);
  const now = new Date();
  // @ts-ignore
  const elapsedTime = now - then;

  const hours = Math.floor(elapsedTime / 3600000);
  const minutes = Math.floor((elapsedTime % 3600000) / 60000);
  const seconds = Math.floor((elapsedTime % 60000) / 1000);

  const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

  return <span className={className}>{formattedTime}</span>;
}

function Proxy({ status }: { status: ProxyStatus }) {
  const [visible, setVisible] = React.useState(false);
  return (
    <div className="flex w-full flex-col gap-4 bg-zinc-900 border-zinc-800 border rounded-lg p-4">
      <div className="flex items-center justify-between">
        <div className="flex flex-col gap-1">
          <span className="text-sm font-bold text-zinc-500">Proxy</span>
          <span className="text-sm">{status.proxy}</span>
        </div>
        <div className="flex flex-col gap-1 items-end">
          <span className="text-sm font-bold text-zinc-500">Buyer</span>
          <span className="text-sm">{status.email}</span>
        </div>
      </div>
      <div className="flex flex-col gap-1">
        <span className="text-sm font-bold text-zinc-500">Pool user</span>
        {status.email !== "-" && status.pool_user && (
          <span className="text-sm">
            {" "}
            {status.pool_user.username}@{status.pool_user.pool}
          </span>
        )}
        {status.email === "-" && (
          <span className="text-sm">
            riglycorp@stratums+tcp://btc.global.luxor.tech:700
          </span>
        )}
      </div>
      <div className="flex items-center justify-between">
        <div className="flex flex-col gap-1">
          <span className="text-sm font-bold text-zinc-500">Elapsed time</span>
          {status.assigned_at && (
            <ElapsedTime
              className="text-sm font-mono"
              timestamp={status.assigned_at}
            />
          )}
          {!status.assigned_at && <span className="text-sm">-</span>}
        </div>
        <div className="flex flex-col gap-1 items-end">
          <span className="text-sm font-bold text-zinc-500">Status</span>
          {status.email === "-" && (
            <span className="font-bold text-green-500 text-sm">Available</span>
          )}
          {status.email !== "-" && status.pool_user && (
            <span className="font-bold text-red-500 text-sm">Unavailable</span>
          )}
        </div>
      </div>
      {status.worker && (
        <>
          <button
            className="h-10 px-4 bg-white/5 border border-zinc-700 rounded flex items-center justify-between gap-2"
            onClick={() => setVisible(!visible)}
          >
            {!visible && (
              <>
                <span className="text-xs">Show worker details</span>
                <Icon
                  icon="chevronUp"
                  className="text-white w-3 h-3 rotate-180"
                />
              </>
            )}
            {visible && (
              <>
                <span className="text-xs">Hide worker details</span>
                <Icon
                  icon="chevronUp"
                  className="text-white w-3 h-3 rotate-0"
                />
              </>
            )}
          </button>
          {visible && (
            <div className="flex flex-col gap-1">
              <span className="text-sm font-bold text-zinc-500">
                Worker data
              </span>
              <div className="flex flex-col h-auto gap-1">
                <div className="flex items-center justify-between">
                  <span className="text-sm font-bold">Hashrate</span>
                  <span className="text-sm font-mono">
                    {(status.worker.hashrate / 10 ** 12).toFixed(3)} TH/s
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <span className="text-sm font-bold">Difficulty</span>
                  <span className="text-sm font-mono">
                    {status.worker.difficulty}
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <span className="text-sm font-bold">Accepted shares</span>
                  <span className="text-sm font-mono">
                    {status.worker.accepted_shares}
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <span className="text-sm font-bold">Rejected shares</span>
                  <span className="text-sm font-mono">
                    {status.worker.rejected_shares}
                  </span>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default function ProxyStatus() {
  const { environment } = useEnvironmentContext();
  const [response, setResponse] = React.useState<ProxyStatus[] | undefined>(
    undefined
  );

  const loadProxyStatuses = async () => {
    const res = await get(apiUrl(environment, `/api/orders/proxies`));
    setResponse(res);
  };

  React.useEffect(() => {
    loadProxyStatuses();
    const interval = setInterval(loadProxyStatuses, 5000);
    return () => clearInterval(interval);
  }, []);

  if (!response) {
    return (
      <div className="w-screen h-screen text-white flex items-center justify-center">
        <Loader className="text-white w-6 h-6" />
      </div>
    );
  }

  return (
    <div className="w-screen h-full text-white p-4 flex flex-col gap-4">
      {response.map((status: ProxyStatus, i: number) => (
        <Proxy key={i} status={status} />
      ))}
    </div>
  );
}
