import clsx from "clsx";
import { format } from "date-fns";
import { get } from "lodash";
import * as React from "react";
import Checkbox from "./Checkbox";
import Icon from "./Icon";
import Loader from "./Loader";
import { SortingDirection } from "../../types";

export default function List({
  className,
  tdClassName,
  spanClass,
  sortKey,
  sortDirection,
  schema,
  data,
  loading,
  onClick,
  onSort,
}: {
  className?: string;
  tdClassName?: string;
  spanClass?: string;
  sortKey?: string;
  sortDirection?: SortingDirection;
  schema: any[];
  data: any[];
  loading?: boolean;
  onClick?: (item: any) => void;
  onSort?: (key: string | undefined, direction: SortingDirection) => void;
}) {
  const [sortingKey, setSortingKey] = React.useState<string | undefined>(
    sortKey
  );
  const [sortingDirection, setSortingDirection] =
    React.useState<SortingDirection>(sortDirection || SortingDirection.None);

  const handleSort = (key: string) => {
    const isNewSortingKey = sortingKey !== key;
    if (isNewSortingKey) {
      setSortingKey(key);
      setSortingDirection(SortingDirection.Ascending);
      if (typeof onSort === "function") {
        onSort(key, SortingDirection.Ascending);
      }
      return;
    }

    const newSortingKey =
      sortingDirection === SortingDirection.Descending ? undefined : key;

    const newSortingDirection =
      sortingDirection === SortingDirection.None
        ? SortingDirection.Ascending
        : sortingDirection === SortingDirection.Ascending
        ? SortingDirection.Descending
        : SortingDirection.None;

    setSortingKey(newSortingKey);
    setSortingDirection(newSortingDirection);

    if (typeof onSort === "function") {
      onSort(newSortingKey, newSortingDirection);
    }
  };

  const handleClick = (i: number) => {
    const item = data[i];
    if (onClick) {
      onClick(item);
    }
  };

  const renderHead = () => {
    return (
      <thead>
        <tr>
          {schema.map((col, i) => {
            return (
              <th
                key={i}
                className={clsx(
                  "h-8 bg-white bg-opacity-10 first-of-type:rounded-tl-lg last-of-type:rounded-tr-lg border-r border-zinc-900 last-of-type:border-none",
                  {
                    "text-left": col.align === "left",
                    "text-right": col.align === "right",
                    "text-center": col.align === "center",
                  }
                )}
              >
                <button
                  className="flex items-center justify-between gap-2 w-full h-full px-3"
                  onClick={() =>
                    typeof onSort === "function" && handleSort(col.key)
                  }
                >
                  <span
                    className={clsx("text-xs whitespace-nowrap", {
                      "text-white": sortingKey === col.key,
                      "text-white/50": sortingKey !== col.key,
                    })}
                  >
                    {col.label}
                  </span>
                  {typeof onSort === "function" &&
                    typeof col.key !== "function" && (
                      <div className="flex flex-col">
                        <Icon
                          icon="chevronUp"
                          className={clsx("w-[10px] h-[10px]", {
                            "text-white":
                              sortingKey === col.key &&
                              sortingDirection === SortingDirection.Ascending,
                            "text-white/50":
                              (sortingKey === col.key &&
                                sortingDirection !==
                                  SortingDirection.Ascending) ||
                              sortingKey !== col.key,
                          })}
                        />
                        <Icon
                          icon="chevronUp"
                          className={clsx(
                            "w-[10px] h-[10px] rotate-180 -mt-[2px]",
                            {
                              "text-white":
                                sortingKey === col.key &&
                                sortingDirection ===
                                  SortingDirection.Descending,
                              "text-white/50":
                                (sortingKey === col.key &&
                                  sortingDirection !==
                                    SortingDirection.Descending) ||
                                sortingKey !== col.key,
                            }
                          )}
                        />
                      </div>
                    )}
                </button>
              </th>
            );
          })}
        </tr>
      </thead>
    );
  };

  if (loading) {
    return (
      <div className={clsx("flex flex-col", className)}>
        <table>{renderHead()}</table>
        <div className="flex flex-col w-full bg-white bg-opacity-5 rounded px-4 h-10 items-center justify-center">
          <Loader className="text-white text-opacity-50 w-6 h-6" />
        </div>
      </div>
    );
  }

  if (data.length === 0) {
    return (
      <div className={clsx("flex flex-col", className)}>
        <table>{renderHead()}</table>
        <div className="flex flex-col w-full bg-white bg-opacity-5 rounded px-4 h-10 items-center justify-center">
          <span className="text-zinc-300 text-sm">No result(s) found</span>
        </div>
      </div>
    );
  }

  return (
    <table className={clsx("w-full", className)}>
      {renderHead()}
      <tbody>
        {data.map((item: any, i: number) => {
          return (
            <tr
              key={i}
              className="group cursor-pointer"
              onClick={() => handleClick(i)}
            >
              {schema.map((col, j) => {
                const value =
                  typeof col.key === "function"
                    ? col.key(item, i)
                    : get(item, col.key);

                return (
                  <td
                    className={clsx(
                      tdClassName,
                      "h-10 bg-white group-odd:bg-opacity-5 group-even:bg-opacity-10 group-hover:bg-opacity-[15%] px-2 border-r whitespace-nowrap border-zinc-900 last-of-type:border-none group-last-of-type:first-of-type:rounded-bl-lg group-last-of-type:last-of-type:rounded-br-lg",
                      {
                        "text-left": col.align === "left",
                        "text-right": col.align === "right",
                        "text-center": col.align === "center",
                      }
                    )}
                    key={i + j}
                  >
                    {col.type === "number" && (
                      <span
                        className={clsx(
                          "text-white font-mono text-sm",
                          spanClass
                        )}
                      >
                        {value}
                      </span>
                    )}
                    {col.type === "sats" && (
                      <span
                        className={clsx(
                          "text-white font-mono text-sm",
                          spanClass
                        )}
                      >
                        {value}
                        <i className="text-zinc-400 not-italic">ṩ</i>
                      </span>
                    )}
                    {col.type === "string" && (
                      <span className={clsx("text-white text-sm", spanClass)}>
                        {value}
                      </span>
                    )}
                    {col.type === "boolean" && (
                      <Checkbox
                        id={col.key}
                        value={value}
                        disabled
                        className="bg-zinc-800"
                      />
                    )}
                    {col.type === "datetime" && (
                      <span className={clsx("text-white text-sm", spanClass)}>
                        {format(new Date(value), "MMMM d, yyyy HH:mm:ss")}
                      </span>
                    )}
                    {col.type === "custom" && value}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
