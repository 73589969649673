import * as React from "react";
import { Account, AccountType, BuyerInvoice, SellerInvoice } from "../../types";
import Code from "../core/Code";
import omit from "../../utils/omit";

export default function InvoiceRawData({
  account,
  invoice,
}: {
  account: Account | undefined;
  invoice: BuyerInvoice | SellerInvoice;
}) {
  const isSeller = account && account.type === AccountType.Seller;
  const report = isSeller
    ? (invoice as SellerInvoice).report
    : omit((invoice as BuyerInvoice).report, ["body"]);

  return (
    <div className="flex gap-4 items-start grow">
      <Code>{JSON.stringify(report, null, 2)}</Code>
    </div>
  );
}
