import clsx from "clsx";
import * as React from "react";
import Icon from "./Icon";
import Popover from "./Popover";
import Loader from "./Loader";
import Input from "./Input";

export interface Option {
  label: string;
  value: any;
}

function Select({
  className,
  id,
  options,
  value,
  onChange,
  placeholder,
  clearable,
  clearLabel = "Clear",
  loading,
  searchable,
  searchPlaceholder = "Search",
  onSearch,
}: // required,
{
  id: string;
  className?: string;
  options: Option[];
  value: any;
  onChange: (value: any) => void;
  placeholder?: string;
  clearable?: boolean;
  clearLabel?: string;
  loading?: boolean;
  searchable?: boolean;
  searchPlaceholder?: string;
  onSearch?: (value: string) => void;
  required?: boolean;
}) {
  const selectRef = React.useRef<any>();
  const [showDropdown, setShowDropdown] = React.useState<boolean>(false);

  const handleSelect = (option: Option) => {
    onChange(option.value);
    setShowDropdown(false);
  };

  const handleClear = () => {
    onChange(undefined);
    setShowDropdown(false);
  };

  const handleClick = (e: any) => {
    e.preventDefault();
    setShowDropdown(true);
  };

  React.useEffect(() => {
    if (selectRef.current && value === null) {
      const select = selectRef.current as any;
      select.value = null;
    }
  }, [selectRef, value, options]);

  const selectedOption: Option | undefined = options.reduce(
    (result: any, option: Option) => {
      if (option.value === value) {
        result = option;
      }
      return result;
    },
    undefined
  );
  return (
    <>
      <button
        id={id}
        className={clsx(
          "flex items-center justify-between rounded bg-white bg-opacity-5 h-10 px-3 gap-3 cursor-pointer",
          className
        )}
        onClick={handleClick}
      >
        {value === undefined && (
          <div className="text-white/60 text-sm">{placeholder}</div>
        )}
        {value !== undefined && selectedOption && (
          <div className="text-white text-sm">{selectedOption.label}</div>
        )}
        <div className="">
          <Icon
            icon="chevronUp"
            className={clsx("w-3 h-3 text-white/60", {
              "rotate-180": !showDropdown,
            })}
          />
        </div>
      </button>

      <Popover
        target={`#${id}`}
        show={showDropdown}
        position="bottom-center"
        onHide={() => setShowDropdown(false)}
      >
        <div className="flex flex-col w-full">
          {searchable && (
            <div className="border-t border-zinc-700 p-2">
              <Input
                id="search"
                type="text"
                onChange={onSearch!}
                className="w-full"
                placeholder={searchPlaceholder}
              />
            </div>
          )}
          {loading && (
            <div className="px-3 h-10 flex items-center justify-center">
              <Loader className="w-5 h-5 text-white" />
            </div>
          )}
          {!loading && options.length > 0 && (
            <div>
              {options.map((option: Option, i: number) => {
                return (
                  <button
                    key={i}
                    className={clsx(
                      "w-full h-8 px-3 flex items-center justify-start hover:bg-white/10",
                      {
                        "bg-white/10": selectedOption?.value === option.value,
                      }
                    )}
                    onClick={() => handleSelect(option)}
                  >
                    <span
                      className={clsx("text-sm", {
                        "text-white":
                          !selectedOption ||
                          selectedOption.value !== option.value,
                        "text-blue-500": selectedOption?.value === option.value,
                      })}
                    >
                      {option.label}
                    </span>
                  </button>
                );
              })}
            </div>
          )}
          {!loading && options.length === 0 && (
            <div className="px-3 h-10 flex items-center justify-center">
              <span className="text-sm text-white">No options available</span>
            </div>
          )}
          {clearable && value !== undefined && (
            <div className="border-t border-zinc-700">
              <button
                className="w-full h-10 px-3 flex items-center justify-center hover:bg-white/10 rounded-b"
                onClick={handleClear}
              >
                <span className="text-sm text-white">{clearLabel}</span>
              </button>
            </div>
          )}
        </div>
      </Popover>
    </>
  );
}

export default Select;
