import * as React from "react";
import {
  Account,
  // AccountType,
  BuyerInvoice,
  SellerInvoice,
} from "../../types";
import clsx from "clsx";
import Plotly from "react-plotly.js";

export default function InvoiceChart({
  // account,
  invoice,
}: {
  account: Account | undefined;
  invoice: BuyerInvoice | SellerInvoice;
}) {
  // const isSeller = account && account.type === AccountType.Seller;
  if (Object.keys(invoice.figure).length === 0) {
    return (
      <div className="flex items-center justify-center py-10">
        <span className="text-white">No graph available (yet).</span>
      </div>
    );
  }

  return (
    <div className="w-full bg-white bg-opacity-5 rounded flex items-center justify-center">
      <div className="bg-white w-full pt-5">
        <Plotly
          className={clsx("w-full h-full flex justify-center items-center")}
          data={invoice.figure.data}
          layout={invoice.figure.layout}
          config={{
            displayModeBar: false,
          }}
          useResizeHandler={true}
          style={{ width: "100%", height: "100%" }}
        />
      </div>
    </div>
  );
}
