import * as React from "react";
import Card from "../core/Card";
import Pagination from "../core/Pagination";
import { useAuthContext } from "../../context/AuthContext";
import { useEnvironmentContext } from "../../context/EnvironmentContext";
import useIsRequestAllowed from "../../hooks/useIsRequestAllowed";
import { apiUrl } from "../../utils/url";
import { get } from "../../utils/fetch";
import { PaginationState, Refund } from "../../types";
import Loader from "../core/Loader";
import RefundList from "./RefundList";

export default function ProcessedRefunds() {
  const { token } = useAuthContext();
  const { environment } = useEnvironmentContext();
  const { isRequestAllowed } = useIsRequestAllowed();

  const [limit, setLimit] = React.useState<number>(10);
  const [offset, setOffset] = React.useState<number>(0);
  const [total, setTotal] = React.useState<number>(0);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [refunds, setRefunds] = React.useState<Refund[]>([]);

  const loadProcessedRefunds = async () => {
    if (!isRequestAllowed) {
      return;
    }
    try {
      setLoading(true);
      const res = await get(apiUrl(environment, `/api/ops/refunds/processed`), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setRefunds(res.results);
      setTotal(res.count);
    } catch (ex) {
      console.error(ex);
    } finally {
      setLoading(false);
    }
  };

  const handlePaginationChange = (state: PaginationState) => {
    setOffset(state.offset);
    setLimit(state.limit);
  };

  React.useEffect(() => {
    loadProcessedRefunds();
  }, [token, environment]);

  return (
    <Card>
      <Card.Header>
        <Card.Title>Processed refunds</Card.Title>
        <div className="flex items-center justify-end">
          <Pagination
            limit={limit}
            offset={offset}
            total={total}
            onChange={handlePaginationChange}
            small
          />
        </div>
      </Card.Header>
      <Card.Body className="flex">
        {loading && (
          <div className="grow flex items-center justify-center">
            <Loader className="w-5 h-5 text-white" />
          </div>
        )}
        {!loading && refunds.length === 0 && (
          <div className="grow flex items-center justify-center">
            <span className="text-white/50 text-sm">
              No processed refund(s) found.
            </span>
          </div>
        )}
        {!loading && refunds.length > 0 && (
          <div className="p-2 w-full h-full">
            <RefundList refunds={refunds} loading={loading} />
          </div>
        )}
      </Card.Body>
    </Card>
  );
}
