import * as React from "react";
import clsx from "clsx";
import { IconType } from "../../types/index";

import * as ICONS from "./icons";

export default function Icon({
  className,
  icon,
}: {
  className?: string;
  icon: IconType;
}) {
  const Component = ICONS[icon];

  return <Component className={clsx(className)} />;
}
