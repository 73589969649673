import clsx from "clsx";
import * as React from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import { useEnvironmentContext } from "../context/EnvironmentContext";
import { Environment } from "../types";
import Button from "./core/Button";
import Icon from "./core/Icon";

function Authorized() {
  const { account, loading, logout } = useAuthContext();

  const handleSignOut = () => {
    logout();
  };

  if (loading) {
    return null;
  }

  return (
    <div className="flex gap-4">
      <span className="text-white uppercase text-sm tracking-wide">
        {account?.email}
      </span>
      <Button
        onClick={handleSignOut}
        className="text-white hover:text-zinc-400 px-0"
      >
        <Icon icon="rightFromBracket" className="w-4 h-4" />
      </Button>
    </div>
  );
}

const ENVS = [
  { label: "PROD", value: Environment.PROD },
  { label: "QA", value: Environment.QA },
];

export default function Topbar() {
  const { account, loading: accountLoading } = useAuthContext();
  const { environment, setEnvironment } = useEnvironmentContext();

  const handleChangeEnvironment = (env: Environment) => {
    localStorage.setItem("rigly-admin-env", env);
    setEnvironment(env);
  };

  return (
    <div className="bg-zinc-900 border-b border-zinc-700 h-12 px-4 flex items-center justify-between gap-4">
      <span className="text-white text-sm font-bold">Rigly Ops</span>
      <div className="h-8 rounded overflow-hidden ml-auto">
        {ENVS.map((env, i) => {
          return (
            <Button
              key={i}
              className={clsx("text-white h-8 px-4 border border-zinc-700", {
                "bg-zinc-700": environment === env.value,
                "bg-zinc-800": environment !== env.value,
              })}
              onClick={() => handleChangeEnvironment(env.value)}
            >
              <span className="text-xs">{env.label}</span>
            </Button>
          );
        })}
      </div>
      {!accountLoading && (
        <>
          {account && <Authorized />}
          {!account && (
            <div className="text-white">
              <Link to="/login">
                <Button>Sign in</Button>
              </Link>
            </div>
          )}
        </>
      )}
    </div>
  );
}
