import * as React from "react";

import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import View from "../components/View";
import { useEffect, useState } from "react";
import * as proxyApi from "../api/proxies";
import * as api from "../api/orders";
import toast from "react-hot-toast";
import clsx from "clsx";
import { Card } from "@rigly/core";
import { Link, useParams } from "react-router-dom";
import { Order, Proxy, WorkerMachine, Worker } from "../types";
import { calculateEfficiency, calculateInefficiency } from "../utils/hashrate";
import Toolbar from "../components/core/Toolbar";
import Modal from "../components/core/Modal";
import { parsePoolUrl } from "../utils/pool";
import { useEnvironmentContext } from "../context/EnvironmentContext";
import { useAuthContext } from "../context/AuthContext";
import AssignmentHistory from "../components/proxies/AssignmentHistory";
import DifficultyHistory from "../components/proxies/DifficultyHistory";
import Logs from "../components/proxies/Logs";
import HashrateChart from "../components/HashrateChart";
import { useWebsocketContext } from "../context/WebsocketContext";

export default function Proxies() {
  const params = useParams();
  const { token } = useAuthContext();
  const { environment } = useEnvironmentContext();
  const ws = useWebsocketContext();

  const [workers, setWorkers] = useState<any[]>([]);

  // const [loading, setLoading] = useState<boolean>(true);
  const [proxy, setProxy] = useState<Proxy | undefined>(undefined);
  const [order, setOrder] = useState<Order | undefined>(undefined);
  const [activeTab, setActiveTab] = useState<string>("hashrate");
  const [activeMachineTab, setActiveMachineTab] = useState<string>("machines");
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [logsQuery, setLogsQuery] = React.useState<string | undefined>(
    undefined
  );
  const [isWorkersGrouped, setIsWorkersGrouped] =
    React.useState<boolean>(false);

  const [poolOne, setPoolOne] = useState<string | undefined>(undefined);
  const [poolUserOne, setPoolUserOne] = useState<string | undefined>(undefined);
  const [poolPasswordOne, setPoolPasswordOne] = useState<string | undefined>(
    undefined
  );

  const [isDownloadingLogs, setIsDownloadingLogs] = useState<boolean>(false);

  const load = async (isRefresh: boolean) => {
    const proxyId = params.proxyId;
    if (!proxyId || !token) {
      return;
    }

    try {
      // setLoading(true);
      const res: Proxy = await proxyApi.getProxy(proxyId, environment, token);
      setProxy(res);

      if (!isRefresh && res.upstream) {
        setPoolOne(res.upstream.pool_user.pool);
        setPoolUserOne(res.upstream.pool_user.username);
      }
    } catch (ex: any) {
      console.log(ex.message);
      toast.error(ex.message, { position: "bottom-right" });
    } finally {
      // setLoading(false);
    }
  };

  const loadCurrentOrder = async () => {
    const proxyId = params.proxyId;
    if (!proxyId || !token) {
      return;
    }

    try {
      const order = await api.getOrderByProxy(proxyId, environment, token);

      if (!order) {
        return;
      }

      setOrder(order);
    } catch (ex: any) {
      // console.log(ex.message);
      // toast.error(ex.message, { position: "bottom-right" });
    }
  };

  const handleSave = async () => {
    const proxyId = params.proxyId;
    if (!proxyId || !token) {
      return;
    }

    if (!poolOne) {
      toast.error("Invalid pool", { position: "bottom-right" });
      return;
    }

    if (!poolUserOne) {
      toast.error("Invalid pool username", { position: "bottom-right" });
      return;
    }

    try {
      const { host, port } = parsePoolUrl(poolOne);

      const res = await proxyApi.switchProxy(
        proxyId,
        {
          upstream_host: host,
          upstream_port: port,
          pool_username: poolUserOne,
          order_id: 111,
        },
        environment,
        token
      );

      toast.success("Switched proxy", { position: "bottom-right" });

      refresh();

      setIsModalVisible(false);
    } catch (ex: any) {
      toast.error(ex.toString(), { position: "bottom-right" });
    }
  };

  const groupWorkers = (workers: WorkerMachine[]) => {
    if (!isWorkersGrouped) {
      return workers;
    }

    const result: { [key: string]: WorkerMachine } = {};

    for (let i = 0; i < workers.length; i++) {
      const worker = workers[i];
      if (!(worker.name in result)) {
        result[worker.name] = worker;
        continue;
      }

      result[worker.name] = {
        ...worker,
        difficulty: result[worker.name].difficulty + worker.difficulty,
        hashrate: {
          calculated:
            result[worker.name].hashrate.calculated +
            worker.hashrate.calculated,
          average:
            result[worker.name].hashrate.average + worker.hashrate.average,
        },
        shares: {
          accepted:
            result[worker.name].shares.accepted + worker.shares.accepted,
          rejected:
            result[worker.name].shares.rejected + worker.shares.rejected,
        },
      };
    }

    return Object.values(result);
  };

  const handleSearchLogs = (value: string) => {
    setLogsQuery(value);
  };

  async function refresh() {
    ws.unsubscribe(`proxy_${params.proxyId}`, handleProxyUpdate);
    ws.subscribe(`proxy_${params.proxyId}`, handleProxyUpdate);
  }

  const onDownloadLogs = async (e: any) => {
    e.preventDefault();

    setIsDownloadingLogs(true);
    const downloadingToast = toast.loading('Creating logs archive...', { position: "bottom-right" });
    
    if (!params.proxyId || !token) {
      return;
    }
    
    const archiveInfo = await proxyApi.getProxyLogsArchive(
      params.proxyId.toString(),
      environment,
      token
    )
    
    toast.dismiss(downloadingToast);

    if (archiveInfo) {
      toast.success((<>
      <div>
        <div>Logs archive for proxy</div>
        <div className="font-semibold">{proxy ? proxy.name : "???"} ({params.proxyId})</div><hr/>
        <ol>
          {archiveInfo.files.map((f: string) => (<li>{f}</li>))}
        </ol>
        </div>
      </>), { position: "bottom-right", duration: 10000 });
    } else {
      toast.error('Logs download failed.', { position: "bottom-right" });
    }

    setIsDownloadingLogs(false);

  };

  const handleProxyUpdate = (update: any) => {
    switch (update.type) {
      case "init": {
        setProxy(update.payload);
        setWorkers(update.payload.workers);
        setPoolOne(update.payload.upstream.pool_user.pool);
        setPoolUserOne(update.payload.upstream.pool_user.username);
        break;
      }
      case "worker_add": {
        setWorkers((currentWorkers) => {
          delete update.payload.proxy_id;
          return [...currentWorkers, update.payload];
        });
        break;
      }
      case "worker_authorize": {
        setWorkers((currentWorkers) => {
          const newWorkers = [...currentWorkers];
          const idx = newWorkers.findIndex(
            (w) => w.id === update.payload.worker_id
          );
          if (idx !== -1) {
            newWorkers[idx] = { ...newWorkers[idx], ...update.payload };
          }
          return newWorkers;
        });
        break;
      }
      case "worker_remove": {
        setWorkers((currentWorkers) => {
          const newWorkers = [...currentWorkers];
          const idx = newWorkers.findIndex(
            (w) => w.id === update.payload.worker_id
          );
          if (idx !== -1) {
            newWorkers.splice(idx, 1);
          }
          return newWorkers;
        });
        break;
      }
      case "worker_stats": {
        setWorkers((currentWorkers) => {
          const newWorkers = [...currentWorkers];
          const idx = newWorkers.findIndex(
            (w) => w.id === update.payload.worker_id
          );
          if (idx !== -1) {
            newWorkers[idx] = { ...newWorkers[idx], ...update.payload };
          }
          return newWorkers;
        });
        break;
      }
      case "stats": {
        setProxy((currentProxy) => {
          delete update.payload.proxy_id;
          return { ...currentProxy, ...update.payload };
        });
        break;
      }
      case "pool_switch": {
        const worker: Worker = {
          id: update.payload.id,
          name: update.payload.name,
          pool_user: {
            pool: update.payload.pool_user.pool,
            username: update.payload.pool_user.username,
          },
        };

        // @ts-ignore
        setProxy((currentProxy) => {
          return {
            ...currentProxy,
            upstream: worker,
          };
        });
        break;
      }
      default: {
        console.log(`[ws] ${update}`);
        break;
      }
    }
  };

  useEffect(() => {
    document.body.classList.add("dark");
  }, []);

  useEffect(() => {
    // load(false);
    // loadCurrentOrder();
  }, [params]);

  // useEffect(() => {
  //   const interval = setInterval(() => load(true), 1000 * 5);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [params]);

  useEffect(() => {
    ws.subscribe(`proxy_${params.proxyId}`, handleProxyUpdate);
    return () => {
      ws.unsubscribe(`proxy_${params.proxyId}`, handleProxyUpdate);
    };
  }, [params.proxyId]);

  useEffect(() => {
    document.body.classList.add("dark");
  }, []);

  return (
    <>
      <Topbar />
      <div className="flex w-full h-screen-excluding-topbar">
        <Sidebar />
        <View>
          <Toolbar>
            <Link to="/proxies">
              <button className="text-sm text-white/50 hover:text-white mr-3">
                &larr; Go back
              </button>
            </Link>
            <span className="text-sm text-white mr-auto">
              Proxy {proxy ? proxy.name : "???"}
            </span>
            <button
              className="h-7 rounded bg-blue-600 border border-blue-400 px-3 cursor-pointer hover:bg-blue-400 hover:border-blue-300"
              onClick={() => setIsModalVisible(true)}
            >
              <span className="text-sm text-white">Switch</span>
            </button>
          </Toolbar>
          <div className="grid grid-cols-6 gap-3 p-3 pt-0">
            <div className="col-span-4 flex flex-col gap-3">
              <Card className="!p-0">
                <div className="flex border-b border-zinc-800 justify-between items-center">
                  <div className="flex items-center justify-start">
                    <button
                      onClick={() => setActiveTab("hashrate")}
                      className={clsx(
                        "text-sm h-10 px-3 outline-none cursor-pointer",
                        {
                          "text-white": activeTab === "hashrate",
                          "text-white/50": activeTab !== "hashrate",
                        }
                      )}
                    >
                      Hashrate
                    </button>
                    <button
                      onClick={() => setActiveTab("assignment-history")}
                      className={clsx(
                        "text-sm h-10 px-3 outline-none cursor-pointer",
                        {
                          "text-white": activeTab === "assignment-history",
                          "text-white/50": activeTab !== "assignment-history",
                        }
                      )}
                    >
                      Assignment history
                    </button>
                    <button
                      onClick={() => setActiveTab("difficulty-history")}
                      className={clsx(
                        "text-sm h-10 px-3 outline-none cursor-pointer",
                        {
                          "text-white": activeTab === "difficulty-history",
                          "text-white/50": activeTab !== "difficulty-history",
                        }
                      )}
                    >
                      Difficulty history
                    </button>
                    <button
                      onClick={() => setActiveTab("logs")}
                      className={clsx(
                        "text-sm h-10 px-3 outline-none cursor-pointer",
                        {
                          "text-white": activeTab === "logs",
                          "text-white/50": activeTab !== "logs",
                        }
                      )}
                    >
                      Logs
                    </button>
                  </div>
                  {activeTab === "logs" && (<>
                    <button
                      className="h-7 rounded bg-blue-600 opacity-100 px-3 cursor-pointer hover:bg-blue-500 disabled:opacity-30 disabled:cursor-not-allowed disabled:bg-zinc-600 disabled:text-zinc-400 ml-auto mr-2"
                      onClick={(e) => onDownloadLogs(e)}
                      disabled={isDownloadingLogs}
                    >
                      <span className="text-white text-xs font-semibold uppercase tracking-wide">Download</span>
                    </button>
                    <input
                      type="text"
                      className="mr-2 outline-none h-7 px-2 bg-zinc-800 border-zinc-700 border rounded text-sm text-white"
                      placeholder="Search..."
                      onInput={(e: any) => handleSearchLogs(e.target.value)}
                    />
                  </>)}
                </div>
                <>
                  {activeTab === "hashrate" && (
                    <>
                      {!proxy && (
                        <div className="h-[40vh] flex items-center justify-center">
                          <span className="text-xs text-white/40">
                            No chart data available
                          </span>
                        </div>
                      )}
                      {proxy && (
                        <HashrateChart
                          proxy={proxy}
                          renderEmpty={() => (
                            <div className="h-[40vh] flex items-center justify-center">
                              <span className="text-xs text-white/40">
                                No chart data available
                              </span>
                            </div>
                          )}
                        />
                      )}
                    </>
                  )}
                  {activeTab === "logs" && (
                    <Logs proxy={proxy} query={logsQuery} />
                  )}
                  {activeTab === "assignment-history" && (
                    <AssignmentHistory proxy={proxy} />
                  )}
                  {activeTab === "difficulty-history" && (
                    <DifficultyHistory proxy={proxy} />
                  )}
                </>
              </Card>
              <Card className="!p-0 col-span-4 row-span-1">
                <div className="flex border-b border-zinc-800 justify-between items-center">
                  <div className="flex items-center justify-start">
                    <button
                      onClick={() => setActiveMachineTab("machines")}
                      className={clsx(
                        "text-sm h-10 px-3 outline-none cursor-pointer",
                        {
                          "text-white": activeMachineTab === "machines",
                          "text-white/50": activeMachineTab !== "machines",
                        }
                      )}
                    >
                      Machines
                    </button>
                    {/* <button
                      onClick={() => setActiveMachineTab("order")}
                      className={clsx(
                        "text-sm h-10 px-3 outline-none cursor-pointer",
                        {
                          "text-white": activeMachineTab === "order",
                          "text-white/50": activeMachineTab !== "order",
                        }
                      )}
                    >
                      Order details
                    </button> */}
                  </div>
                  {activeMachineTab === "machines" && (
                    <div className="flex items-center justify-end gap-2 mr-2">
                      <label
                        htmlFor="group-workers-checkbox"
                        className="text-white/50 text-xs cursor-pointer select-none"
                      >
                        Group machines
                      </label>
                      <input
                        id="group-workers-checkbox"
                        type="checkbox"
                        checked={isWorkersGrouped}
                        onChange={(e) => setIsWorkersGrouped(e.target.checked)}
                        className="cursor-pointer outline-none"
                      />
                    </div>
                  )}
                </div>
                {activeMachineTab === "machines" && (
                  <div className="flex flex-col px-3 pb-3">
                    {proxy && (
                      <>
                        {workers.length === 0 && (
                          <div className="flex justify-center items-center h-[160px]">
                            <span className="text-white/50 text-sm">
                              No machines detected
                            </span>
                          </div>
                        )}
                        {workers.length > 0 && (
                          <table>
                            <thead>
                              <tr>
                                <th className="text-left pb-2">
                                  <span className="text-white text-xs font-normal">
                                    #
                                  </span>
                                </th>
                                <th className="text-left pb-2">
                                  <span className="text-white text-xs font-normal">
                                    Machine
                                  </span>
                                </th>
                                <th className="text-left pb-2">
                                  <span className="text-white text-xs font-normal">
                                    Hashrate
                                  </span>
                                </th>
                                <th className="text-left pb-2">
                                  <span className="text-white text-xs font-normal">
                                    Shares
                                  </span>
                                </th>
                                <th className="text-left pb-2">
                                  <span className="text-white text-xs font-normal">
                                    Difficulty
                                  </span>
                                </th>
                                <th className="text-left pb-2">
                                  <span className="text-white text-xs font-normal">
                                    Efficiency / Inefficiency
                                  </span>
                                </th>
                                <th className="text-left pb-2">
                                  <span className="text-white text-xs font-normal">
                                    Last share
                                  </span>
                                </th>
                                <th className="text-left pb-2">
                                  <span className="text-white text-xs font-normal">
                                    Downstream
                                  </span>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {groupWorkers(workers).map(
                                (worker: WorkerMachine, i: number) => {
                                  return (
                                    <tr key={i} className="">
                                      <td className="text-left py-1 border-t border-zinc-800">
                                        <span className="text-sm text-white">
                                          {i + 1}
                                        </span>
                                      </td>
                                      <td className="text-left py-1 border-t border-zinc-800">
                                        <span className="text-sm text-white">
                                          {worker.name}
                                        </span>
                                      </td>
                                      <td className="text-left py-1 border-t border-zinc-800">
                                        <span className="text-sm text-white font-mono">
                                          {worker.hashrate?.average
                                            ? `${(
                                                worker.hashrate.average /
                                                1000000000000
                                              ).toFixed(2)} TH/s`
                                            : "-"}
                                        </span>
                                      </td>
                                      <td className="text-left py-1 border-t border-zinc-800">
                                        <span className="text-sm text-white font-mono">
                                          {worker.shares
                                            ? `${worker.shares.accepted} / ${worker.shares.rejected}`
                                            : "-"}
                                        </span>
                                      </td>
                                      <td className="text-left py-1 border-t border-zinc-800">
                                        <span className="text-sm text-white font-mono">
                                          {worker.difficulty !== undefined
                                            ? Math.round(worker.difficulty)
                                            : 0}
                                        </span>
                                      </td>
                                      <td className="text-left py-1 border-t border-zinc-800">
                                        <div className="text-sm font-mono flex items-center gap-2">
                                          <span className="text-white">
                                            {calculateEfficiency(worker.shares)}
                                            %
                                          </span>
                                          <span className="text-white/50">
                                            /
                                          </span>
                                          <span className="text-white">
                                            {calculateInefficiency(
                                              worker.shares
                                            )}
                                            %
                                          </span>
                                        </div>
                                      </td>
                                      <td className="text-left py-1 border-t border-zinc-800">
                                        {worker.is_last_share_accepted && (
                                          <span className="text-sm text-green-500 block">
                                            Accepted
                                          </span>
                                        )}
                                        {!worker.is_last_share_accepted && (
                                          <span className="text-sm text-red-500 block">
                                            Rejected
                                          </span>
                                        )}
                                      </td>
                                      <td className="text-left py-1 border-t border-zinc-800">
                                        {isWorkersGrouped && (
                                          <span className="text-sm text-white font-mono">
                                            -
                                          </span>
                                        )}
                                        {!isWorkersGrouped && (
                                          <span className="text-sm text-white font-mono">
                                            {worker.host}:{worker.port}
                                          </span>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        )}
                      </>
                    )}
                  </div>
                )}
                {activeMachineTab === "order" && (
                  <div className="w-full">
                    {!order && (
                      <div className="flex items-center justify-center h-20">
                        <span className="text-white/50 text-sm">
                          Not serving an order
                        </span>
                      </div>
                    )}
                    {order && (
                      <>
                        {[
                          {
                            label: "Order",
                            value: order ? (
                              <Link
                                to={`/orders/${order.id}`}
                                className="text-blue-300 hover:text-blue-400 underline"
                              >
                                Order {order.id}
                              </Link>
                            ) : (
                              "-"
                            ),
                          },
                          {
                            label: "Time remaining until completion",
                            value: (
                              <div>
                                <div className="w-full h-[8px] rounded-full border border-zinc-700 overflow-hidden">
                                  <div
                                    className="w-full bg-blue-500 h-full rounded-full border"
                                    style={{
                                      transform: `translate3d(-50%,0,0)`,
                                    }}
                                  ></div>
                                </div>
                                <span className="text-white text-sm">
                                  1 day, 4 hours, 3 minutes
                                </span>
                              </div>
                            ),
                          },
                          {
                            label: "Target hashrate",
                            value: `${order.hashrate} TH/s`,
                          },
                        ].map((stat: any, i: number) => {
                          return (
                            <div
                              key={i}
                              className="flex flex-col p-3 border-b border-zinc-800 gap-1"
                            >
                              <span className="text-white/50 text-sm">
                                {stat.label}
                              </span>
                              <span className="text-white text-sm">
                                {stat.value}
                              </span>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                )}
              </Card>
            </div>
            <div className="col-span-2 row-span-3 flex flex-col gap-3">
              <Card className="!p-0 flex flex-col">
                <div className="px-3 h-10 border-b border-zinc-800 flex items-center justify-start">
                  <span className="text-white/50 text-sm">Proxy details</span>
                </div>
                {proxy && (
                  <>
                    {[
                      {
                        label: "Host",
                        value: `${proxy.host}:${proxy.port}`,
                      },
                      {
                        label: "Worker(s)",
                        value: (
                          <span className="font-mono text-sm">
                            {workers.length}
                          </span>
                        ),
                      },
                      {
                        label: "Order",
                        value: order ? (
                          <div className="flex flex-col gap-2">
                            <Link
                              to={`/orders/${order.id}`}
                              className="text-blue-300 hover:text-blue-400 underline"
                            >
                              Order {order.id}
                            </Link>

                            <span className="text-white/50 text-xs">
                              Time until completion
                            </span>
                            <div className="flex flex-col gap-1">
                              <div className="w-full h-[8px] rounded-full border border-zinc-700 overflow-hidden">
                                <div
                                  className="w-full bg-blue-500 h-full rounded-full border"
                                  style={{
                                    transform: `translate3d(-50%,0,0)`,
                                  }}
                                />
                              </div>
                              <span className="text-white text-xs font-mono">
                                1 day, 4 hours, 3 minutes
                              </span>
                            </div>
                          </div>
                        ) : (
                          "Idle"
                        ),
                      },
                      {
                        label: "Upstream Pool",
                        value: proxy.upstream
                          ? proxy.upstream.pool_user.pool
                          : "-",
                      },
                      {
                        label: "Upstream User",
                        value: proxy.upstream
                          ? proxy.upstream.pool_user.username
                          : "-",
                      },
                      {
                        label: order
                          ? "Hashrate / Target Hashrate"
                          : "Hashrate",
                        value: (
                          <div className="text-sm font-mono flex items-center gap-2">
                            <span className="text-white">
                              {(
                                proxy.hashrate.calculated / 1000000000000
                              ).toFixed(2)}{" "}
                              TH/s
                            </span>
                            {order && (
                              <>
                                <span className="text-white/50">/</span>
                                <span>{order.hashrate} TH/s</span>
                              </>
                            )}
                          </div>
                        ),
                      },
                      {
                        label: "Shares",
                        value: (
                          <div className="text-sm font-mono flex items-center gap-2">
                            <span className="text-white">
                              {proxy.shares.accepted}
                            </span>
                            <span className="text-white/50">/</span>
                            <span>{proxy.shares.rejected}</span>
                          </div>
                        ),
                      },
                      {
                        label: "Efficiency / Inefficiency",
                        value: (
                          <div className="text-sm font-mono flex items-center gap-2">
                            <span className="text-white">
                              {calculateEfficiency(proxy.shares)}%
                            </span>
                            <span className="text-white/50">/</span>
                            <span className="text-white">
                              {calculateInefficiency(proxy.shares)}%
                            </span>
                          </div>
                        ),
                      },
                      {
                        label: "Difficulty",
                        value: (
                          <span className="font-mono text-sm">
                            {Math.round(proxy.difficulty)}
                          </span>
                        ),
                      },
                    ].map((stat: any, i: number) => {
                      return (
                        <div
                          key={i}
                          className="flex flex-col p-3 border-b border-zinc-800 gap-1"
                        >
                          <span className="text-white/50 text-xs">
                            {stat.label}
                          </span>
                          <span className="text-white text-sm">
                            {stat.value}
                          </span>
                        </div>
                      );
                    })}
                  </>
                )}
              </Card>
            </div>
          </div>
        </View>
      </div>
      <Modal active={isModalVisible} onClose={() => setIsModalVisible(false)}>
        <Modal.Header>
          <Modal.Title>Switch Proxy</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body className="p-3">
          <table className="w-full">
            <thead>
              <tr>
                <th className="text-left font-normal text-sm pb-2 text-white/50 pr-1.5">
                  Pool
                </th>
                <th className="text-left font-normal text-sm pb-2 text-white/50 px-1.5">
                  User
                </th>
                <th className="text-left font-normal text-sm pb-2 text-white/50 pl-1.5">
                  Password
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="pr-1.5">
                  <input
                    className="w-full outline-none h-10 px-3 bg-zinc-800 border-zinc-700 border rounded text-white"
                    type="text"
                    name="pool_one"
                    placeholder="Pool #1"
                    value={poolOne}
                    onInput={(e: any) => setPoolOne(e.target.value)}
                  />
                </td>
                <td className="px-1.5">
                  <input
                    className="w-full outline-none h-10 px-3 bg-zinc-800 border-zinc-700 border rounded text-white"
                    type="text"
                    name="user_one"
                    placeholder="User #1"
                    value={poolUserOne}
                    onInput={(e: any) => setPoolUserOne(e.target.value)}
                  />
                </td>
                <td className="pl-1.5">
                  <input
                    className="w-full outline-none h-10 px-3 bg-zinc-800 border-zinc-700 border rounded text-white"
                    type="text"
                    name="password_one"
                    placeholder="Password #1"
                    value={poolPasswordOne}
                    onInput={(e: any) => setPoolPasswordOne(e.target.value)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <div />
          <button
            className="h-7 rounded bg-blue-600 border border-blue-400 px-3 cursor-pointer hover:bg-blue-400 hover:border-blue-300"
            onClick={handleSave}
          >
            <span className="text-sm text-white">Save</span>
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
