import * as React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./index.css";

import DashboardRoute from "./routes/Dashboard";
import AccountsRoute from "./routes/Accounts";
import AccountDetailRoute from "./routes/AccountDetail";
import AuctionDetailRoute from "./routes/AuctionDetail";
import AuctionsRoute from "./routes/Auctions";
import OrderDetailRoute from "./routes/OrderDetail";
import OrdersRoute from "./routes/Orders";
import PaymentDetailRoute from "./routes/PaymentDetail";
import PaymentsRoute from "./routes/Payments";
import ProxiesLegacyRoute from "./routes/Proxies";
import ProxiesRoute from "./routes/ProxiesV2";
import ProxyDetailLegacyRoute from "./routes/ProxyDetail";
import ProxyDetailRoute from "./routes/ProxyDetailV2";
import InvoicesRoute from "./routes/Invoices";
import InvoiceDetailRoute from "./routes/InvoiceDetail";
import MiningRoute from "./routes/Mining";
import RefundDetailRoute from "./routes/RefundDetail";
import RefundsRoute from "./routes/Refunds";
import ProxyStatusRoute from "./routes/ProxyStatus";
// import WIPRoute from "./routes/WIP";
import LoginRoute from "./routes/Login";
import ProxiesStatusRoute from "./routes/ProxiesStatus";
import BlockPartiesRoute from "./routes/BlockParties";

import Layout from "./components/Layout";

import { AuthContextProvider } from "./context/AuthContext";
import { EnvironmentContextProvider } from "./context/EnvironmentContext";
import { ModalContextProvider } from "./context/ModalContext";
import { WebsocketContextProvider } from "./context/WebsocketContext";

createRoot(document.getElementById("root") as HTMLElement).render(
  <EnvironmentContextProvider>
    <AuthContextProvider>
      <WebsocketContextProvider>
        <ModalContextProvider>
          <Router>
            <Layout>
              <Routes>
                <Route index element={<DashboardRoute />} />
                <Route
                  path="/accounts/:accountId/invoice/:invoiceId"
                  element={<InvoiceDetailRoute />}
                />
                <Route
                  path="/accounts/:accountId"
                  element={<AccountDetailRoute />}
                />
                <Route path="/accounts" element={<AccountsRoute />} />
                <Route
                  path="/auctions/:auctionId"
                  element={<AuctionDetailRoute />}
                />
                <Route path="/auctions" element={<AuctionsRoute />} />
                <Route path="/orders/:orderId" element={<OrderDetailRoute />} />
                <Route path="/orders/proxies" element={<ProxyStatusRoute />} />
                <Route path="/orders" element={<OrdersRoute />} />
                <Route
                  path="/payments/:paymentId"
                  element={<PaymentDetailRoute />}
                />
                <Route path="/payments" element={<PaymentsRoute />} />
                <Route
                  path="/proxies-legacy/:proxyId"
                  element={<ProxyDetailLegacyRoute />}
                />
                <Route
                  path="/proxies-legacy"
                  element={<ProxiesLegacyRoute />}
                />
                <Route
                  path="/proxies/:proxyId"
                  element={<ProxyDetailRoute />}
                />
                <Route path="/proxies" element={<ProxiesRoute />} />
                <Route path="/proxy-status" element={<ProxiesStatusRoute />} />
                <Route path="/invoices" element={<InvoicesRoute />} />
                <Route path="/mining" element={<MiningRoute />} />
                <Route path="/block-parties" element={<BlockPartiesRoute />} />
                <Route
                  path="/refunds/:refundId"
                  element={<RefundDetailRoute />}
                />
                <Route path="/refunds" element={<RefundsRoute />} />
                <Route
                  path="/login/callback"
                  element={<LoginRoute callback />}
                />
                <Route path="/login" element={<LoginRoute />} />
              </Routes>
            </Layout>
          </Router>
        </ModalContextProvider>
      </WebsocketContextProvider>
    </AuthContextProvider>
  </EnvironmentContextProvider>
);
