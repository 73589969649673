import * as React from "react";
import {
  Account,
  AccountType,
  BuyerInvoice,
  Earnings,
  SellerInvoice,
} from "../../types";
import Button from "../core/Button";
import Icon from "../core/Icon";
import Loader from "../core/Loader";
import * as Handlebars from "handlebars";
import toast from "react-hot-toast";
import { useAuthContext } from "../../context/AuthContext";
import { useEnvironmentContext } from "../../context/EnvironmentContext";
import { post } from "../../utils/fetch";
import { apiUrl } from "../../utils/url";
import TextEditor from "../core/TextEditor";
import { EARNINGS_HEADINGS } from "./InvoiceEarnings";

const renderEmail = (input: string, data: any): string => {
  try {
    const sanitizedInput = input
      .replace(/\\n/gim, "<br />")
      .replace(/\{/gim, "<b>{{")
      .replace(/\}/gim, "}}</b>")
      .replace(/:>8,?/gim, "");
    const template = Handlebars.compile(sanitizedInput);
    return template(data)
      .replace(/\n/gim, "<br />")
      .replace(/(<br>)+/g, "<br>");
  } catch (ex: any) {
    toast.error(
      "Could not render email, please check your console for more information",
      { position: "bottom-left" }
    );
    console.error(ex.message);
    return "";
  }
};

const renderBuyerEmail = (invoice: BuyerInvoice): string => {
  return renderEmail(invoice.report.body, invoice.report);
};

const renderSellerEmail = (invoice: SellerInvoice): string => {
  const earnings = invoice.report.earnings
    ? `<br>${earningsToHtml(invoice.report.earnings)}`
    : "";
  return renderEmail(invoice.report.body, invoice.report) + earnings;
};

const earningsToHtml = (earnings: Earnings) => {
  let result = `<table>
    <thead>
      <tr>
        <td>Date</td>${Object.keys(earnings)
          .map((key) => `<td>${EARNINGS_HEADINGS[key]}</td>`)
          .join("")}
      </tr>
    </thead>
  <tbody>`;
  for (const date of Object.keys(earnings.hashrate_effective_thps)) {
    result += `<tr>
        <td>${date}</td>
        <td>${earnings.hashrate_effective_thps[date]}</td>
        <td>${earnings.fpps_price_avg_satspthpspd[date]}</td>
        <td>${earnings.fpps_payout_sats[date]}</td>
        <td>${earnings.rigly_earnings_sats[date]}</td>
      </tr>`;
  }
  return `${result}
  	</tbody>
  </table>`;
};

export default function InvoiceEmail({
  account,
  invoice,
  onRefresh,
}: {
  account: Account | undefined;
  invoice: BuyerInvoice | SellerInvoice;
  onRefresh: () => void;
}) {
  const { environment } = useEnvironmentContext();
  const { token } = useAuthContext();

  const isSeller = account && account.type === AccountType.Seller;
  const [email, setEmail] = React.useState<string>(
    isSeller
      ? renderSellerEmail(invoice as SellerInvoice)
      : renderBuyerEmail(invoice as BuyerInvoice)
  );

  const [loadingSendInvoice, setLoadingSendInvoice] =
    React.useState<boolean>(false);

  const handleSendInvoice = async () => {
    if (process.env.REACT_APP_ENV !== "local" && !token) {
      return;
    }

    if (!account) {
      return;
    }

    try {
      setLoadingSendInvoice(true);
      await post(
        apiUrl(
          environment,
          account.type === AccountType.Seller
            ? `/api/ops/sellers/${account.id}/invoices/${invoice.id}/send`
            : `/api/ops/buyers/${account.id}/invoices/${invoice.id}/send`
        ),
        {
          content: email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Invoice sent", { position: "bottom-left" });
      onRefresh();
    } catch (ex: any) {
      toast.error(ex.message, { position: "bottom-left" });
    } finally {
      setLoadingSendInvoice(false);
    }
  };

  return (
    <div className="flex flex-col gap-4 items-start grow">
      <TextEditor id="email" value={email} onChange={setEmail} />
      {!invoice.is_email_sent && (
        <Button
          disabled={loadingSendInvoice}
          className="uppercase tracking-wide h-12 px-4 flex justify-start items-center gap-2"
          color="blue"
          onClick={handleSendInvoice}
        >
          {!loadingSendInvoice && (
            <Icon icon="envelope" className="w-4 h-4 text-white" />
          )}
          {loadingSendInvoice && <Loader className="w-4 h-4 text-white" />}
          <span className="text-white text-sm">Send invoice to email</span>
        </Button>
      )}
    </div>
  );
}
