import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Order } from "../types";
import List from "./core/List";

export default function OrdersList({
  className,
  orders,
  loading,
}: {
  className?: string;
  orders: Order[];
  loading: boolean;
}) {
  const navigate = useNavigate();

  return (
    <List
      className={className}
      schema={[
        { label: "ID", key: "id", type: "number", align: "left" },
        {
          label: "Epoch",
          key: "auction.epoch.epoch_number",
          type: "number",
          align: "left",
        },
        {
          label: "Auction",
          key: "auction.title",
          type: "string",
          align: "left",
        },
        {
          label: "Account",
          key: "account.email",
          type: "string",
          align: "left",
        },
      ]}
      data={orders}
      loading={loading}
      onClick={(order: Order) => navigate(`/orders/${order.id}`)}
    />
  );
}
