import * as React from "react";
import toast from "react-hot-toast";
import { useAuthContext } from "../../context/AuthContext";
import { useEnvironmentContext } from "../../context/EnvironmentContext";
import { useModalContext } from "../../context/ModalContext";
import { get } from "../../utils/fetch";
import { apiUrl } from "../../utils/url";
import Button from "../core/Button";
import Form from "../core/Form";
import Modal from "../core/Modal";

export default function CreateAuctionModal() {
  const { token } = useAuthContext();
  const { environment } = useEnvironmentContext();
  const { modals, hideModal } = useModalContext();
  const { active, meta } = modals["create-auction"];

  const [formLoading, setFormLoading] = React.useState<boolean>(false);
  const [formData, setFormData] = React.useState<any>({});
  const [formSchema, setFormSchema] = React.useState<any>(undefined);

  const loadForm = async () => {
    if (process.env.REACT_APP_ENV !== "local" && !token) {
      return;
    }

    try {
      setFormLoading(true);

      const schema = await get(
        apiUrl(environment, `/api/ops/auctions/create`),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setFormSchema(schema);
    } catch (ex: any) {
      toast.error(ex.message, { position: "bottom-left" });
    } finally {
      setFormLoading(false);
    }
  };

  const handleCancel = () => {
    hideModal("create-auction");
  };

  const handleSave = () => {
    meta.onSave(formData);
  };

  React.useEffect(() => {
    loadForm();
  }, [environment, token]);

  return (
    <Modal
      active={active}
      onClose={handleCancel}
      className="w-full md:w-2/4 lg:w-[50vw]"
    >
      <Modal.Header>
        <Modal.Title>
          {meta && meta.title ? meta.title : "Create auction"}
        </Modal.Title>
        <Modal.Close />
      </Modal.Header>
      <Modal.Body className="p-2">
        <div className="text-white text-sm leading-7">
          {meta && meta.content ? meta.content : ""}
        </div>

        {!formLoading && <Form onChange={setFormData} schema={formSchema} />}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="h-8 rounded flex items-center justify-start gap-2 uppercase tracking-wide px-2"
          color="gray"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          className="h-8 rounded flex items-center justify-start gap-2 uppercase tracking-wide px-2"
          color="blue"
          onClick={handleSave}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
