/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import clsx from "clsx";

import List from "../components/core/List";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import View from "../components/View";
import { get } from "../utils/fetch";
import Chip from "../components/core/Chip";
import {
  Account,
  AccountType,
  Auction,
  AuctionStatus,
  AuctionType,
  AuctionWithResult,
  SellerInvoice,
  SellerInvoiceType,
} from "../types/index";
import { apiUrl } from "../utils/url";
import { useEnvironmentContext } from "../context/EnvironmentContext";
import { useAuthContext } from "../context/AuthContext";
import Checkbox from "../components/core/Checkbox";
import toast from "react-hot-toast";
import Toolbar from "../components/core/Toolbar";
import Button from "../components/core/Button";
import Icon from "../components/core/Icon";
import CreateInvoiceModal from "../components/modals/CreateInvoiceModal";
import { useModalContext } from "../context/ModalContext";

export default function Auctions() {
  const params = useParams();
  const { token } = useAuthContext();
  const { showModal, hideModal } = useModalContext();
  const { environment } = useEnvironmentContext();
  const navigate = useNavigate();

  const [loadingAccount, setLoadingAccount] = React.useState<boolean>(false);
  const [loadingAuctions, setLoadingAuctions] = React.useState<boolean>(false);
  const [loadingAuctionResult, setLoadingAuctionResult] =
    React.useState<boolean>(false);
  const [loadingInvoices, setLoadingInvoices] = React.useState<boolean>(false);
  const [invoices, setInvoices] = React.useState([]);
  const [account, setAccount] = React.useState<Account | undefined>(undefined);
  const [auctions, setAuctions] = React.useState<Auction[] | undefined>(
    undefined
  );
  const [auctionResults, setAuctionResults] = React.useState<any[] | undefined>(
    undefined
  );

  const loadAccount = async () => {
    if (!params.accountId || !token) {
      return;
    }

    try {
      setLoadingAccount(true);
      const res = await get(
        apiUrl(environment, `/api/ops/accounts/${params.accountId}`),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAccount(res);
    } catch (ex: any) {
      toast.error(ex.message, { position: "bottom-left" });
    } finally {
      setLoadingAccount(false);
    }
  };

  const loadAuctions = async () => {
    if (!params.accountId || !token) {
      return;
    }

    try {
      setLoadingAuctions(true);
      const res = await get(
        apiUrl(environment, `/api/ops/accounts/${params.accountId}/auctions`),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAuctions(res);
    } catch (ex: any) {
      toast.error(ex.message, { position: "bottom-left" });
    } finally {
      setLoadingAuctions(false);
    }
  };

  const loadAuctionResults = async () => {
    if (!params.accountId || !token) {
      return;
    }

    try {
      setLoadingAuctionResult(true);
      const res = await get(
        apiUrl(
          environment,
          `/api/ops/accounts/${params.accountId}/auction-results`
        ),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAuctionResults(res);
    } catch (ex: any) {
      toast.error(ex.message, { position: "bottom-left" });
    } finally {
      setLoadingAuctionResult(false);
    }
  };

  const loadInvoices = async () => {
    try {
      setLoadingInvoices(true);
      const res = await get(
        apiUrl(environment, `/api/ops/sellers/${params.accountId}/invoices`),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setInvoices(res);
    } catch (ex: any) {
      toast.error(ex.message, { position: "bottom-left" });
    } finally {
      setLoadingInvoices(false);
    }
  };

  const handleCreateInvoice = async () => {
    showModal("create-invoice", {
      onSave: () => {
        hideModal("create-invoice");
        loadInvoices();
      },
    });
  };

  React.useEffect(() => {
    if (account) {
      loadAuctions();
      loadAuctionResults();

      if (account.type === AccountType.Seller) {
        loadInvoices();
      }
    }
  }, [account]);

  React.useEffect(() => {
    loadAccount();
  }, [params, environment, token]);

  const mappedAuctions =
    auctions &&
    auctions.map((auction: Auction) => {
      return {
        ...auction,
        result: auctionResults?.filter((result: any) => {
          return result.auction === auction.id;
        })[0],
      };
    });

  return (
    <>
      <Topbar />
      <div className="flex w-full h-screen-excluding-topbar">
        <Sidebar />
        <View>
          {account && !loadingAccount && (
            <>
              <Toolbar className="p-1 pl-4">
                <h1 className="text-white text-sm font-semibold">
                  Account {account.email} ({account.id})
                </h1>
                <div className="flex items-center justify-center gap-1">
                  {account.type === AccountType.Seller && (
                    <Button
                      className="h-8 rounded flex items-center justify-start gap-2 uppercase tracking-wide px-2"
                      color="blue"
                      onClick={handleCreateInvoice}
                    >
                      <Icon icon="plus" className="w-4 h-4 text-white" />
                      <span className="text-xs">Create invoice</span>
                    </Button>
                  )}
                </div>
              </Toolbar>
              <div className="flex flex-col gap-2 px-3">
                <h1 className="text-white text-2xl font-semibold"></h1>
                <div className="flex gap-1">
                  <span className="text-sm text-white">Username</span>
                  <span className="text-sm text-white">{account.username}</span>
                  {/* <Chip
                  className={clsx("", {
                    "bg-orange-500": auction.status === AuctionStatus.Scheduled,
                    "bg-blue-500": auction.status === AuctionStatus.Active,
                    "bg-green-500": auction.status === AuctionStatus.Completed,
                  })}
                >
                  {auction.status}
                </Chip>
                <Chip
                  className={clsx("", {
                    "bg-green-500":
                      auction.auction_type.type ===
                      AuctionType.ImmediateDelivery,
                    "bg-blue-500":
                      auction.auction_type.type === AuctionType.ForwardDate,
                    "bg-orange-500":
                      auction.auction_type.type === AuctionType.UpfrontPayment,
                  })}
                >
                  {auction.auction_type.type}
                </Chip> */}
                </div>
              </div>
            </>
          )}
          {account && account.type === AccountType.Seller && (
            <>
              <h2 className="text-xl text-white font-semibold">Invoices</h2>
              <div className="px-3">
                <List
                  data={invoices}
                  loading={loadingInvoices}
                  onClick={(invoice: SellerInvoice) =>
                    navigate(`/accounts/${account.id}/invoice/${invoice.id}`)
                  }
                  schema={[
                    { label: "ID", key: "id", type: "number", align: "left" },
                    {
                      label: "Type",
                      key: (invoice: SellerInvoice) => (
                        <span className="text-white text-sm">
                          {invoice.report.invoice_type ===
                          SellerInvoiceType.Epoch
                            ? "Epoch-based"
                            : "Custom timespan"}
                        </span>
                      ),
                      type: "custom",
                    },
                    {
                      label: "Meta",
                      key: (invoice: SellerInvoice) => (
                        <span className="text-white text-sm">
                          {invoice.report.invoice_type ===
                          SellerInvoiceType.Epoch
                            ? `Epoch ${invoice.report.meta.epoch_number}`
                            : `Start: ${invoice.report.meta.hashrate_start} - End: ${invoice.report.meta.hashrate_end}`}
                        </span>
                      ),
                      type: "custom",
                    },
                    {
                      label: "Status",
                      key: (invoice: SellerInvoice) =>
                        invoice.is_email_sent ? (
                          <span className="text-green-500 text-sm font-semibold">
                            E-mail sent
                          </span>
                        ) : (
                          <span className="text-red-500 text-sm font-semibold">
                            Draft
                          </span>
                        ),
                      type: "custom",
                    },
                    {
                      label: "Created at",
                      key: "report.created_at",
                      type: "datetime",
                      align: "left",
                    },
                  ]}
                />
              </div>
            </>
          )}
          {mappedAuctions && (
            <div className="flex flex-col gap-2 px-3">
              <h2 className="text-xl text-white font-semibold">
                Auction results
              </h2>

              <List
                data={mappedAuctions}
                loading={loadingAuctions || loadingAuctionResult}
                onClick={(auction: AuctionWithResult) =>
                  navigate(`/auctions/${auction.id}`)
                }
                schema={[
                  { label: "ID", key: "id", type: "number", align: "left" },
                  {
                    label: "Auction",
                    key: "title",
                    type: "string",
                    align: "left",
                  },
                  {
                    label: "Epoch",
                    key: (auction: AuctionWithResult) => (
                      <span className="text-white">
                        {auction.auction_type.type === AuctionType.ForwardDate
                          ? `Epoch ${auction.epoch.epoch_number}`
                          : `-`}
                      </span>
                    ),
                    type: "custom",
                  },
                  {
                    label: "Type",
                    key: "auction_type.type",
                    type: "string",
                    align: "left",
                  },
                  {
                    label: "Status",
                    key: (auction: AuctionWithResult) => (
                      <Chip
                        className={clsx("", {
                          "bg-orange-500":
                            auction.status === AuctionStatus.Scheduled,
                          "bg-blue-500":
                            auction.status === AuctionStatus.Active,
                          "bg-green-500":
                            auction.status === AuctionStatus.Completed,
                        })}
                      >
                        {auction.status}
                      </Chip>
                    ),
                    type: "custom",
                  },
                  {
                    label: "Result",
                    key: (auction: AuctionWithResult) => {
                      if (!auction.result) {
                        return null;
                      }
                      return (
                        <span
                          className={clsx("px-4 col-span-1", {
                            "text-red-500": !auction.result.is_winner,
                            "text-green-500": auction.result.is_winner,
                          })}
                        >
                          {auction.result.is_winner ? "Won" : "Lost"}
                        </span>
                      );
                    },
                    type: "custom",
                  },
                ]}
              />
            </div>
          )}

          {account && (
            <div className="px-3">
              <h2 className="text-xl text-white font-semibold">Permissions</h2>
              <div className="grid grid-cols-3 gap-4">
                {[
                  "hashrate_delivery_start",
                  "hashrate_delivery_end",
                  "invoice_create",
                  "invoice_update",
                  "auction_create",
                  "auction_promote_runner_up",
                  "auth_login_as_user",
                  "payment_send_reminder",
                ].map((key: string, i: number) => {
                  const value = account.permissions.hasOwnProperty(key)
                    ? // @ts-ignore
                      account.permissions[key]
                    : false;
                  return (
                    <Checkbox key={i} value={value} label={key} id={key} />
                  );
                })}
              </div>
            </div>
          )}
        </View>
      </div>
      <CreateInvoiceModal accountId={Number(params.accountId)} />
    </>
  );
}
