import * as React from "react";
import clsx from "clsx";
import { PaginationState } from "../../types/index";
import Button from "./Button";

export default function Pagination({
  total,
  limit,
  offset,
  onChange,
  buttonCount = 5,
  small,
}: {
  total: number;
  limit: number;
  offset: number;
  buttonCount?: number;
  onChange: (state: PaginationState) => void;
  small?: boolean;
}) {
  const [pageIndex, setPageIndex] = React.useState<number>(
    offset === 0 ? 0 : Math.ceil(offset / limit)
  );
  const pagesCount = Math.ceil(total / limit);

  const handlePreviousClick = () => {
    const newPageIndex = pageIndex - 1;

    setPageIndex(newPageIndex);
    onChange({
      offset: limit * newPageIndex,
      limit: limit,
    });
  };

  const handleNextClick = () => {
    const newPageIndex = pageIndex + 1;

    setPageIndex(newPageIndex);
    onChange({
      offset: limit * newPageIndex,
      limit: limit,
    });
  };

  const handlePageClick = (value: number) => {
    setPageIndex(value);
    onChange({
      offset: limit * value,
      limit: limit,
    });
  };

  const paginationButtons = getPaginationButtons(
    pageIndex,
    buttonCount,
    pagesCount
  );

  return (
    <div className="flex items-center justify-center">
      <Button
        onClick={handlePreviousClick}
        disabled={pageIndex === 0}
        className={clsx(
          "uppercase tracking-wide bg-white bg-opacity-5 hover:bg-opacity-10 rounded group disabled:bg-opacity-5",
          {
            "h-7 px-2": small,
            "h-10 px-4": !small,
          }
        )}
      >
        <span className="text-xs text-white group-disabled:text-zinc-600">
          &larr;
        </span>
      </Button>
      <div className="flex items-center justify-center px-2 gap-2">
        {pagesCount === 0 && (
          <Button
            onClick={() => handlePageClick(0)}
            className={clsx(
              "uppercase tracking-wide bg-white rounded bg-opacity-10",
              {
                "h-10 px-4": !small,
                "h-7 px-2": small,
              }
            )}
          >
            <span className="text-xs text-white">1</span>
          </Button>
        )}
        {paginationButtons.map((idx: number, i: number) => {
          return (
            <Button
              key={i}
              onClick={() => handlePageClick(idx - 1)}
              className={clsx("uppercase tracking-wide bg-white rounded", {
                "h-10 px-4": !small,
                "h-7 px-2": small,
                "bg-opacity-10": pageIndex + 1 === idx,
                "bg-opacity-5 hover:bg-opacity-10": pageIndex + 1 !== idx,
              })}
            >
              <span className="text-xs text-white">{idx}</span>
            </Button>
          );
        })}
      </div>
      <Button
        onClick={handleNextClick}
        disabled={pageIndex >= pagesCount - 1}
        className={clsx(
          "uppercase tracking-wide bg-white bg-opacity-5 hover:bg-opacity-10 rounded group disabled:bg-opacity-5",
          {
            "px-4 h-10": !small,
            "px-2 h-7": small,
          }
        )}
      >
        <span className="text-xs text-white group-disabled:text-zinc-600">
          &rarr;
        </span>
      </Button>
    </div>
  );
}

function getPaginationButtons(
  pageIndex: number,
  buttonCount: number,
  totalPages: number
): number[] {
  if (totalPages === 1) {
    return [1];
  }

  let startPage = pageIndex - Math.floor(buttonCount / 2);
  let endPage = pageIndex + Math.floor(buttonCount / 2);

  if (startPage < 0) {
    endPage = Math.min(endPage - startPage, totalPages - 1);
    startPage = 0;
  }

  if (endPage >= totalPages) {
    startPage = Math.max(startPage - (endPage - totalPages + 1), 0);
    endPage = totalPages - 1;
  }

  const buttons = [];
  for (let i = startPage; i <= endPage; i++) {
    buttons.push(i + 1);
  }

  return buttons;
}
